import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
// import { Router } from '@angular/router';
// import { LoginService } from './login.service';


import { Cidade, CidadesWebServices } from '../model';

import { API, ITENSPORPAGINA } from '../app.config';
import { CLIENT_ID } from '../app.config';

@Injectable()
export class CidadeService{
  constructor(
    private http: HttpClient,
    // private router: Router,
    // private login: LoginService
    ){}


    buscarPorParametro(param: any, pagina: Number, joincidadewebservice?: boolean): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

     if (!joincidadewebservice)
     {
        // if (param.length > 0)
        // {
          if( (typeof param == 'string') ){
            param = 'texto='+param;
          }else{

            param = this.getQueryString(param);

          }

          return this.http.get<any>( `${API}/api/Cidade/list/${false}/${false}?${param}&Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        // }
        // else
        // {
        //   return this.http.get<any>( `${API}/api/Cidade?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        // }
     }
     else
     {
       if (param.length > 0)
       {
         return this.http.get<any>( `${API}/api/Cidade/list/${joincidadewebservice}/${false}?text=${param.trim()}&Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
       }
       else
       {
         return this.http.get<any>( `${API}/api/Cidade/list/${joincidadewebservice}/${false}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
       }
     }
    }

    buscarOrcamento(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

        if (param.length > 0)
        {
          return this.http.get<any>( `${API}/api/Cidade/list/${false}/${true}?texto=${param.trim()}&Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }



    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${API}/api/Cidade/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    buscaCidadePorIbge(ibge: number): Observable<any>{
        return this.http.get<any>( `${API}/api/cidade/ibge/${ibge}/`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${API}/api/Cidade/${id}`);
    }

    salvar(cidade: Cidade): Observable<any>{
      if (cidade.Id > 0){
        return this.http.put<any>( `${API}/api/Cidade/${cidade.Id}`, cidade);
      }else{
        return this.http.post<any>( `${API}/api/Cidade/`, cidade);
      }

    }

    uploadImagem(arquivo: any, id: number): Observable<any>{

      const url = `${API}/api/cidade/imagem/${id}/`;

      const headers = new HttpHeaders().append('noloader', 'noloader');
      const formData = new FormData();
      formData.append('Arquivo', arquivo);

      return this.http.post(url, formData, {headers : headers});

    }

    vinculaCidadeIntegrador (cidadesWebService: CidadesWebServices[]): Observable<any> {
      return this.http.post<any>( `${API}/api/cidade-integrador/vincular`, cidadesWebService);
    }

    desvincularCidadeIntegrador (cidadesWebService: CidadesWebServices[]): Observable<any> {
      return this.http.post<any>( `${API}/api/cidade-integrador/desvincular`, cidadesWebService);
    }

    buscarEnderecoPorCep(cep): Observable<any> {
        cep = cep.replace(/\D/g,'');
        let headers = new HttpHeaders().append('noloader', 'noloader').append('noauth', 'noauth');
        return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`, {headers: headers});
    }

    getQueryString(object) {

      if (!object)
          return ''

      if (typeof (object) === 'string')
          return object;

      if (typeof (object) === 'object')
          object = {...object}

      let queryString = ''

      // Monta a querystring
      Object.keys(object).forEach((key) => {
          if (object[key] !== null && object[key] !== undefined && object[key] !== '') {
              if (queryString.length > 0)
                  queryString += '&'
              queryString += key + '=' + object[key];
          }
      });

      return queryString;

    }

  }
