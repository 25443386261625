// canLoad controla o carregamento do módulo

import { CanLoad, Route, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';

@Injectable()
export class LoggedInGuard implements CanLoad, CanActivate{

  permissoes = [];
  isRoot = false;
  constructor(private loginService: LoginService){
    const permi = window.localStorage.getItem('gy84d8');

    if (permi != null){
      this.permissoes = JSON.parse(atob(permi));

      if (this.permissoes.indexOf('root') > -1){
        this.isRoot = true;
      }
    }

  }

  checkAuthentication(path: string): boolean{
    const loggedin = this.loginService.isLoggedIn();
    if (!loggedin){
      this.loginService.handleLogin(`/${path}`);
    }
    return loggedin;
  }


  checkPermission(permissoesNecessarias): boolean{

    let retorno = false;

    if(!permissoesNecessarias || permissoesNecessarias.permissao == null)
    if(!permissoesNecessarias || !Array.isArray(permissoesNecessarias) || permissoesNecessarias.length == 0)
    {
      retorno = true;
      return true;
    };


    if (this.isRoot){
      return true;
    }else{
      if (permissoesNecessarias.permissao != null){
        retorno = retorno && permissoesNecessarias.permissao.some((element, index, array) => this.existeNasPermissoes(element, index, array));
      }
      return retorno;
    }
  }

  canLoad(route: Route): boolean{
    return this.checkAuthentication(route.path) ;
  }

  canActivate(
    activatedRoute: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot): boolean{

      return this.checkAuthentication(activatedRoute.routeConfig.path) && this.checkPermission(activatedRoute.data);
    }



    existeNasPermissoes(element, index, array){
      return (this.permissoes.indexOf(element) > -1);
    }

  }
