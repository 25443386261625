import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CarroService } from 'src/app/services';
import { __spreadArrays, __read } from 'tslib';

@Component({
  selector: 'app-carro-cancela',
  templateUrl: './carro-cancela.component.html',
  styleUrls: ['./carro-cancela.component.scss']
})
export class CarroCancelaComponent implements OnInit {

  @Output() alterou = new EventEmitter<any>();

  @Output() fechou = new EventEmitter<any>();

  @Input()
  carro: any;
  carregando = false;
  mensagensErro: any[];
  mensagemSucesso: string;
  constructor(private carroService: CarroService) { }

ngOnInit() {
}

cancelar() {
  delete this.mensagensErro;
  delete this.mensagemSucesso;
  this.carregando = true;
  this.carroService.cancelarCarro(this.carro).subscribe(
    dados => {
      console.log(dados);
      this.carro = dados.Carro;
      this.alterou.emit(this.carro);
      alert('Cancelamento efetivado!');
      this.carregando = false;

    }, erro => {
      this.carregando = false;
      alert(erro);
      this.mensagensErro = this.errorHandler(erro);

    });
}

public errorHandler(erro): string[] {
  let mensagem = [];
  switch (erro.status) {
    case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
    case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
    case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
    case 404: mensagem.push("Solicitação inválida!"); break;
    case 406: mensagem.push(erro.error.mensagem); break;
    case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'); break;
    default: mensagem.push(erro.statusText);
  }
  if (erro.error != null && erro.error.mensagens != null) {
    mensagem =  __spreadArrays([mensagem], __read(erro.error.mensagens));
    // mensagem = [...mensagem, ...erro.error.mensagens];
  }
  return mensagem;

}
}
