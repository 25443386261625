import { Injectable } from "@angular/core";
export class Aereo {
  public Id: number;
  public selecionado: boolean;
  public AereoOperacao: boolean;
  public Seguimentos: any;
  public Status: number;
  public VendaTotal: number;
  public Fornecedor: string;
  public ExtraIntegracao: string;
  public ExtraIntegracao2: ExtraIntegracao;
  public PaxesTarifa: any;
  public TarifasTotal: any;
  public TaxasTotal: any;
  public LocalizadorAereo: any;
  public LimiteTempoEmissao: any;
  public Erros: any;

  public AeroportoPartida: string;
  public AeroportoChegada: string;
  public DataPartida: Date;
  public DataChegada: Date;
  public PaisChegada: any;
  public Voos: any;
}

class ExtraIntegracao {
  public Disponivel: number;
  public Reservado: number;
  public Emitido: number;
}
export class Segmento {
  public AeroportoPartida: string;
  public AeroportoChegada: string;
  public DataPartida: Date;
  public PaisChegada: any;
}

@Injectable()
export class BuscaAereoRQ {
  // tslint:disable-next-line: no-use-before-declare
  public FiltroAereo: FiltroAereo = new FiltroAereo();
  public Adt: number;
  public Chd: number;
  public Snr: number;
  public Inf: number;
  public Idades: string;
  public SegmentosBusca: Segmento[];
  public Guid: string;
  public Rebusca: boolean;
  public ReservaId: number;
  public AgenciaId: any;
  public AereoConsolidacao: boolean;
  public TipoBusca: string;
  public MaximoRegistros: number;
  public VooDireto: boolean;
  public PacoteId = 0;

}

export class FiltroAereo {
  public ValorMinimo: number;
  public ValorMaximo: number;
  public HorarioIdaMinimo = 0;
  public HorarioIdaMaximo = 24;
  public HorarioVoltaMinimo = 0;
  public HorarioVoltaMaximo = 24;
  public Cias: string[] = [];
  public Voos: number[];
  public Bagagem = -1;
  public SomenteVoosDiretos: boolean;
  public TodasFamilias: boolean;
  public Tarifa: string[];
  public Fornecedores: string[] = [];
  public MinimoDuracaoTrechos: number[] = [];
  public MaximoDuracaoTrechos: number[] = [];
  public NumeroParadasIda: Number = -1;
  public NumeroParadasVolta: Number = -1;
}
