import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { ServicoOffline } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class ServicoOfflineService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{

      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservico/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservico?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservico/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlineservico/${id}`);
    }

    salvar(servicooffline: ServicoOffline): Observable<any>{
      if (servicooffline.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/offlineservico/${servicooffline.Id}`, servicooffline);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/offlineservico/`, servicooffline);
      }

    }

    uploadImagem(arquivo: any, id: number): Observable<any>{

      const url = `${GERENCIAMENTO}/api/offlineservico/imagem/${id}/`;

      const headers = new HttpHeaders().append('noloader', 'noloader');
      const formData = new FormData();
      formData.append('Arquivo', arquivo);

      return this.http.post(url, formData, {headers : headers});

    }

    removerImagem(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlineservico/imagem/remover/${id}`);
    }

    vinculaCidade (id: number, cidadeId: number): Observable<any> {
    return this.http.post<any>( `${GERENCIAMENTO}/api/offlineservico-cidade/vincular/${id}/${cidadeId}`, {});
    }

    desvincularCidade (id: number, cidadeId: number): Observable<any> {
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlineservico-cidade/desvincular/${id}/${cidadeId}`, {});
    }

  }
