// tslint:disable:ban-types
// tslint:disable: max-line-length

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BuscaPacoteRQ, BuscaServicoRQ, BuscaHotelRQ } from '../../model';

import { API } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class PacotelstService {

  constructor(private http: HttpClient) {

  }

  buscarPacotes(busca: string, datain: any, dataout: any, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (busca.length > 0 || datain != null || dataout != null) {
      return this.http.post<any>(`${API}/api/Pacote/list?Pagina=${pagina}&ItensPorPagina=${9}`, { headers: headers, Busca: busca, Datain: datain, Dataout: dataout });
    } else {
      return this.http.get<any>(`${API}/api/Pacote?Pagina=${pagina}&ItensPorPagina=${9}`, { headers });
    }
    // if (busca.length > 0) {
    //   return this.http.get<any>(`${API}/api/Pacote/list/${busca.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers });
    // } else {
    //   return this.http.get<any>(`${API}/api/Pacote?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers });
    // }
  }

  buscar(busca: BuscaHotelRQ): Observable<any> {
    return this.http.post<any>(`${API}/api/pacote/busca/`, busca, { headers: new HttpHeaders() });
  }

  reservar(PacoteReservaId: number): Observable<any> {
    return this.http.post<any>(`${API}/api/pacote/reservar/${PacoteReservaId}`, {});
  }

  cancelar(PacoteReservaId: number): Observable<any> {
    return this.http.post<any>(`${API}/api/pacote/cancelar/${PacoteReservaId}`, {});
  }

  remover(PacoteReservaId: number): Observable<any> {
    return this.http.delete<any>(`${API}/api/pacotereserva/remover/${PacoteReservaId}`, {});
  }

  salvarObservacaoVoucher(pacoteId, Observacao): Observable<any>{
    const formData = new FormData();
    formData.append('Observacao', Observacao);
    return this.http.post<any>( `${API}/api/Pacote/voucher/observacao/salvar/${pacoteId}`, formData);
  }
}
