import { Cidade } from '../endereco.model';

export class Hoteloffline{

  public Id: number;
  public RazaoSocial: string;
  public Nome: string;
  public CNPJ: string;
  public Categoria: string;
  public Descricao: string;
  public InibeVoucher: any;
  public display: any;
  public Email: string;
  public EmailReserva: string;
  public Markup: any;
  public Telefone: string;
  public MoedaId: number;
  public TaxaISS: any;
  public TaxaServico: any;
  public TaxaOutra: any;
  public Cep: any;
  public Logradouro: any;
  public Numero: any;
  public Bairro: any;
  public Complemento: any;
  public Ativo: any;
  public Recomendado: any;
  public ERPId: any;
  public Alteracoes: any;


  public Cidade: Cidade = new Cidade();
  public CategoriasApartamento: CategoriaOfflineApartamento[];
  public Imagens: OfflineHotelImagens[];
  public RegraTarifaria : OfflineHotelRegraTarifaria[];
  public ManutencaoPeriodo : OfflineHotelManutencaoPeriodo[];
  public PromocaoTarifa : OfflineHotelPromocaoTarifa[];

}

export class CategoriaOfflineApartamento {
  public Id: number;
  public Hoteloffline: Hoteloffline;
  public OfflineHotelId: number;
  public Apartamentos: OfflineApartamento[] = [];
  public Imagens: OfflineCategoriaApartamentoImagem[];


  public Nome: any;
  public Descricao: any;
  public Regime: any;
  public ExigePrePagamento: any;

}

export class OfflineApartamento {
  public Id: number;
  public CategoriaOfflineApartamento: CategoriaOfflineApartamento;
  public OfflineCategoriaApartamentoId: number;

  public Tipo: any;
  public AdtMax: any;
  public ChdMax: any;
  public ChdFree: any;
  public ChdFreeIdade: any;
  public Chd1IdadeDe: any;
  public Chd1IdadeAte: any;
  public Chd1Quantidade: any;
  public Chd2IdadeDe: any;
  public Chd2IdadeAte: any;
  public Chd2Quantidade: any;
  public Tarifas : OfflineApartamentoTarifa[];

  public tarifa: {};
}

export class OfflineHotelImagens {
  public Id: number ;
  public OfflineHotelId: number;
  public URL: string;
}

export class OfflineCategoriaApartamentoImagem {
  public Id: number ;
  public OfflineCategoriaApartamentoId: number;
  public URL: string;
}

export class OfflineApartamentoTarifa {
  public Id : number;
  public OfflineApartamentoId: number;
  public Tarifa : number;
  public TarifaCHD1 : number;
  public TarifaCHD2 : number;
  public CheckinDe : any;
  public CheckoutAte : any;
  public ValidadeDe : any;
  public ValidadeAte : any;
  public MinNoites : number;
  public Politica : string;
  public DiasCancelamentoSemCusto : number;
  public selecionado : any;
}

export class OfflineHotelRegraTarifaria {
  public Id: number ;
  public OfflineHotelId: number;
  public Descricao: string;
  public MinimoNoite: number;
  public PeriodoDe: any;
  public PeriodoAte: any;
}

export class OfflineHotelManutencaoPeriodo {
  public Id: number ;
  public OfflineHotelId: number;
  public Descricao: string;
  public PeriodoDe: any;
  public PeriodoAte: any;
}

export class OfflineHotelPromocaoTarifa {
  public Id: number ;
  public OfflineHotelId: number;
  public Descricao: string;
  public NumeroNoites: Number;
  public GanhaNumeroNoites: Number;
  public PeriodoDe: any;
  public PeriodoAte: any;
}
