import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { API } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';
import { BuscaSeguroRQ } from 'src/app/model/turismo/seguro.model';

@Injectable()
export class SeguroService {
  constructor(private http: HttpClient) { }

  buscarSeguro(busca: BuscaSeguroRQ, pagina: number): Observable<any> {
    return this.http.post<any>(`${API}/api/Seguro/busca?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  detalhesCobertura(seguro: any): Observable<any> {
    return this.http.patch<any>(`${API}/api/seguro/detalhes`, seguro, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  removerSeguro(seguro: any): Observable<any> {
    return this.http.delete<any>(`${API}/api/Seguro/${seguro.Id}`);
  }

  reservarSeguro(seguro: any): Observable<any> {
    return this.http.post<any>(`${API}/api/seguro/reservar/${seguro.Id}`, seguro, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  atualizarStatusSeguro(seguro: any): Observable<any> {
    return this.http.patch<any>(`${API}/api/seguro/atualizar/${seguro.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  cancelarSeguro(seguro: any): Observable<any> {
    return this.http.post<any>(`${API}/api/seguro/cancelar/${seguro.ReservaId}/${seguro.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
  }
}
