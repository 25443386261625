import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Util } from 'src/app/services';

@Component({
  selector: 'app-servico-detalhes',
  templateUrl: './servico-detalhes.component.html',
  styleUrls: ['./servico-detalhes.component.scss']
})
export class ServicoDetalhesComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  @Input()
  servico: any;

  public customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoWidth: true,
    margin: 10,
    autoHeight: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    center: true,
  };

  ngOnInit() {
    // if (this.servico.Fornecedor == 'OS') {
    //
    //   for (let imagem of this.servico.Imagens) {
    //       imagem = Util.pathImagemApi(imagem);
    //   }
    //
    //   this.customOptions = { ...this.customOptions, loop: false };
    //
    // }
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

}
