import { FiltroServico, Servico } from './../../../model/turismo/servico.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Busca, Paginacao } from 'src/app/model';
import { Options } from 'ng5-slider';
import * as moment from 'moment';
import { Subscription, Observable } from 'rxjs';
import { ServicoService } from 'src/app/services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lista-servico',
  templateUrl: './lista-servico.component.html',
  styleUrls: ['./lista-servico.component.scss']
})
export class ListaServicoComponent implements OnInit {
  constructor(
    private servicoService: ServicoService,
    public _router: Router,
    public route: ActivatedRoute,
    ) { }

  @Output() selecionou = new EventEmitter<any>();
  @Output() avancar = new EventEmitter<any>();
  @Output() voltar = new EventEmitter<any>();
  @Output() removeu = new EventEmitter<any>();
  @Input() events: Observable<void>;
  @Input() Orcamento: Servico[] = [];
  @Input() busca: Busca;

  private eventsSubscription: Subscription;

  FiltroServico: FiltroServico = new FiltroServico();
  paginacao: Paginacao = new Paginacao();
  servicos: Servico[] = [];
  buscasAtivas = [];
  guid: string = '';
  buscando = false;
  rebusca = false;
  indiceAtual = 0;
  timer = null;

  condicaoVoltar: boolean;

  OpcoesValor : Options = {
    floor: 0,
    ceil: 99999,
  };

  cidades = [];
  contador = 0;

  ngOnInit(){
    this.busca.trechos = this.busca.trechos.map(x => {
      x.dataIda = moment(x.dataIda);
      x.minDataIda = moment(x.minDataIda);

      if(this.busca.tipoBuscaAereo === 'ida-volta'){
        x.dataVolta = moment(x.dataVolta);
        x.minDataVolta = moment(x.minDataVolta);
      }else{
        x.dataVolta = moment(x.dataIda.format("YYYY-MM-DD")).add(1,'day');
        x.minDataVolta = moment(x.dataIda.format("YYYY-MM-DD")).add(1,'day');
      }
      return x;
    });

    const tbusca = this.busca.tipoBusca;
    ((tbusca.aereo || tbusca.hotel)
      ? this.condicaoVoltar = true
      : this.condicaoVoltar = false)

    if(!this.busca.trechos[0].cidadeDestino.display_field  && this.busca.trechos[0].AeroportoChegada.display_field){
      this.busca.trechos[0].cidadeDestino = this.busca.trechos[0].AeroportoChegada.Cidade;
      this.busca.trechos[0].cidadeDestino.display_field = this.busca.trechos[0].cidadeDestino.Nome + ' - ' + this.busca.trechos[0].cidadeDestino.Estado.Nome + ' / ' + this.busca.trechos[0].cidadeDestino.Estado.Pais.Nome;;
    }

    this.buscar();

    this.eventsSubscription = this.events.subscribe((busca) => this.refazerBusca(busca));
  }

  refazerBusca(busca) {
    if (this.aba() == 'lista-servico') {
      this.busca = busca;
      this.busca.primeira = false;
      window.scroll(0, 0);
      this.paginacao.NumeroPagina = 1;
      this.buscando = true;
      this.buscar();
    }
  }
  aba() {
    if (this.route.snapshot.params.tipo != null) {

      return this.route.snapshot.params.tipo;
    } else {
      const x = this._router.url.split('/');

      return x[x.length - 1];
    }
  }
  rotaAvancar() {
    this.avancar.emit('x');
  }

  rotaAnterior() {
    this.voltar.emit('x');
  }

  buscar(buscaServico = null){

    if ( buscaServico == null ){
      buscaServico = this.busca.getBuscaServico();
    }

    buscaServico.FiltroServico = this.FiltroServico;

    if (!this.buscando){
      this.servicos = [];
    }

    this.buscando = true;
    this.servicoService.buscarTicket(buscaServico, this.paginacao.NumeroPagina).subscribe(
      dados => {


        this.buscasAtivas =  JSON.parse(dados.MetaData.BuscasAtivas);
        this.busca.guid = dados.MetaData.Guid;
        buscaServico.Guid = dados.MetaData.Guid;
        this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacao.TotalItens = dados.MetaData.TotalItens;
        this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
        this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;


        const meta = JSON.parse(dados.MetaData.MetaDados);
        if (meta != null ){
          const meta = JSON.parse(dados.MetaData.MetaDados);

          if(! (this.FiltroServico.ValorMinimo > 0)){
            this.FiltroServico.ValorMinimo =  meta.ValorMinimo;
            this.FiltroServico.ValorMaximo = meta.ValorMaximo;
          }

          //atualiza o slider
          const newOptions: Options = Object.assign({}, this.OpcoesValor);
          newOptions.floor = meta.ValorMinimo;;
          newOptions.ceil = meta.ValorMaximo;
          this.OpcoesValor = newOptions;


        }
        else
        {
          this.FiltroServico.ValorMinimo = 0;
          this.FiltroServico.ValorMaximo = 0;

          const newOptions: Options = Object.assign({}, this.OpcoesValor);
          newOptions.floor = 0;
          newOptions.ceil = 99999;
          this.OpcoesValor = newOptions;

        }

        window.sessionStorage.setItem('busca', JSON.stringify(this.busca));

        this.servicos = dados.Items.map(x => {
          x.Id = x.Id * -1;
          x.vermais = false;
          x.selecionado = this.servicoSelecionado(x);
          // console.log(x);
          return x;
        });

        //Filtra os serviços que possuem o atributo DiasSemana, e cria dentro do objeto serviço
        //um array contento os valores inteiros dos dias da semana, para transformar 0,1,2,3,4,5,6 em
        //Dom, Seg, Ter, Qua, Qui, Sex, Sáb.

        this.servicos.filter(x => x.DiasSemana != undefined && x.DiasSemana != null).map(x => {
          x.ListDiasSemana = x.DiasSemana.split(",").map(Number);
          return x;
        })


        this.contador++;

        if (this.buscasAtivas.length > 0 && (window.location.href.split('/').indexOf("lista-servico") != -1 || window.location.href.split('/').indexOf("adicionar") != -1)){
          setTimeout(() => {this.buscar(buscaServico); }, 3000);
        }else{
          this.buscando = false;
        }

      },
      erro => {
        console.log(erro);
        this.buscando = false;
      },
    );
  }

  buscarPagina(pagina){
    window.scroll(0, 0);
    this.paginacao.NumeroPagina = pagina;
    this.buscar();
  }

  buscarDebounce() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.refazerBusca(this.busca), 600);
  }

  selecionarServico(servico){
    servico.selecionado = true;
    this.Orcamento.push(servico);
    this.selecionou.emit(servico);

  }

  servicoSelecionado(servico){
    const dado = this.Orcamento.filter(x => x.Id == servico.Id);
    return dado.length > 0;
    //return false;
  }

  removerServico(servico){
    servico.selecionado = false;
    let indice = -1;
    for (let i = 0; i < this.Orcamento.length; i++){
      if (this.Orcamento[i].Id == servico.Id){
        indice = i;
      }
    }
    if (indice >= 0){
      this.Orcamento.splice(indice, 1);
      this.removeu.emit(servico);
    }
  }


  somaTotal(arr){
    return arr.map(x => x.ValorListagem).reduce(function(a, b){
      return a + b;
    }, 0);
  }

  onChange(event) {
    this.busca.primeira = false;
    this.rebusca = true;
  }

  limparFiltros() {
    this.FiltroServico = new FiltroServico();
    this.buscar();
  }

  distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  }

}
