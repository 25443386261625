import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-comprovante-cartao',
  templateUrl: './comprovante-cartao.component.html',
  styleUrls: ['./comprovante-cartao.component.scss']
})
export class ComprovanteCartaoComponent implements OnInit {

  @Input() pagamento: any;
  hoje = new Date();
  mensagemSucesso = null;
  mensagensErro = null;
  carregando = false;

  @Output() fechou = new EventEmitter<any>();

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.hoje = new Date();
    console.log(this.pagamento);

  }

  fecharModal() {
    this.modalService.dismissAll();
  }

}
