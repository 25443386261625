import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Options } from 'ng5-slider';
import { ReservaService } from 'src/app/services';

@Component({
  selector: 'app-reserva-termometro',
  templateUrl: './reserva-termometro.component.html',
  styleUrls: ['./reserva-termometro.component.scss']
})
export class ReservaTermometroComponent implements OnInit {

  @Input() Orcamento: any;
  @Output() alterou = new EventEmitter<any>();
  
  value : number = 0;
  comissao : number = 0;
  buscandoTermometro = true;
  Ciente = false;
  percentualMax : any;

  options : Options = {
    floor : -999,
    ceil : 999,
    step: 0.01,
    showSelectionBar: true,
    getSelectionBarColor: (value: number): string => {
      if (value >= (this.comissao * (25 / 100)) && value < this.comissao) {
          return 'yellow';
      }
      if (value >= 0 && value <= (this.comissao * (25 / 100))) {
          return '#2AE02A';
      }
      if (value >= this.comissao && value < this.options.ceil) {
          return 'orange';
      }
      return 'red';
    },
    translate: (value: number): string => {
      return value + ` (${Math.round((100 * value) / this.Orcamento.ValorTotalComissao)}%)`
    },

    vertical: true
  };

  constructor(private modalService:NgbModal, private reservaService:ReservaService) { }

  ngOnInit() {
    this.buscaTermometro(this.Orcamento.Id);
  }

  buscaTermometro(id) {
    this.reservaService.buscaTermometro(id).subscribe(
      dados => {
        this.options.floor = 0 - dados.floor;
        this.options.ceil = dados.ceil;
        this.comissao = Number(dados.value.toFixed(2));
        console.log(this.comissao)
        this.value = dados.value
        this.buscandoTermometro = false;

        if (this.Orcamento.ValorDescontoComissao && this.Orcamento.ValorDescontoComissao != 0) {
          this.value = this.comissao + this.Orcamento.ValorDescontoComissao;
        }
        else if (this.Orcamento.ValorOver && this.Orcamento.ValorOver != 0) {
          this.value = this.comissao + this.Orcamento.ValorOver;
        }

        this.value = Number(this.value.toFixed(2));


      },
      erro => {
        console.log(erro);
      }
    )
  }

  alterar() {

      if (this.value > this.comissao) {
        this.Orcamento.ValorDescontoComissao = 0;
        this.Orcamento.ValorOver = this.value - this.comissao;
      }
      else if (this.value < this.comissao) {
        this.Orcamento.ValorOver = 0;
        this.Orcamento.ValorDescontoComissao = this.value - this.comissao;
      }
      else if (this.value == this.comissao) {
        this.Orcamento.ValorOver = 0;
        this.Orcamento.ValorDescontoComissao = 0;
      }

      this.Orcamento.CienteAlteracaoComissao = true;

      this.reservaService.alterarOrcamento(this.Orcamento).subscribe(
        dados => {
          alert("Orçamento alterado com sucesso !");
          this.alterou.emit();
        },
        erro => {
          alert("Ocorreu um erro ao atualizar o orçamento !");
        }
      )
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

}
