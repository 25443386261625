import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';

import { API } from '../app.config';
import { CLIENT_ID } from '../app.config';


@Injectable()
export class LoginService {


  constructor(private http: HttpClient, private router: Router) {}

  today(): Date {
    return new Date();
  }

  user(): any {
    const usuario = JSON.parse(window.localStorage.getItem('loggedUser'));
    const agora = Date.now() / 1000;
    if ( usuario === null) {
      this.handleLogin('');
      return null;
    } else
    if (agora < usuario.token_expire) {
      return usuario;
    } else {
      this.logout();
    }

  }

  isLoggedIn() {
    return this.user() != null;
  }

  handleLogin(caminho: string) {
    // this.router.navigate(['/login', btoa(caminho)])
    this.router.navigate(['/']);
  }

  logar(username: string, password: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-type', 'application/json');
    const body = `grant_type=password&username=${username}&password=${password}&client_id=${CLIENT_ID}`;

    return this.http.post<any>(
      `${API}/token`,  body, {headers})
      .do(dados => {
        const data = dados.access_token.toString().split('.');
        const userTemp = JSON.parse(atob(data[1]));
        const usuario = JSON.parse(userTemp.usuario);

        usuario.access_token = dados.access_token;
        usuario.token_expire = (Date.now() / 1000) + dados.expires_in;

        window.localStorage.setItem('loggedUser', JSON.stringify(usuario));

        this.buscarDadosUsuario(usuario);

      });

    }

    buscarDadosUsuario(usuario) {
      // delete usuario.Nome;
      // window.localStorage.setItem('loggedUser', JSON.stringify(usuario));
      this.getUserById(usuario.Id).subscribe(
        dados => {

          const user = dados.Items[0];

          // delete user.Perfis;
          delete user.Roles;
          delete user.Alteracoes;
          delete user.Cidade.Alteracoes;
          // delete user.Agencia;

          user.VisualizaIntegradorAereo = user.Perfis.map(x => x.Permissoes).flat().some(y => y.Regra == "visualiza_integrador_aereo");

          user.access_token = usuario.access_token;
          user.token_expire = usuario.token_expire;
          window.localStorage.removeItem('loggedUser');
          window.localStorage.setItem('loggedUser', JSON.stringify(user));

        }, erro => {
          console.log('ERRO AO BUSCAR DADOS DO USUARIO');
          console.log(erro);


        }
      );



    }

    logout() {

      window.localStorage.clear();
      window.sessionStorage.clear();
      this.handleLogin('');
    }

    getUserById(id: number): Observable<any> {
      return this.http.get<any>( `${API}/api/usuario/${id}?Pagina=1&ItensPorPagina=1`);
    }
  }
