import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Busca, Orcamento } from 'src/app/model';
import { ReservaService } from 'src/app/services';

export const BUSCA = 'busca';
export const ORCAMENTO = 'orcamentousuario';

@Component({
  selector: 'app-listas',
  templateUrl: './listas.component.html',
  styleUrls: ['./listas.component.scss']
})
export class ListasComponent implements OnInit {

  eventsSubject: Subject<void> = new Subject<void>();

  constructor(
    public reservaService: ReservaService,
    public _router: Router,
    public route: ActivatedRoute,
  ) { }

  busca: Busca = new Busca();
  indiceAtual = 0;
  progress: any;

  Orcamento: Orcamento = new Orcamento();

  aba() {
    if (this.route.snapshot.params.tipo != null) {

      return this.route.snapshot.params.tipo;
    } else {
      const x = this._router.url.split('/');

      return x[x.length - 1];
    }
  }

  ngOnInit() {
    this.aba()

    const id = this.route.snapshot.params.id;
    const busca = window.sessionStorage.getItem(BUSCA);
    if (busca !== null) {
      this.busca = this.busca.decodeBusca(busca);
      this.Orcamento.Id = this.busca.ReservaId;
    } else {
      // Caso não exista busca configurada, redireciona para a aereo
      if (id !== undefined) {
        this._router.navigate(['/' + id]);
      } else {
        this._router.navigate(['']);
      }
    }

    if (id !== undefined) {
      this.busca.primeira = false;
      this.busca.novoOrcamento = false;
      this.busca.orcamentoId = id;
      this.reservaService.buscaReservaBusca(id).subscribe(
        dados => {
          this.busca.quartos = dados.quartos;
          window.sessionStorage.setItem(BUSCA, JSON.stringify(this.busca));
        },
        erros => {
          console.log(erros);
        }
      );
    } else {
      // Se não existe ID

      const orcamento = window.sessionStorage.getItem(ORCAMENTO);
      if (orcamento != null) {
        this.Orcamento = JSON.parse(orcamento);
      }

      if (busca !== null) {
        this.busca = this.busca.decodeBusca(busca);
        this.Orcamento.Id = this.busca.ReservaId;
      } else {
        this._router.navigate(['']);
      }
      if (this._router.url === '/listas') {
        alert('verifica os passos da busca');
        this._router.navigate(['']);
      }

      const atualArr = this._router.url.split('/');
      const atual = atualArr[atualArr.length - 1];
    }
  }

  atualizarOrcamento(event, adicionou) {
    window.sessionStorage.setItem(ORCAMENTO, JSON.stringify(this.Orcamento));
    if (this.busca.primeira && adicionou) {
      window.sessionStorage.setItem(BUSCA, JSON.stringify(this.busca));

      const id = this.route.snapshot.params.id;

      if (this.aba() === 'aereo' && id === undefined) {
        this.rotaAvancar();
      }
    }
  }

  podeVoltar(): boolean {
    const atualArr = this._router.url.split('/');
    const atual = atualArr[atualArr.length - 1];
    return this.indiceAtual > 0 || atual === 'orcamento';
  }

  rotaAvancar() {
    const tipo = this.aba();
    switch (tipo) {
      case 'lista-aereo':
        if (this.busca.tipoBusca.hotel) { return this.rota('lista-hotel'); }
        if (this.busca.tipoBusca.ticket) { return this.rota('lista-servico'); }
        if (this.busca.tipoBusca.carro) { return this.rota('lista-carro'); }
        if (this.busca.tipoBusca.seguro) { return this.rota('lista-seguro'); }
      return this.rota('orcamento');
      break;
      case 'lista-hotel':
          if (this.busca.tipoBusca.ticket) { return this.rota('lista-servico'); }
          if (this.busca.tipoBusca.carro) { return this.rota('lista-carro'); }
          if (this.busca.tipoBusca.seguro) { return this.rota('lista-seguro'); }
      return this.rota('orcamento');
      break;
      case 'lista-servico':
          if (this.busca.tipoBusca.carro) { return this.rota('lista-carro'); }
          if (this.busca.tipoBusca.seguro) { return this.rota('lista-seguro'); }
          return this.rota('orcamento');
          break;
      case 'lista-carro':
        if (this.busca.tipoBusca.seguro) { return this.rota('lista-seguro'); }
          return this.rota('orcamento');
      break;
      case 'lista-seguro':
        return this.rota('orcamento');
      break;
        }
  }

  rotaAnterior() {
    const tipo = this.aba();

    switch (tipo) {
      case 'orcamento':
        if (this.busca.tipoBusca.seguro) { return this.rota('lista-seguro'); }
        if (this.busca.tipoBusca.carro) { return this.rota('lista-carro'); }
        if (this.busca.tipoBusca.ticket) { return this.rota('lista-servico'); }
        if (this.busca.tipoBusca.hotel) { return this.rota('lista-hotel'); }
      return this.rota('lista-aereo');
      case 'lista-seguro':
        if (this.busca.tipoBusca.carro) { return this.rota('lista-carro'); }
        if (this.busca.tipoBusca.ticket) { return this.rota('lista-servico'); }
        if (this.busca.tipoBusca.hotel) { return this.rota('lista-hotel'); }
      return this.rota('lista-aereo');
      case 'lista-carro':
        if (this.busca.tipoBusca.ticket) { return this.rota('lista-servico'); }
        if (this.busca.tipoBusca.hotel) { return this.rota('lista-hotel'); }
      return this.rota('lista-aereo');
      case 'lista-servico':
        if (this.busca.tipoBusca.hotel) { return this.rota('lista-hotel'); }
      return this.rota('lista-aereo');
      case 'lista-hotel':
        if (this.busca.tipoBusca.hotel) { return this.rota('lista-aereo'); }
      return this.rota('lista-aereo');
    }
  }

  rota(texto) {
    if (this.busca.novoOrcamento) {
      this._router.navigate([`listas/${texto}`]);
    } else {
      this._router.navigate([
        `adicionar/${this.busca.orcamentoId}/${texto}`
      ]);
    }
  }

  rebuscou(busca) {
    this.eventsSubject.next(busca);
    console.log(busca);
  }

}
