import { CidadesWebServices } from '../endereco.model';
import { Markup } from './markup.model';

export class WebService {
  Id: number;
  CidadesWebServices: CidadesWebServices[];
  Descricao: string;
  Senha?: string;
  PortalSenha?: string;
  Markups: Markup[];
  Sigla: any;
  SiglaInterna: any;
  IdErp: any;
  OpcaoBusca: any;
  Url: any;
  UrlAlternativa: any;
  Login: any;
  Token: any;
  TipoEmpresa: any;
  Producao: any;
  TemSubFornecedor: any;
  Timeout: any;
  ExpireToken: any;
  Aereo: any;
  Hotel: any;
  Ingresso: any;
  Transfer: any;
  Carro: any;
  Seguro: any;
  AereoOperacao: boolean;
  Pagamento : any;
  Alteracoes: any;

}

export class SubFornecedor {
  Id: number;
  Nome: any;
  Descricao: any;
  IRRF: any;
  Alteracoes: any;

}
