import { __spreadArrays, __read } from 'tslib';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SeguroService } from 'src/app/services';

@Component({
  selector: 'app-seguro',
  templateUrl: './seguro.component.html',
  styleUrls: ['./seguro.component.scss']
})
export class SeguroComponent implements OnInit {
  @Output() selecionouSeguro = new EventEmitter<any>();
  @Output() removeuSeguro = new EventEmitter<any>();
  @Output() detalhesSeguro = new EventEmitter<any>();
  @Input() seguro;
  @Input() usuario;
  @Input() index;
  @Input() selecionar = true;
  
  mensagensErro = [];
  carregando = false;
  modalLoadded = false;

  constructor(
    private seguroService: SeguroService
    ) { }

  ngOnInit() {
  }

  selecionou(seguro) {
    this.selecionouSeguro.emit(seguro);
  }

  removeu(seguro) {
    this.removeuSeguro.emit(seguro);
  }

  // detalhes(seguro) {
  //   this.detalhesSeguro.emit(seguro);
  // }

  GetDetalhesCobertura(seguro) {
    this.carregando = true;
    this.seguroService.detalhesCobertura(seguro).subscribe(
      dados => {
        this.seguro = dados.Seguro;
        // this.LngLat = "[" + this.hotel.Longitude.replace(/,/g, '.') + "," + this.hotel.Latitude.replace(/,/g, '.') + "]";
        this.carregando = false;
      }, erro => {
        this.carregando = false;
        this.mensagensErro = this.errorHandler(erro);
      });
  }

  public errorHandler(erro): string[] {
    let mensagem = [];
    switch (erro.status) {
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
      case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
      case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
      case 404: mensagem.push('Solicitação inválida!'); break;
      case 406: mensagem.push(erro.error.mensagem); break;
      // case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ); break;
      case 500: mensagem.push(erro.error.ExceptionMessage); break;
      default: mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      // mensagem = [...mensagem, ...erro.error.mensagens];
      mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
    }
    return mensagem;
  }
  
}
