import { Component, NgModule, OnInit } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, FormsModule, ValidationErrors, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { NgxMaskModule } from 'ngx-mask';
import { Financiamento } from 'src/app/model';
import { CidadeService, FinanciamentoService, ProfissaoService } from 'src/app/services';

@Component({
    selector: 'app-financiamento-form',
    templateUrl: './financiamento-form.component.html',
    styleUrls: ['./financiamento-form.component.scss']
})

@NgModule({
    imports: [BrowserModule, FormsModule, NgxMaskModule.forRoot()],
    declarations: [FinanciamentoFormComponent],
    bootstrap: [FinanciamentoFormComponent]
})
export class FinanciamentoFormComponent implements OnInit {

    // Variáveis
    emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    numberPattern = /^[0-9]*$/;

    profissoes = [];
    form: FormGroup;
    myGroup: FormGroup;
    moment: any = moment;

    mensagensErro = [];
    mensagemErro = '';
    mensagemSucesso = '';
    dataNascimento: any;
    carregamento = 2;
    salvo = false;
    registro: Financiamento = new Financiamento();
    ConsultaSPC: any;
    avisoTerceiros = '';

    profissao: any;
    data: [];
    permissoes: any;
    visualiza_analise_financiamento = false;
    public display = {
        CidadeDS: "Cidade",
        EstadoDS: "Estado",
        profissao: "Profissão"
    }
    confirmacaoService: any;


    constructor(
        private cidadeService: CidadeService,
        private formBuilder: FormBuilder,
        public router: Router,
        public route: ActivatedRoute,
        private financiamentoService: FinanciamentoService,
        private profissaoService: ProfissaoService,
        public modalService: NgbModal,
    ) { }

    ngOnInit() {

        this.form = this.formBuilder.group({
            Nome: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
            Sobrenome: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
            CPF: this.formBuilder.control('', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]),
            RG: this.formBuilder.control('', [Validators.required]),
            OrgaoEmissor: this.formBuilder.control('', [Validators.required]),
            DataNascimento: this.formBuilder.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
            Email: this.formBuilder.control('', [Validators.required, Validators.pattern(this.emailPattern)]),
            Celular: this.formBuilder.control('', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]),
            TelefoneResidencial: this.formBuilder.control('', [Validators.required, Validators.minLength(10), Validators.maxLength(11)]),
            TipoResidencia: this.formBuilder.control('', [Validators.required]),
            TemCNPJ: this.formBuilder.control('', [Validators.required]),
            CNPJ: this.formBuilder.control(''),
            Cep: this.formBuilder.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
            Logradouro: this.formBuilder.control({ value: '', disable: false }, [Validators.required]),
            Numero: this.formBuilder.control('', [Validators.required]),
            Complemento: this.formBuilder.control('', []),
            Bairro: this.formBuilder.control({ value: '', disable: false }, [Validators.required]),
            Cidade: this.formBuilder.control({ value: '', disable: false }, [Validators.required]),
            Estado: this.formBuilder.control({ value: '', disable: false }, [Validators.required]),

            profissao: this.formBuilder.control('', [Validators.required]),
            ReferenciaComercial: this.formBuilder.control('', [Validators.required]),
            TelefoneComercial: this.formBuilder.control('', [Validators.required, Validators.minLength(10), Validators.maxLength(11)]),
            Ramal: this.formBuilder.control(''),
            EnderecoComercial: this.formBuilder.control('', [Validators.required]),
            Renda: this.formBuilder.control('', [Validators.required]),

            BancoNumero: this.formBuilder.control('', [Validators.required]),
            ClienteDesde: this.formBuilder.control('', [Validators.required, Validators.minLength(7), Validators.maxLength(7)]),
            AgenciaNumero: this.formBuilder.control('', [Validators.required]),
            AgenciaDigito: this.formBuilder.control('', [Validators.required]),
            ContaNumero: this.formBuilder.control('', [Validators.required]),
            ContaDigito: this.formBuilder.control('', [Validators.required]),
            CidadeDs: this.formBuilder.control(''),
            EstadoDs: this.formBuilder.control(''),
            Produto: this.formBuilder.control('', [Validators.required]),
        });

        const id = this.route.snapshot.params['id'];
        if (id !== undefined) {
            this.carregarObjeto(id);
        }

        let permi = window.localStorage.getItem("gy84d8");
        if (permi != null) {
            this.permissoes = JSON.parse(atob(permi));
        }
    }

    carregarObjeto(id: number) {
        delete this.mensagemSucesso;
        delete this.mensagensErro;

        this.financiamentoService.buscarPorId(id).subscribe(
            dados => {

                this.registro = dados.Items[0];

                this.atribuicaoValores(this.registro.TemCNPJ, this.registro.EstadoDs);

                if (this.registro.DataNascimento) {
                    this.dataNascimento = moment(this.registro.DataNascimento).format("DDMMYYYY");
                }

                if (this.registro.FinanceiroTerceiros) {
                    this.avisoTerceiros = `<p>Por se tratar de um financiamento de terceiros por favor enviar os documentos abaixo, no e-mail credito@funpay.tur.br.</p>` +
                        `<p>O assunto do e-mail deve ser ` + this.registro.Nome + ' ' + this.registro.Sobrenome + ' - ' + this.registro.CPF +
                        `<p>Documentos que comprovem grau de parentesco: cópia do RG ou CNH (passageiro viajante) +
                                cópia do RG ou CNH e comprovante de residência (cliente pagante).
                                Caso você tenha optado pela opção "outros" será obrigatório a declaração de pagamento de terceiros.
                                Neste caso, entre em contato com o seu consultor de vendas da FRT.</p>`;
                }

                // if (this.registro.Status == 0) {
                //   this.registro.Nome = '';
                //   this.registro.Sobrenome = '';
                //   this.registro.CPF = '';
                // }
                this.carregamento--;
                if (this.carregamento == 0) {
                    this.selecionaProfissao(this.registro.Profissao);
                }
            }, erros => {
                console.log("Erro ao buscar o registro: " + erros);
                this.mensagensErro = this.errorHandler(erros);
            }
        );
    }


    selecionaProfissao(item: { Descricao: any; CodigoFornecedor: any; }) {
        this.registro.Profissao = item.Descricao;
        this.registro.ProfissaoCodigo = item.CodigoFornecedor;
    }

    onChangeProfissao(val: string) {
        this.profissaoService.buscarPorParametro(val, 1).subscribe(
            (dados) => {
                this.data = dados.Items.map((x: { display_field: any; Descricao: any; }) => {
                    x.display_field = x.Descricao;
                    return x;
                });
            }, (erro) => {
                console.log(erro);
                this.data = [];
            }
        );
    }

    confirmarEnviarProposta() {
        this.mensagemErro = '';
        this.mensagemSucesso = '';
        // this.confirmacaoService.confirm("Confirmação de Envio de Proposta de Financiamento",
        //   "Tem certeza que deseja enviar a proposta? ",
        //   "Enviar", "Voltar")
        //   .then((confirmed) => {
        //     if (confirmed) {
        //       this.enviarProposta();
        //     }
        //   }).catch(() => console.log(""));
        this.enviarProposta();
    }

    enviarProposta() {
        this.mensagemSucesso = null;
        this.mensagensErro = null;
        this.registro.DataNascimento = moment(this.dataNascimento, "ddMMyyyy", true);

        this.financiamentoService.enviarProposta(this.registro).subscribe(
            dados => {
                if (!this.registro.Id) {
                    this.mensagemSucesso = "Proposta enviada com sucesso!";
                    this.router.navigate([this.router.url + "/" + dados.Id]);
                }
                this.registro = dados;
                this.mensagemSucesso = "Proposta enviada com sucesso!";
                this.registro.DataNascimento = this.registro.DataNascimento.format("DDMMYYYY");
            },
            erros => {
                console.log("Erro ao Salvar");
                console.log(erros);
                this.mensagensErro = this.errorHandler(erros);
                this.registro.DataNascimento = this.registro.DataNascimento.format("DDMMYYYY");
            }
        );
    }

    MaskCpf(v) {
        v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
        v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
        //de novo (para o segundo bloco de números)
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
        return v
    }

    MaskCnpj(v) {
        v = v.replace(/\D/g, "")                           //Remove tudo o que não é dígito
        v = v.replace(/^(\d{2})(\d)/, "$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
        v = v.replace(/(\d{4})(\d)/, "$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
        return v
    }

    salvar() {
        this.mensagemSucesso = null;
        this.mensagensErro = null;
        this.registro.DataNascimento = moment(this.dataNascimento, "DDMMYYYY");
        this.registro.CPF = this.MaskCpf(this.registro.CPF);
        if (this.registro.ProfissaoCodigo > 0) {
            this.financiamentoService.salvar(this.registro).subscribe(
                dados => {
                    if (!this.registro.Id) {
                        this.mensagemSucesso = "Registro Salvo com Sucesso!";
                        this.router.navigate([this.router.url + "/" + dados.Id]);
                    }
                    this.mensagemSucesso = "Registro Salvo com Sucesso!";
                    this.salvo = true;
                    this.registro.DataNascimento = this.registro.DataNascimento.format("DDMMYYYY");
                }, erros => {
                    console.log("Erro ao Salvar");
                    console.log(erros);

                    this.mensagensErro = this.errorHandler(erros);
                }
            );
        } else {
            alert("Selecione uma profissão da lista!");
        }
    }

    cnpjConditionallyRequiredValidator(event: any) {
        console.log(event);
        if (this.registro.TemCNPJ) {
            this.form.get('CNPJ').setValidators([Validators.required]);
        } else {
            this.form.get('CNPJ').setValidators([]);
        }
        this.form.get('CNPJ').updateValueAndValidity();
    }

    dateMask(event: { target: { value: string; }; }) {
        let v = event.target.value;

        if (v.match(/^\d{2}$/) !== null) {
            event.target.value = v + "/";
        } else if (v.match(/^\d{4}\/\d{4}$/) !== null) {
            event.target.value = v + "/";
        }
    }

    verificarStatus() {
        this.mensagemSucesso = null;
        this.mensagensErro = null;

        this.financiamentoService.verificarProposta(this.registro.Id).subscribe(
            dados => {
                if (this.registro.Status == dados.Status) {
                    this.registro = dados;
                    this.mensagemSucesso = "Ainda não houve alteração no status do financiamento!";
                } else if (dados.Status == 2) {
                    this.mensagemSucesso = "O financiamento foi aprovado!";
                    this.registro = dados;
                    this.registro.DataNascimento = moment(this.registro.DataNascimento).format("ddMMyyyy");
                } else if (dados.Status == 3) {
                    this.mensagensErro = ["O financiamento foi reprovado."];
                    this.registro = dados;
                    this.registro.DataNascimento = moment(this.registro.DataNascimento).format("ddMMyyyy");
                } else {
                    this.registro = dados;
                    this.mensagensErro = ["O Financiamento " + this.registro.StatusDesc];
                    this.registro.DataNascimento = moment(this.registro.DataNascimento).format("ddMMyyyy");
                }

                this.visualiza_analise_financiamento = this.permissoes.indexOf("visualiza_analise_financiamento") > -1
                    || this.permissoes.indexOf("admin") > -1
                    || this.permissoes.indexOf("root") > -1;

                if (!this.visualiza_analise_financiamento) {
                    this.visualiza_analise_financiamento = this.registro.ReservaPagamento.Reserva.Agencia.VisualizaAnaliseFinanciamento;
                }

                this.ConsultaSPC = JSON.parse(this.registro.ConsultaSpc);
                console.log(this.ConsultaSPC);
            }, erros => {
                console.log("Erro ao Salvar");
                console.log(erros);
                this.mensagensErro = this.errorHandler(erros);
            }
        );
    }

    irReserva() {
        //;
        this.router.navigate([`/orcamento/${this.registro.ReservaId}`]);
    }


    getFormValidationErrors(form: { controls: {}; get: (arg0: string) => { (): any; new(): any; errors: ValidationErrors; }; }) {
        let erros = [];
        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    const erro = { key: key, keyError: keyError, value: controlErrors[keyError] };
                    erros = [Array.from(erros), erro];
                });
            }
        });
        if (erros[0] != undefined && erros[0].length > 0) {
            erros = erros[0];
        }
        if (erros[0].length <= 0) {
            erros = erros;
        }
        return erros;
    }

    validaData(data: moment.MomentInput) {

        data = moment(data, 'DDMMYYYY', true);
        let now = moment();
        return data && data.isValid() && data < now;
    }

    setDataNascimento($event: any) {
        this.dataNascimento = $event;
    }

    buscaCepPax() {
        this.cidadeService.buscarEnderecoPorCep(this.registro.Cep).subscribe(
            dados => {
                this.registro.Logradouro = dados.logradouro;
                this.registro.Bairro = dados.bairro;
                this.registro.Complemento = dados.complemento;
                this.registro.CidadeDs = dados.localidade;
                this.registro.EstadoDs = dados.uf;
            });
    }

    public errorHandler(erro: { status: any; error: { mensagem: any; mensagens: Iterable<unknown> | ArrayLike<unknown>; }; statusText: any; }): string[] {
        let mensagem = [];
        switch (erro.status) {
            case 0:
                mensagem.push("Não foi possível conectar ao servidor.");
                break;
            case 400:
                mensagem.push(erro.error.mensagem);
                break;
            case 401:
                mensagem.push("Não autorizado.");
                break;
            case 404:
                mensagem.push(erro.error.mensagem);
                break;
            case 406:
                mensagem.push(erro.error.mensagem);
                break;
            case 500:
                mensagem.push("Erro interno do servidor! Tente novamente, caso o erro persista, contate o administrador do sistema.");
                break;
            default:
                mensagem.push(erro.statusText);
        }
        if (erro.error != null && erro.error.mensagens != null) {
            mensagem = [Array.from(mensagem), Array.from(erro.error.mensagens)];
        }
        return mensagem;
    }


    copyToClipboard() {

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.registro.Nome + " " + this.registro.Sobrenome + " - " + this.registro.CPF;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

    }

    itemModal = null;
    abrirModal(content: any, item: any) {
        this.itemModal = item;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true, size: 'lg' });
    }

    // Valores para o destino
    paises = [
        'Brasil',
        'Afeganistão',
        'África do Sul',
        'Albânia',
        'Alemanha',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antilhas Holandesas',
        'Antárctida',
        'Antígua e Barbuda',
        'Argentina',
        'Argélia',
        'Armênia',
        'Aruba',
        'Arábia Saudita',
        'Austrália',
        'Áustria',
        'Azerbaijão',
        'Bahamas',
        'Bahrein',
        'Bangladesh',
        'Barbados',
        'Belize',
        'Benim',
        'Bermudas',
        'Bielorrússia',
        'Bolívia',
        'Botswana',
        'Brunei',
        'Bulgária',
        'Burkina Faso',
        'Burundi',
        'Butão',
        'Bélgica',
        'Bósnia e Herzegovina',
        'Cabo Verde',
        'Camarões',
        'Camboja',
        'Canadá',
        'Catar',
        'Cazaquistão',
        'Chade',
        'Chile',
        'China',
        'Chipre',
        'Colômbia',
        'Comores',
        'Coreia do Norte',
        'Coreia do Sul',
        'Costa do Marfim',
        'Costa Rica',
        'Croácia',
        'Cuba',
        'Dinamarca',
        'Djibouti',
        'Dominica',
        'Egito',
        'El Salvador',
        'Emirados Árabes Unidos',
        'Equador',
        'Eritreia',
        'Escócia',
        'Eslováquia',
        'Eslovênia',
        'Espanha',
        'Estados Federados da Micronésia',
        'Estados Unidos',
        'Estônia',
        'Etiópia',
        'Fiji',
        'Filipinas',
        'Finlândia',
        'França',
        'Gabão',
        'Gana',
        'Geórgia',
        'Gibraltar',
        'Granada',
        'Gronelândia',
        'Grécia',
        'Guadalupe',
        'Guam',
        'Guatemala',
        'Guernesei',
        'Guiana',
        'Guiana Francesa',
        'Guiné',
        'Guiné Equatorial',
        'Guiné-Bissau',
        'Gâmbia',
        'Haiti',
        'Honduras',
        'Hong Kong',
        'Hungria',
        'Ilha Bouvet',
        'Ilha de Man',
        'Ilha do Natal',
        'Ilha Heard e Ilhas McDonald',
        'Ilha Norfolk',
        'Ilhas Cayman',
        'Ilhas Cocos (Keeling)',
        'Ilhas Cook',
        'Ilhas Feroé',
        'Ilhas Geórgia do Sul e Sandwich do Sul',
        'Ilhas Malvinas',
        'Ilhas Marshall',
        'Ilhas Menores Distantes dos Estados Unidos',
        'Ilhas Salomão',
        'Ilhas Virgens Americanas',
        'Ilhas Virgens Britânicas',
        'Ilhas Åland',
        'Indonésia',
        'Inglaterra',
        'Índia',
        'Iraque',
        'Irlanda do Norte',
        'Irlanda',
        'Irã',
        'Islândia',
        'Israel',
        'Itália',
        'Iêmen',
        'Jamaica',
        'Japão',
        'Jersey',
        'Jordânia',
        'Kiribati',
        'Kuwait',
        'Laos',
        'Lesoto',
        'Letônia',
        'Libéria',
        'Liechtenstein',
        'Lituânia',
        'Luxemburgo',
        'Líbano',
        'Líbia',
        'Macau',
        'Macedônia',
        'Madagáscar',
        'Malawi',
        'Maldivas',
        'Mali',
        'Malta',
        'Malásia',
        'Marianas Setentrionais',
        'Marrocos',
        'Martinica',
        'Mauritânia',
        'Maurícia',
        'Mayotte',
        'Moldávia',
        'Mongólia',
        'Montenegro',
        '"Red Hat Display"',
        'Moçambique',
        'Myanmar',
        'México',
        'Mônaco',
        'Namíbia',
        'Nauru',
        'Nepal',
        'Nicarágua',
        'Nigéria',
        'Niue',
        'Noruega',
        'Nova Caledônia',
        'Nova Zelândia',
        'Noto Sans',
        'Níger',
        'Omã',
        'Palau',
        'Palestina',
        'Panamá',
        'Papua-Nova Guiné',
        'Paquistão',
        'Paraguai',
        'País de Gales',
        'Países Baixos',
        'Peru',
        'Pitcairn',
        'Polinésia Francesa',
        'Polônia',
        'Porto Rico',
        'Portugal',
        'Quirguistão',
        'Quênia',
        'Reino Unido',
        'República Centro-Africana',
        'República Checa',
        'República Democrática do Congo',
        'República do Congo',
        'República Dominicana',
        'Reunião',
        'Romênia',
        'Ruanda',
        'Rubik',
        'Rússia',
        'Saara Ocidental',
        'Saint Martin',
        'Saint-Barthélemy',
        'Saint-Pierre e Miquelon',
        'Samoa Americana',
        'Samoa',
        'Santa Helena, Ascensão e Tristão da Cunha',
        'Santa Lúcia',
        'Senegal',
        'Serra Leoa',
        'Seychelles',
        'Singapura',
        'Somália',
        'Sri Lanka',
        'Suazilândia',
        'Sudão',
        'Suriname',
        'Suécia',
        'Suíça',
        'Svalbard e Jan Mayen',
        'São Cristóvão e Nevis',
        'São Marino',
        'São Tomé e Príncipe',
        'São Vicente e Granadinas',
        'Sérvia',
        'Síria',
        'Tadjiquistão',
        'Tailândia',
        'Taiwan',
        'Tanzânia',
        'Terras Austrais e Antárticas Francesas',
        'Território Britânico do Oceano Índico',
        'Timor-Leste',
        'Togo',
        'Tonga',
        'Toquelau',
        'Trinidad e Tobago',
        'Tunísia',
        'Turcas e Caicos',
        'Turquemenistão',
        'Turquia',
        'Tuvalu',
        'Ucrânia',
        'Uganda',
        'Uruguai',
        'Uzbequistão',
        'Vanuatu',
        'Vaticano',
        'Venezuela',
        'Vietname',
        'Wallis e Futuna',
        'Zimbabwe',
        'Zâmbia'
    ]

    // Manipulação da DOM
    // valor padrão para o input anterior
    meuInputAnterior: boolean;
    valorEstado: string;
    atribuicaoValores(valor: boolean, estado: string) {
        if (valor == null || valor == undefined) {
            this.meuInputAnterior = false;
        } else {
            this.meuInputAnterior = valor;
        }
        this.valorEstado = estado;
    }

    // função para atualizar o valor do input anterior
    atualizarInputAnterior(valor: boolean) {
        this.meuInputAnterior = valor;
        this.registro.TemCNPJ = valor;
    }

    atualizarEstado(estado: string) {
        this.valorEstado = estado;
        this.registro.EstadoDs = estado;
    }

}
