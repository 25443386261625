// tslint:disable: max-line-length
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ServicoService } from 'src/app/services';
import { OrcamentoComponent } from 'src/app/views/orcamento/orcamento.component';
import { __read, __spreadArrays } from 'tslib';

@Component({
  selector: 'app-reserva-servico',
  templateUrl: './reserva-servico.component.html',
  styleUrls: ['./reserva-servico.component.scss']
})
export class ReservaServicoComponent implements OnInit {

  @Output() alterou = new EventEmitter<any>();
  @Output() fechou = new EventEmitter<any>();
  @Input() orcamento: OrcamentoComponent;
  @Input() servico: any;
  dataServico: any;
  datas: any;
  politica: any;
  mensagensErro: any[];
  mensagemSucesso: string;
  carregando = false;
  reservado = false;

  constructor(private servicoService: ServicoService) { }

  limparPolitica() {
    console.log('Limpando politica');
    delete this.politica;
    this.buscarRegras();
  }

  ngOnInit() {
    this.servico.De = moment(this.servico.De);
    this.servico.Ate = moment(this.servico.Ate);
    const dias = this.servico.Ate.diff(this.servico.De, 'days');
    let data = moment(this.servico.De.format('YYYY-MM-DD'));
    this.datas = [];

    if (this.servico.DatasDisponiveis !== undefined && this.servico.DatasDisponiveis.length > 0) {
      this.datas = this.servico.DatasDisponiveis.map(x => {
        x = moment(x);
        return x;
      });

      console.log(this.datas);

    } else {
      for (let i = 0; i < (dias + 1); i++) {
        this.datas.push(moment(data));
        data = data.add(1, 'd');

      }
    }

    this.dataServico = this.datas[0];


    this.buscarRegras();
  }

  buscarRegras() {
    delete this.mensagensErro;
    delete this.mensagemSucesso;
    this.carregando = true;
    this.servicoService.buscarPolitica(this.servico, this.dataServico.format('YYYY-MM-DD')).subscribe(
      dados => {

        this.politica = dados;
        this.carregando = false;

      }, erro => {
        this.carregando = false;
        console.log(erro);
        this.mensagensErro = this.errorHandler(erro);
      });
  }

  reservar() {
    delete this.mensagensErro;
    delete this.mensagemSucesso;
    this.carregando = true;
    this.servicoService.reservarServico(this.politica).subscribe(
      dados => {

        this.servico = dados.Servico;
        this.mensagemSucesso = 'Serviço reservado!';
        this.alterou.emit(this.servico);
        this.carregando = false;

      }, erro => {
        this.carregando = false;
        console.log(erro);
        this.mensagensErro = this.errorHandler(erro);
      });
  }


  perguntasValidas() {
    if (!this.politica) {
      return false;
    }

    if (!this.politica.Servico.Perguntas) {
      return true;
    }

    if (this.politica.Servico.Perguntas) {
      if (this.politica.Servico.Perguntas.length === 0) {
        return true;
      }

      if (this.politica.Servico.Perguntas.length > 0) {
        //        console.log(this.politica.Servico.Perguntas.filter(x => !x.Resposta || x.Resposta.length == 0).length == 0);
        return this.politica.Servico.Perguntas.filter(x => !x.Resposta || x.Resposta.length === 0).length === 0;
      }
    }
    return false;
  }

  public errorHandler(erro): string[] {

    let mensagem = [];
    switch (erro.status) {
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
      case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
      case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
      case 404: mensagem.push('Solicitação inválida!'); break;
      case 406: mensagem.push(erro.error.mensagem); break;
      case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'); break;
      default: mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
    }
    return mensagem;

  }


}
