import { Agencia } from './../../model/cadastro/agencia.model';
import { AppModule } from './../../app.module';
import {
    Component,
    ElementRef,
    HostListener,
    Input,
    NgModule,
    OnInit,
    ViewChild
} from "@angular/core";
import {
    Router,
    ActivatedRoute
} from "@angular/router";
import * as moment from "moment";
import {
    Subscription
} from "rxjs";
import {
    API
} from "src/app/app.config";
import * as cloneDeep from 'lodash/cloneDeep';

import {
    Servico,
    Orcamento,
    Hotel,
    Reserva,
    Aereo,
    Pacote,
    Voucher,
} from "src/app/model";
import {
    AereoService,
    AgenciaService,
    HotelService,
    ServicoService,
    ReservaService,
    LoginService,
    CarroService,
    SeguroService,
    UsuarioService,
    VoucherService,
    PacotelstService,
    ProdutoOfflineService
} from "src/app/services";
import {
    __spreadArrays,
    __read
} from "tslib";
import {
    Location
} from "@angular/common";
import {
    NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { ConfirmacaoService } from "src/app/components/confirmacao/confirmacao.service";
import { SelecionarConsultorComponent } from './selecionar-consultor/selecionar-consultor.component';
import { SelecionarAgenteComponent } from './selecionar-agente/selecionar-agente.component';
import { Seguro } from 'src/app/model/turismo/seguro.model';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
const {
    cpf
} = require('cpf-cnpj-validator');
@Component({
    selector: "app-orcamento",
    templateUrl: "./orcamento.component.html",
    styleUrls: ["./orcamento.component.scss"],
})
export class OrcamentoComponent implements OnInit {
    @Input() servicos: Servico;
    Orcamento: any = new Reserva();
    orcamento: Orcamento;
    @Input() hoteis: Hotel;
    @Input() reserva: Reserva;
    @Input() aereo: Aereo;
    @Input() pacote: Pacote;
    @Input() busca: any;
    vouchers: Voucher[] = [];
    objeto: any;
    aereoLoadded = false;
    hotelLoadded = false;
    servicoLoadded = false;
    pagamentoLoadded = false;
    pacoteLoadded = false;
    carroLoadded = false;
    seguroLoadded = false;
    estornoLoadded = false;
    comprovanteLoadded = false;
    newItem = false;
    apiLocale = API;
    Arr = Array;
    dif: number;
    alterapaxcomreserva: boolean = false;
    OfflineProdutoId: any;
    MensagemObs: string = '';

    config: any = {
        editable: true,
        spellcheck: true,
        height: "8rem",
        minHeight: "0",
        maxHeight: "auto",
        width: "auto",
        minWidth: "0",
        translate: "yes",
        enableToolbar: true,
        showToolbar: true,
        placeholder: "Digite suas observações...",
        defaultParagraphSeparator: "",
        defaultFontName: "",
        defaultFontSize: "",
        fonts: [{
            class: "arial",
            name: "Arial"
        },
        {
            class: "times-new-roman",
            name: "Times New Roman"
        },
        {
            class: "calibri",
            name: "Calibri"
        },
        {
            class: "comic-sans-ms",
            name: "Comic Sans MS"
        },
        ],
        customClasses: [{
            name: "quote",
            class: "quote",
        },
        {
            name: "redText",
            class: "redText",
        },
        {
            name: "titleText",
            class: "titleText",
            tag: "h3",
        },
        ],
        uploadUrl: "v1/image",
        sanitize: true,
        toolbarPosition: "top",
    };

    @ViewChild("reservarAereo") reservarAereo: ElementRef;
    @ViewChild("emitirAereo") emitirAereo: ElementRef;
    @ViewChild("cancelarAereo") cancelarAereo: ElementRef;
    @ViewChild("reservarHotel") reservarHotel: ElementRef;
    @ViewChild("reservarServico") reservarServico: ElementRef;
    @ViewChild("cancelarServico") cancelarServico: ElementRef;
    @ViewChild("reservarCarro") reservarCarro: ElementRef;
    @ViewChild("cancelarCarro") cancelarCarro: ElementRef;
    @ViewChild("reservarSeguro") reservarSeguro: ElementRef;
    @ViewChild("cancelarSeguro") cancelarSeguro: ElementRef;
    @ViewChild("pagamento") pagamentoModal: ElementRef;
    @ViewChild("estorno") estornoModal: ElementRef;
    @ViewChild("comprovante") comprovanteModal: ElementRef;
    @ViewChild(SelecionarConsultorComponent) selecionarConsultor: SelecionarConsultorComponent;
    @ViewChild(SelecionarAgenteComponent) selecionarAgente: SelecionarAgenteComponent;
    private routeSub: Subscription;

    constructor(
        public _router: Router,
        public route: ActivatedRoute,
        private aereoService: AereoService,
        private agenciaService: AgenciaService,
        private hotelService: HotelService,
        private servicoService: ServicoService,
        private reservaService: ReservaService,
        private loginService: LoginService,
        private usuarioService: UsuarioService,
        private carroService: CarroService,
        private seguroService: SeguroService,
        private _location: Location,
        private voucherService: VoucherService,
        private pacotelstService: PacotelstService,
        private modalService: NgbModal,
        private produtoOfflineService: ProdutoOfflineService,
        private confirmacaoService: ConfirmacaoService,
    ) {
        this.empresa = JSON.parse(sessionStorage.getItem("loggedUser"));
        this.dataAtual = moment().format("YYYY-MM-DD");
    }

    // Mensagens
    mensagemIdadeCertaVoltaViagem: string = null;
    mensagemSucessoPessoas: string = null;
    mensagensErroPessoas: string[] = null;
    mensagemSucessoHotel: string = null;
    mensagensErroHotel: string[] = null;
    mensagemSucessoAereo: string = null;
    mensagensErroAereo: string[] = null;
    mensagensErroReserva: string[];
    mensagemSucessoReserva: string;
    mensagemSucessoTransfer: string = null;
    mensagensErroTransfer: string[] = null;
    mensagemSucessoTicket: string = null;
    mensagensErroTicket: string[] = null;
    mensagemSucessoServico: string = null;
    mensagensErroServico: string[] = null;
    mensagensErroPacote: string[] = null;
    mensagensErroSeguro: string[] = null;

    // Booleans
    modalcomprovantecartao = false;
    passageirosConfirmados = false;
    IdadeCertaVoltaViagem = true;
    removeUltimoHotel = false;
    toggleConsultor = false;
    togglePessoas = false;
    auxAereoVolta = true;
    carregando = true;
    aprova_pix = false;
    auxAereoIda = true;
    perfilRoot: boolean;
    servicoCarregando: boolean;

    // Any
    alteracoesPessoas: any;
    agenteSelecionado: any;
    agenteListaRadio: any;
    chavePessoa: any;
    itemReserva: any;
    permissoes: any;
    dataAtual: any;
    Consultor: any;
    Agente: any;
    empresa: any;
    usuario: any;
    idTEMP: any;
    idTEMP2: any;
    aereos: any;
    agente: any;
    carregandoObj = false;
    spinnerAereo = false;
    spinnerHotel = false;
    spinnerServico = false;
    spinnerCarro = false;
    spinnerSeguro = false;
    spinnerPacote = false;

    // Other
    // pacote = [];
    incluso: string;
    opcoesValores = [];
    agenteLista: [];
    agencias = [];
    agentes = [];
    perfis = [];
    erros = [];
    agenciaId: number;
    hoje = moment();
    num: Hotel;
    totalServico = 0;

    NomeAgencia = "";
    agenteNome = "";

    distinct = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    console(obj) {
        console.log(obj);
    }

    alterouReserva(){
        this.idTEMP = this.route.snapshot.params.id;
        if (this.idTEMP !== undefined) {
            setTimeout(() => {
                this.carregarObjeto(this.idTEMP);
            }, 500);
        }
    }

    ngOnInit() {
        this.busca = JSON.parse(sessionStorage.getItem("busca"));
        this.dataAtual = moment().format("YYYY-MM-DD");
        this.idTEMP = this.route.snapshot.params.id;
        if (this.idTEMP !== undefined) {
            setTimeout(() => {
                this.carregarObjeto(this.idTEMP);
            }, 500);
        }

        this.usuario = this.loginService.user();
        const permi = window.localStorage.getItem("gy84d8");
        if (permi != null) {
            this.permissoes = JSON.parse(atob(permi));
            this.aprova_pix =
                this.permissoes.indexOf("aprova_pix") > -1 ||
                this.permissoes.indexOf("admin") > -1 ||
                this.permissoes.indexOf("root") > -1;

            this.removeUltimoHotel =
                this.permissoes.indexOf("remove_ultimo_hotel") > -1 ||
                this.permissoes.indexOf("admin") > -1 ||
                this.permissoes.indexOf("root") > -1;

            this.alterapaxcomreserva = this.permissoes.indexOf("alterapaxcomreserva") > -1;
        }

        this.buscaAgentes();
        this.servicoCarregando = true;
    }

    ngDoCheck() {

    }

    ngAfterViewInit() {
        this.servicoCarregando = false;

    }

    fecharModal() {
        this.modalService.dismissAll();
    }

    salvarObservacao(item: string) {
        //item = HOTEL/PACOTE
        if (item == "HOTEL") {
            this.hotelService.salvarObservacaoVoucher(this.Orcamento.Hoteis[this.objeto].Id, this.Orcamento.Hoteis[this.objeto].Observacoes).subscribe(
                dados => {
                    this.MensagemObs = "Observação Salva";
                    setTimeout(() => {
                        this.MensagemObs = '';
                        this.fecharModal();
                    }, 1200);

                },
                erros => {
                    console.log(erros);
                })
        } else if (item == "PACOTE") {
            this.pacotelstService.salvarObservacaoVoucher(this.Orcamento.PacoteReservas[this.objeto].Id, this.Orcamento.PacoteReservas[this.objeto].Observacao).subscribe(
                dados => {
                    this.MensagemObs = "Observação Salva";
                    setTimeout(() => {
                        this.MensagemObs = '';
                        this.fecharModal();
                    }, 1200);

                },
                erros => {
                    console.log(erros);
                }
            )
        }
    }

    abrirModal(content, item) {
        this.objeto = item;
        this.modalService.open(content, {
            centered: true,
            size: 'lg',
            backdrop: 'static'
        })
    }

    abrirModalTermometro(content, item) {
        this.objeto = item;
        this.modalService.open(content, {
            centered: true,
            size: 'lg'
        })
    }

    seguro: Seguro;
    GetDetalhesCobertura(seguro: Seguro) {
        this.seguroService.detalhesCobertura(seguro).subscribe(
            dados => {
                this.seguro = dados.Seguro;
                console.log(this.seguro);
                // this.LngLat = "[" + this.hotel.Longitude.replace(/,/g, '.') + "," + this.hotel.Latitude.replace(/,/g, '.') + "]";
                this.carregando = false;
            }, erro => {
                this.carregando = false;
                alert(erro);
            });
    }

    acessarFinanciamento(pgto) {
        this._router.navigate([`/financiamento/${pgto.Financiamentos[0].Id}`]);
    }

    abrirModalProdutoOffline(content, id = null) {
        this.OfflineProdutoId = id;
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title',
            backdrop: 'static',
            centered: true,
            size: 'lg'
        });
    }

    public confirmarRemoverProdutoOffline(produtooffline) {
        const resposta = window.confirm(
            `Tem certeza que deseja remover o produto offline?`
        );

        if (resposta) {
            this.removerProdutoOffline(produtooffline);
        } else {
            // some code
        }
    }

    removerProdutoOffline(produtooffline) {
        if (!this.removeUltimoHotel && (this.Orcamento.Aereos.length > 0 && !this.Orcamento.AereoConsolidacao && (this.Orcamento.OfflineProdutos.length == 1 && (this.Orcamento.Hoteis == null || this.Orcamento.Hoteis.length == 0) && (this.Orcamento.Servicos == null || this.Orcamento.Servicos.length == 0)))) {
            alert('É necessário manter pelo menos um item além do aéreo !')
        }
        else if (this.validaTermometro()) {
            alert("Necessário retornar o valor do termômetro para padrão antes de remover esse item.")
        }
        else {
            this.produtoOfflineService.remover(produtooffline.Id).subscribe(
                dados => {
                    this.Orcamento.OfflineProdutos = this.Orcamento.OfflineProdutos.filter(x => x.Id !== dados.Id);
                    this.mensagemSucessoReserva = `Produto Offline ${dados.Id} removido com sucesso!`;
                    this.atualizarOrcamento();
                    this.valoresOpcoes();
                },
                erro => {
                    this.mensagensErroReserva = this.errorHandler(erro);
                });
        }
    }
    voltar() {
        this._router.navigate([`/orcamentos`]);

    }

    buscarOrcamento() {
        this.idTEMP = this.route.snapshot.params.id;
        this.reservaService.buscarOrcamento(this.idTEMP).subscribe(
            (dados) => {
                this.reserva = dados;
                setTimeout(() => {
                    this.buscaAgentes(true);
                }, 100);
            },
            (erro) => console.log(erro)
        );
    }

    validaTermometro() {
        return (this.Orcamento.ValorDescontoComissao < 0 || this.Orcamento.ValorOver > 0);
    }

    buscaAgentes(primeira = false) {
        // const agente = JSON.parse(localStorage.getItem("loggedUser"));

        // if (agente.agenciaId == (null || undefined)) {
        //   const agencia = atob(localStorage.getItem("g8h7d8"));
        //   const agenciaId = JSON.parse(agencia);
        //   this.idTEMP2 = agenciaId.Id;
        // } else {
        //   this.idTEMP2 = agencia.AgenciaId;
        // }

        // this.usuarioService
        //   .buscarPorAgencia(this.agenteNome, 1, this.idTEMP2)
        //   .subscribe(
        //     (dados) => {
        //       this.agentes = dados.Items;

        //       if (primeira) {
        //         let i = this.agentes
        //           .map((x) => x.Id)
        //           .indexOf(this.Orcamento.AgenteId);
        //         this.Orcamento.Agente = this.agentes[i];
        //       }
        //     },
        //     (erros) => {
        //       console.log("Erro ao buscar usuarios da agencia: ", erros);
        //       this.agentes = [];
        //     }
        //   );
    }

    onChangeAgencia(val) {
        this.agenciaService.buscarPorParametro(val, 1).subscribe(
            (dados) => {
                this.agencias = dados.Items.map(function (x) {
                    x.display_field = x.NomeFantasia;
                    return x;
                });
            },
            (erro) => {
                console.log(erro);
                this.agencias = [];
            }
        );
    }

    selecionaAgencia(agencia) {
        let primeira = false;
        if (this.Orcamento.AgenciaId != agencia.Id) {
            this.Orcamento.Agente = null;
            this.Orcamento.AgenteId = 0;
            primeira = true;
        }

        this.Orcamento.Agencia = agencia;
        this.Orcamento.AgenciaId = agencia.Id;
        this.buscaAgentes(primeira);
    }
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    carregarObjeto(id) {
        this.reservaService.buscarOrcamento(id).subscribe(
            (dados) => {
                this.Orcamento = dados;
                setTimeout(() => {
                    this.selecionarConsultor.buscar(this.Orcamento.AgenciaId);
                }, 100);
                setTimeout(() => {
                    this.selecionarAgente.buscar(this.Orcamento.AgenciaId);
                }, 100);

                this.aereos = this.Orcamento.Aereos;
                this.hoteis = this.Orcamento.Hoteis;
                this.servicos = this.Orcamento.Servicos;
                this.agenciaId = this.Orcamento.AgenciaId;
                this.pacote = this.Orcamento.Hoteis.filter((x) => x.PacoteId != null);
                this.Orcamento.Pessoas = this.Orcamento.Pessoas.map((x) => {
                    x.Nascimento = x.Nascimento ?
                        moment(x.Nascimento).format("DDMMYYYY") :
                        null;
                    return x;
                });
                this.Orcamento.Quartos = this.Orcamento.Pessoas.map((x) => x.Quarto)
                    .filter(this.distinct)
                    .sort();
                this.Orcamento.Quartos = this.Orcamento.Quartos.map((x) => {
                    return {
                        quarto: x,
                        pessoas: this.Orcamento.Pessoas.filter((y) => y.Quarto === x),
                    };
                });
                this.Orcamento.Servicos = this.Orcamento.Servicos.map(x => {
                    if (this.isJson(x.ExtraIntegracao)) {
                        x.ExtraIntegracao2 = JSON.parse(x.ExtraIntegracao);
                    }
                    return x;
                });
                this.togglePessoas = !this.paxValido();
                this.valoresOpcoes();
                this.verificaIncluso();
                this.IdadeCertaVoltaViagem = this.Orcamento.Pessoas.every(
                    (x) => x.IdadeCertaVoltaViagem === true
                );
                if (!this.IdadeCertaVoltaViagem) {
                    this.mensagemIdadeCertaVoltaViagem = `
          <h5>Atenção:</h5>
          <p>
          Para que o sistema calcule corretamente o orçamento, é necessário informar uma data de nascimento compatível com a idade inserida na pesquisa.
          Importante levar em consideração a idade dos passageiros ao término da viagem, ou seja, caso algum passageiro faça aniversário durante a viagem,
          informe a idade que ele terá no dia do retorno. Essa informação é indispensável para cálculo de tarifa aérea,
          INF/CHD/ADT e também para políticas de CHD/ADT em hotéis, serviços de receptivo e seguro viagem.
          </p>`;
                }

                this.carregando = false;
                this.verificaConsultor();
            },
            (erro) => {
                console.log("Erro ao buscar o objeto da reserva");
                console.log(erro);
                this.mensagensErroReserva = this.errorHandler(erro);
            }
        );
    }

    verificaConsultor() {
        this.Orcamento.Consultor === (null || undefined) ?
            (this.Consultor = false) :
            (this.Consultor = true);
    }

    paxValido() {
        if (!this.Orcamento.Pessoas) {
            return true;
        }

        const temSeguro = this.Orcamento.Seguros.length > 0;
        return (
            this.Orcamento.Pessoas.filter(
                (pax) =>
                    !pax.Nome ||
                    !pax.Sobrenome ||
                    !pax.Nascimento ||
                    //   (!pax.Email && this.Orcamento.Aereos.length > 0) ||
                    (!pax.Rg && this.Orcamento.Servicos.length > 0) ||
                    //   (!pax.Telefone && this.Orcamento.Aereos.length > 0) ||
                    !this.validaData(pax.Nascimento) ||
                    !pax.Sexo ||
                    pax.Sexo === "N" ||
                    ((!pax.CPF || (pax.CPF.length == 0)) || pax.CPF && !this.validaCPF(pax.CPF))
                // || ((this.Orcamento.Aereos.length > 0 && (!pax.CPF || (pax.CPF.length == 0))) || pax.CPF && !this.validaCPF(pax.CPF))
            ).length === 0
        );
    }

    validaCPF(input) {
        return cpf.isValid(input);
    }

    validaData(data) {
        data = moment(data, "DDMMYYYY", true);
        const now = moment();
        return data && data.isValid() && data < now;
    }

    valoresOpcoes() {
        this.opcoesValores = [];

        const totalAereo = this.Orcamento.Aereos.map((x) => x.ValorVenda).reduce(
            function (a, b) {
                return a + b;
            },
            0
        );
        const totalServico = this.Orcamento.Servicos.map(
            (x) => x.ValorFechamento
        ).reduce(function (a, b) {
            return a + b;
        }, 0);
        const total = totalAereo + totalServico;

        let opcoes = this.Orcamento.Hoteis.map((x) => x.Opcao);
        opcoes = Array.from(new Set(opcoes));
        opcoes.forEach((element) => {
            const hoteis = this.Orcamento.Hoteis.filter(
                (x) =>
                    x.Opcao === element &&
                    x.Status !== 8 &&
                    x.Status !== 7 &&
                    x.Status !== 10
            );
            const totalHotel = hoteis
                .map((x) => x.ValorVendaMoedaDestino)
                .reduce(function (a, b) {
                    return a + b;
                }, 0);
            const dadoshoteis = hoteis.map((x) => {
                x.CheckinOpcao = moment(x.Checkin).format("DD/MM/YYYY");
                x.CheckoutOpcao = moment(x.Checkout).format("DD/MM/YYYY");
                return x;
            });

            let totalOpcao = total + totalHotel;

            if (this.Orcamento.ValorDescontoComissao) {
                totalOpcao += this.Orcamento.ValorDescontoComissao;
            }
            if (this.Orcamento.ValorOver) {
                totalOpcao += this.Orcamento.ValorOver;
            }

            this.opcoesValores.push({
                opcao: element,
                valor: totalOpcao,
                dados: dadoshoteis,
            });
            this.opcoesValores.sort(function (a, b) {
                return a.opcao - b.opcao;
            });
        });
    }

    verificaIncluso() {
        this.incluso = "";
        if (this.Orcamento.Aereos.length > 0) {
            this.incluso = "Aéreo";
        }

        if (this.Orcamento.Servicos.length > 0) {
            if (this.incluso !== "") {
                this.incluso += " + ";
            }
            this.incluso += "Serviço";
        }

        if (this.Orcamento.Seguros.length > 0) {
            if (this.incluso !== "") {
                this.incluso += " + ";
            }
            this.incluso += "Seguro";
        }
    }

    changeAgente(e) {
        this.agenteListaRadio = e.target.value;

        console.log("Change Agente: ", this.agenteListaRadio);
    }

    public errorHandler(erro): string[] {
        let mensagem = [];
        console.log(erro);
        switch (erro.status) {
            case 0:
                mensagem.push('o servidor não respondeu, tente novamente mais tarde!');
                break;
            case 400:
                mensagem.push('Houve algum erro na execução, tente novamente!');
                break;
            case 401:
                mensagem.push('você não tem autorização para executar esta ação!');
                break;
            case 404:
                mensagem.push('Solicitação inválida!');
                break;
            case 406:
                mensagem.push(erro.error.mensagem);
                break;
            case 500:
                mensagem.push(erro.error.ExceptionMessage);
                break;
            default:
                mensagem.push(erro.statusText);
        }
        if (erro.error != null && erro.error.mensagens != null) {
            mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
        }
        let mensagemErro = mensagem.join("\n");
        console.log(mensagemErro);
        alert(mensagemErro);
        return mensagem;
    }

    atualizarOrcamento() {
        this.carregarObjeto(this.Orcamento.Id);
    }

    noites(dataInicio, dataFim) {
        dataInicio = moment(dataInicio);
        dataFim = moment(dataFim);
        return dataFim.diff(dataInicio, "days");
    }

    removerItem(item, tipo) {
        if (this.validaTermometro()) {
            alert("Necessário retornar o valor do termômetro para padrão antes de remover esse item.")
        } else if (confirm("Confirmar exclusão de " + tipo)) {
            switch (tipo) {
                case "Aereo":
                    this.spinnerAereo = true;
                    this.aereoService.removerAereo(item).subscribe(
                        (dados) => {
                            this.Orcamento.Aereos = this.Orcamento.Aereos.filter(
                                (x) => x.Id !== dados.Id
                            );
                            alert(tipo + " removido com sucesso!");
                            this.atualizarOrcamento();
                            this.spinnerAereo = false;
                        },
                        (erro) => {
                            console.log(erro);
                            alert("Houve um erro ao deletar o item");
                            this.spinnerAereo = false;
                        }
                    );
                    break;
                case "Hotel":
                    this.spinnerHotel = true;
                    this.hotelService.removerHotel(item).subscribe(
                        (dados) => {
                            this.Orcamento.Hoteis = this.Orcamento.Hoteis.filter(
                                (x) => x.Id !== dados.Id
                            );
                            alert(tipo + " removido com sucesso!");
                            this.atualizarOrcamento();
                            this.spinnerHotel = false;
                        },
                        (erro) => {
                            console.log(erro);
                            alert("Houve um erro ao deletar o item");
                            this.spinnerHotel = false;
                        }
                    );
                    break;
                case "Servico":
                    this.spinnerServico = true;
                    this.servicoService.removerServico(item).subscribe(
                        (dados) => {
                            this.Orcamento.Servicos = this.Orcamento.Servicos.filter(
                                (x) => x.Id !== dados.Id
                            );
                            alert(tipo + " removido com sucesso!");
                            this.atualizarOrcamento();
                            this.spinnerServico = false;
                        },
                        (erro) => {
                            console.log(erro);
                            alert("Houve um erro ao deletar o item");
                            this.spinnerServico = false;
                        }
                    );
                    break;
                case "Carro":
                    this.spinnerCarro = true;
                    this.carroService.removerCarro(item).subscribe(
                        (dados) => {
                            this.Orcamento.Carros = this.Orcamento.Carros.filter(
                                (x) => x.Id !== dados.Id
                            );
                            alert(tipo + " removido com sucesso!");
                            this.atualizarOrcamento();
                            this.spinnerCarro = false;
                        },
                        (erro) => {
                            console.log(erro);
                            alert("Houve um erro ao deletar o item");
                            this.spinnerCarro = false;
                        }
                    );
                    break;
                case "Seguro":
                    this.spinnerSeguro = true;
                    this.seguroService.removerSeguro(item).subscribe(
                        (dados) => {
                            this.Orcamento.Seguros = this.Orcamento.Seguros.filter(
                                (x) => x.Id !== dados.Id
                            );
                            alert(tipo + " removido com sucesso!");
                            this.atualizarOrcamento();
                            this.spinnerSeguro = false;
                        },
                        (erro) => {
                            console.log(erro);
                            alert("Houve um erro ao deletar o item");
                            this.spinnerSeguro = false;
                        }
                    );
                    break;
                case "Pacote":
                    this.spinnerPacote = true;
                    this.pacotelstService.remover(item.Id).subscribe(
                        (dados) => {
                            this.Orcamento.PacoteReservas = this.Orcamento.PacoteReservas.filter(
                                (x) => x.Id !== dados.Id
                            );
                            alert(tipo + " removido com sucesso!");
                            this.atualizarOrcamento();
                            this.spinnerPacote = false;
                        },
                        (erro) => {
                            console.log(erro);
                            alert("Houve um erro ao deletar o item");
                            this.spinnerPacote = false;
                        }
                    );
                    break;
            }
        }
    }

    // AEREO SECTION
    atualizarAereo(aereo) {
        const i = this.Orcamento.Aereos.map((x) => x.Id).indexOf(aereo.Id);

        this.Orcamento.Aereos[i] = aereo;
        this.atualizarOrcamento();
    }

    limparMensagensAereo() {
        delete this.mensagemSucessoAereo;
        delete this.mensagensErroAereo;
    }

    // emitirAereo(aereo) {
    //   this.limparMensagensAereo();
    //   this.aereoService.emitirAereo(aereo).subscribe(
    //     (dados) => {
    //       console.log(dados);
    //       this.Orcamento.Aereos = this.Orcamento.Aereos.filter(
    //         (x) => x.Id !== dados.Id
    //       );
    //       this.mensagemSucessoAereo = `Aéreo ${dados.Id} emitido com sucesso!`;
    //       this.atualizarOrcamento();
    //     },
    //     (erro) => {
    //       this.mensagensErroAereo = this.errorHandler(erro);
    //     }
    //   );
    // }

    tarifarAereo(aereo) {
        this.limparMensagensAereo();
        this.aereoService.tarifarAereo(aereo).subscribe(
            (dados) => {
                aereo = dados;
                this.mensagemSucessoAereo = `Aéreo ${dados.Id} tarifado com sucesso com sucesso!`;
            },
            (erro) => {
                this.mensagensErroAereo = this.errorHandler(erro);
            }
        );
    }

    // HOTEL SECTION
    atualizarHotel(hotel) {
        const i = this.Orcamento.Hoteis.map((x) => x.Id).indexOf(hotel.Id);
        hotel.Cidade = this.Orcamento.Hoteis[i].Cidade;
        hotel.WebService = this.Orcamento.Hoteis[i].WebService;
        this.Orcamento.Hoteis[i] = hotel;
        this.atualizarOrcamento();
    }

    cancelarHotel(hotel) {
        let mensagem = [];

        if (confirm("Confirmar cancelamento do hotel?")) {
            this.spinnerHotel = true;

            // this.limparMensagensHotel();
            this.hotelService.cancelarHotel(hotel).subscribe(
                (dados) => {
                    hotel = dados;
                    mensagem.push("Cancelamento efetivado!");
                    this.spinnerHotel = false;
                    alert(mensagem);

                    this.atualizarHotel(hotel);
                },
                (erro) => {
                    switch (erro.status) {
                        case 0:
                            mensagem.push(
                                "o servidor não respondeu, tente novamente mais tarde!"
                            );
                            break;
                        case 400:
                            mensagem.push("Houve algum erro na execução, tente novamente!");
                            break;
                        case 401:
                            mensagem.push(
                                "você não tem autorização para executar esta ação!"
                            );
                            break;
                        case 404:
                            mensagem.push("Solicitação inválida!");
                            break;
                        case 406:
                            mensagem.push(erro.error.mensagem);
                            break;
                        case 500:
                            mensagem.push(erro.error.ExceptionMessage);
                            break;
                        default:
                            mensagem.push(erro.statusText);
                    }
                    if (erro.error != null && erro.error.mensagens != null) {
                        mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
                    }
                    alert(mensagem);
                    this.spinnerHotel = false;
                }
            );
        }
    }

    atualizarStatusHotel(hotel) {
        // this.limparMensagensHotel();
        hotel.carregando = true;
        const statusAtual = hotel.Status;
        this.hotelService.atualizarStatusHotel(hotel).subscribe(
            (dados) => {
                hotel = dados;
                this.atualizarHotel(hotel);
                if (hotel.Status === statusAtual) {
                    this.mensagemSucessoHotel = `Hotel ${hotel.Nome} segue com o mesmo status no fornecedor!`;
                    alert(this.mensagemSucessoHotel);
                } else {
                    // tslint:disable-next-line:max-line-length
                    this.mensagemSucessoHotel = `Hotel ${hotel.Nome
                        } teve o Status Alterado de ${this.getStatus(
                            statusAtual
                        )} para ${this.getStatus(hotel.Status)}!`;
                    alert(this.mensagemSucessoHotel);
                }

                hotel.carregando = false;
            },
            (erro) => {
                hotel.carregando = false;
                this.mensagensErroHotel = this.errorHandler(erro);
                alert(this.mensagensErroHotel);
            }
        );
    }

    // SERVICOS SECTION



    atualizarServico(servico) {
        const i = this.Orcamento.Servicos.map((x) => x.Id).indexOf(servico.Id);
        this.Orcamento.Servicos[i] = servico;
    }

    salvarDadosPessoas() {
        this.limparMensagensPessoas();
        this.Orcamento.Pessoas = this.Orcamento.Pessoas.map((x) => {
            x.Nascimento = moment(x.Nascimento, "DDMMYYYY");
            return x;
        });
        this.reservaService.salvarDadosPessoas(this.Orcamento.Pessoas).subscribe(
            (dados) => {
                this.Orcamento.Pessoas = dados;

                this.Orcamento.Pessoas = this.Orcamento.Pessoas.map((x) => {
                    x.Nascimento = x.Nascimento ?
                        moment(x.Nascimento).format("DD/MM/YYYY") :
                        null;
                    return x;
                });
                this.Orcamento.Quartos = this.Orcamento.Pessoas.map((x) => x.Quarto)
                    .filter(this.distinct)
                    .sort();
                this.Orcamento.Quartos = this.Orcamento.Quartos.map((x) => {
                    return {
                        quarto: x,
                        pessoas: this.Orcamento.Pessoas.filter((y) => y.Quarto === x),
                    };
                });
                this.IdadeCertaVoltaViagem = dados.every(
                    (x) => x.IdadeCertaVoltaViagem === true
                );
                if (!this.IdadeCertaVoltaViagem) {
                    this.mensagemIdadeCertaVoltaViagem = `<h5>Atenção:</h5>
          <p>
          Para que o sistema calcule corretamente o orçamento, é necessário informar uma data de nascimento compatível com a idade inserida na pesquisa.
          Importante levar em consideração a idade dos passageiros ao término da viagem, ou seja, caso algum passageiro faça aniversário durante a viagem,
          informe a idade que ele terá no dia do retorno. Essa informação é indispensável para cálculo de tarifa aérea,
          INF/CHD/ADT e também para políticas de CHD/ADT em hotéis, serviços de receptivo e seguro viagem.
          </p>`;
                }
                this.mensagemSucessoPessoas = "Dados atualizados com sucesso!";

                alert("PAX confirmados/editados");
            },
            (erro) => {
                console.log("Erro ao salvar pessoas!");
                console.log(erro);
                this.mensagensErroPessoas = this.errorHandler(erro);
            }
        );
    }

    limparMensagensPessoas() {
        delete this.mensagemSucessoPessoas;
        delete this.mensagensErroPessoas;
        delete this.mensagemIdadeCertaVoltaViagem;
    }

    carregar(obj, tipo) {
        this.objeto = obj;
        console.log(this.objeto);
        if (this.paxValido()) {
            switch (tipo) {
                case "aereo":
                    this.aereoLoadded = true;
                    setTimeout(() => {
                        this.reservarAereo.nativeElement.click();
                    }, 100);
                    break;
                case "aereo-cancelar":
                    this.aereoLoadded = true;
                    setTimeout(() => {
                        this.cancelarAereo.nativeElement.click();
                    }, 100);
                    break;
                case "aereo-emitir":
                    this.aereoLoadded = true;
                    setTimeout(() => {
                        this.emitirAereo.nativeElement.click();
                    }, 100);
                    break;
                case "hotel":
                    this.hotelLoadded = true;
                    setTimeout(() => {
                        this.reservarHotel.nativeElement.click();
                    }, 100);
                    break;
                case "hotel":
                    this.hotelLoadded = true;
                    setTimeout(() => {
                        this.reservarHotel.nativeElement.click();
                    }, 100);
                    break;
                case "servico":
                    this.servicoLoadded = true;
                    setTimeout(() => {
                        this.reservarServico.nativeElement.click();
                    }, 100);
                    break;
                case "servico-cancelar":
                    this.servicoLoadded = true;
                    setTimeout(() => {
                        this.cancelarServico.nativeElement.click();
                    }, 100);
                    break;
                case "carro":
                    this.carroLoadded = true;
                    setTimeout(() => {
                        this.reservarCarro.nativeElement.click();
                    }, 100);
                    break;
                case "carro-cancelar":
                    this.carroLoadded = true;
                    setTimeout(() => {
                        this.cancelarCarro.nativeElement.click();
                    }, 100);
                    break;
                case "seguro":
                    this.seguroLoadded = true;
                    setTimeout(() => {
                        this.reservarSeguro.nativeElement.click();
                    }, 100);
                    break;
                case "seguro-cancelar":
                    this.seguroLoadded = true;
                    setTimeout(() => {
                        this.cancelarSeguro.nativeElement.click();
                    }, 100);
                    break;
                case "pagamento":
                    this.pagamentoLoadded = true;
                    setTimeout(() => {
                        this.pagamentoModal.nativeElement.click();
                    }, 100);
                    break;
                case "estorno":
                    this.estornoLoadded = true;
                    setTimeout(() => {
                        this.estornoModal.nativeElement.click();
                    }, 100);
                    break;
                case "comprovante":
                    this.comprovanteLoadded = true;
                    setTimeout(() => {
                        this.comprovanteModal.nativeElement.click();
                    }, 100);
                    break;
            }
        } else {
            alert("Verifique os dos Pax!");
        }
    }

    getStatus(status) {
        switch (status) {
            case 0:
                return "PENDENTE";
            case 1:
                return "AGUARDANDOINTEGRADOR";
            case 2:
                return "PARCIALMENTECONFIRMADO";
            case 3:
                return "RESERVADO";
            case 4:
                return "CONFIRMADO";
            case 7:
                return "PROBLEMATECNICO";
            case 8:
                return "CANCELADA";
        }
    }

    selecionaConsultor(consultor) {
        if (
            this.Orcamento.Consultor != null &&
            consultor.Id === this.Orcamento.Consultor.Id
        ) {
            // return true;
        }

        this.reservaService
            .alterarConsultor(this.Orcamento.Id, consultor.Id)
            .subscribe(
                (dados) => {
                    this.Consultor = consultor;
                    this.Orcamento.Consultor = consultor;
                    this.mensagemSucessoReserva = "Consultor alterado com sucesso";
                },
                (erro) => {
                    console.log(erro);
                    this.mensagensErroReserva = this.errorHandler(erro);
                    alert("Erro ao selecionar consultor");
                }
            );
    }

    selecionaAgente(agente) {
        if (
            this.Orcamento.Agente != null &&
            agente.Id === this.Orcamento.Agente.Id
        ) {
            // return true;
        }

        this.reservaService
            .alterarAgente(this.Orcamento.Id, agente.Id)
            .subscribe(
                (dados) => {
                    this.Agente = agente;
                    this.Orcamento.Agente = agente;
                    this.mensagemSucessoReserva = "Agente alterado com sucesso";
                },
                (erro) => {
                    console.log(erro);
                    this.mensagensErroReserva = this.errorHandler(erro);
                    alert("Erro ao selecionar agente");
                }
            );
    }

    abrirLinkPagamento(pagamento) {
        this.reservaService.gerarLinkPagamento(pagamento.Id).subscribe(
            dados => {
                window.open(dados);
                console.log(dados);
            },
            error => {
                console.log(error);
            });
    }

    alternaAuxAereoIda() {
        this.auxAereoIda = !this.auxAereoIda;
    }

    alternaAuxAereoVolta() {
        this.auxAereoVolta = !this.auxAereoVolta;
    }

    somaServico(x, i: any) {
        if (x == 0) {
            this.totalServico = i;
        } else {
            this.totalServico = +this.totalServico + i;
        }
    }

    atualizarPagamentos(pagamentos) {
        this.carregarObjeto(this.Orcamento.Id);
    }

    adicionarItem() {
        if (this.busca) {
            const id = this.route.snapshot.params.id;
            this.busca.ReservaId = id;
            sessionStorage.setItem("busca", JSON.stringify(this.busca));
        }

        document.getElementById("modalPesquisa").click();
    }

    setDataNascimento(pessoa) {
        console.log(pessoa);
        const novaIdade = this.getIdade(pessoa.Nascimento);
        if (pessoa.Tipo == 0) {
            pessoa.Idade = novaIdade;
        }
    }

    getIdade(nascimento): number {
        const datanasc = moment(nascimento, "DD/MM/YYYY");
        const timeDiff = Math.abs(Date.now() - datanasc.valueOf());
        const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        return age;
    }

    imprimirVoucher(tipo, item) {
        if (!item.VoucherKey) {
            console.log(item)
            console.log(tipo)
            let endereco = `${window.location.origin}/voucher/`;
            console.log(endereco)
            this.voucherService.gerarVoucher(tipo, item, endereco)
                .subscribe(
                    dados => {

                        item.VoucherKey = dados;
                        this.exibeVoucher(item.VoucherKey);
                        console.log(item)
                    },
                    error => {
                        switch (item) {
                            case 'aereo':
                                this.mensagensErroAereo = this.errorHandler(error);
                                break;
                            case 'hotel':
                                this.mensagensErroHotel = this.errorHandler(error);
                                break;
                            case 'transfer':
                                this.mensagensErroTransfer = this.errorHandler(error);
                                break;
                            case 'ticket':
                                this.mensagensErroTicket = this.errorHandler(error);
                                break;
                            case 'pacote':
                                this.mensagensErroPacote = this.errorHandler(error);
                                break;
                            case 'servico':
                                this.mensagensErroServico = this.errorHandler(error);
                                break;
                            case 'seguro':
                                this.mensagensErroSeguro = this.errorHandler(error);
                                break;
                            default:
                                this.errorHandler(error);
                                break;

                        }
                    });

        } else
            return this.exibeVoucher(item.VoucherKey);

    }

    exibeVoucher(VoucherKey) {
        window.open(`/voucher/${VoucherKey}`, "_blank");
    }

    voucherSeguroValor: Seguro;
    voucherSeguro(seguro: Seguro) {
        this.voucherSeguroValor = seguro;
        const strVouchers = seguro.Vouchers;
        const arrVouchers = strVouchers.split(' ');
        const pais = 550;
        for (const codigo of arrVouchers) {
            const v = new Voucher();
            v.codigo = parseInt(codigo, 10);
            v.validacao = (parseInt(codigo, 10) + pais) * 7;
            this.vouchers.push(v);
        }
        console.log(this.vouchers);
    }

    consultarPagamento(pagamento) {
        this.mensagemSucessoReserva = '';
        //this.carregando = true;
        this.reservaService.consultarPagamento(pagamento.Id).subscribe(
            dados => {

                // const i = this.Orcamento.Pagamentos.map(x => x.Id).indexOf(dados.Id);
                // this.Orcamento.Pagamentos[i] = dados;
                this.atualizarOrcamento()

                switch (dados.Status) {
                    case 4:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: APROVADO';
                        break;
                    case 2:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: CONFIRMADO';
                        break;
                    case 3:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: RECUSADO';
                        break;
                    case 5:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: CANCELADO';
                        break;
                    case 6:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: ESTORNADO';
                        break;
                    case 0:
                        this.mensagemSucessoReserva = 'Status Atual do Pagamento: PENDENTE';
                        break;
                }

            }, erro => {
                this.mensagensErroReserva = this.errorHandler(erro);
            })
    }

    orcamentoOpcoesCompleto() {
        let endereco = `${window.location.origin}/orcamentodownload/false/${this.Orcamento.Id}`;
        // window.open(this.reservaService.urlOrcamento(), "_blank");
        window.open(endereco, "_blank");
    }

    orcamentoOpcoesSimplificado() {
        let endereco = `${window.location.origin}/orcamentodownload/true/${this.Orcamento.Id}`;
        // window.open(this.reservaService.urlOrcamento(), "_blank");
        window.open(endereco, "_blank");
    }

    reservarPacote(PacoteReservaId) {
        const resposta = window.confirm(
            `Tem certeza que deseja reservar o pacote ${PacoteReservaId} ?`
        );

        if (resposta && this.paxValido()) {
            this.spinnerPacote = true;
            this.pacotelstService.reservar(PacoteReservaId).subscribe(
                (dados) => {
                    // this.mensagemSucesso = `Pacote ${dados.Id} reservado com sucesso com sucesso!`;
                    this.spinnerPacote = false;
                    this.atualizarOrcamento();
                },
                (erro) => {
                    this.spinnerPacote = false;
                    this.mensagensErroReserva = this.errorHandler(erro);
                }
            );
        } else if (!this.paxValido()) {
            alert("Verifique os dados dos pax !");
        }
    }

    confirmarAlterarPagamento(pagamento, aprovar = false) {

        let resposta = false;

        if (aprovar) {
            resposta = window.confirm(`Tem certeza que deseja aprovar este pagamento: ${pagamento.Id} ?`);
        } else {
            resposta = window.confirm(`Tem certeza que deseja alterar este pagamento: ${pagamento.Id} ?`);
        }


        if (resposta) {
            this.AlterarPagamento(pagamento);
        }
    }
    AlterarPagamento(pgto) {
        let pgtoClone = cloneDeep(pgto);

        pgtoClone.Status = 4;

        this.reservaService.alterarReservaPagamento(pgtoClone).subscribe(
            dados => {
                alert("Pagamento aprovado com sucesso !")
                this.atualizarOrcamento();
            },
            erro => {
                alert("Ocorreu um erro ao aprovar o Pagamento !");
                this.mensagensErroReserva = erro;
            }
        )
    }
    cancelarPacote(PacoteReservaId) {
        const resposta = window.confirm(
            `Tem certeza que deseja cancelar o pacote ${PacoteReservaId} ?`
        );

        if (resposta && this.paxValido()) {
            this.spinnerPacote = true;
            this.pacotelstService.cancelar(PacoteReservaId).subscribe(
                (dados) => {
                    // this.mensagemSucesso = `Pacote ${dados.Id} reservado com sucesso com sucesso!`;
                    this.spinnerPacote = false;
                    this.atualizarOrcamento();
                },
                (erro) => {

                }
            );
        } else if (!this.paxValido()) {
            alert("Verifique os dados dos pax !");
        }
    }

    // Orcamento Section

    statusContrato(pagamentoId: number) {
        return this.Orcamento.Contratos.filter(x => x.ReservaPagamentoId == pagamentoId).map(x => x.Status);
    }

    confirmarNovoLink(id) {
        this.mensagensErroReserva = null;
        this.mensagemSucessoReserva = '';
        this.confirmacaoService.confirm('Acessar Contrato e gerar nova assinatura', 'Descartar Assinatura atual e gerar uma nova?', 'Confirmar', 'Voltar')
            .then((confirmed) => {
                if (confirmed) {
                    this.GeraChaveLink(id);
                }
            })
            .catch(() => console.log(''));
    }

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    acessarBoleto(pgto) {
        this.mensagensErroReserva = null;
        const confirmar = window.confirm('ATENÇÃO: reconfirme se o valor e vencimento dos boletos foram solicitados corretamente antes de emiti-los, pois após o boleto gerado será cobrado uma taxa de cancelamento de R$ 22.00 por boleto.');
        if (pgto.Boletos && pgto.Boletos.length == 1) {
            if (confirmar && this.validURL(pgto.Boletos[0].URL)) {
                window.open(pgto.Boletos[0].URL);
            }
        }
        else if (pgto.TipoPagamento == 4 && this.validURL(pgto.Financiamentos[0].Link)) {
            if (confirmar) {
                window.open(pgto.Financiamentos[0].Link);
            }
        }
        else {
            this.mensagensErroReserva.push('Boleto indisponível, entre em contato com seu consultor');
        }
    }

    GeraChaveLink(pagamentoId: number) {
        this.reservaService.geradorChaveLink(pagamentoId).subscribe(
            dados => {
                // console.log(dados);
                window.open(dados);
            },
            error => {
                console.log(error);
                this.mensagensErroReserva = this.errorHandler(error);
                alert(error.error.mensagem)
            }
        )
    }

    contratoAssinado(pagamentoId: number) {
        return this.Orcamento.Contratos
            .filter(x => x.ReservaPagamentoId == pagamentoId)
            .map(x => x.Assinaturas).flat()
            .filter(y => y.TipoPessoaAssinatrua == 0 && (y.AssinaturaDigitalizada != null && y.AssinaturaDigitalizada.length > 0)).length > 0;
    }

    GeraChaveLinkPDF(pagamentoId: number) {
        this.reservaService.geradorChaveLinkPDF(pagamentoId).subscribe(
            dados => {
                window.open(dados);
            },
            error => {
                this.mensagensErroReserva = this.errorHandler(error);
                alert(error.error.mensagem);
            }
        )
    }

    public confirmarGeraChave(id) {
        this.mensagensErroReserva = null;
        this.mensagemSucessoReserva = '';
        this.confirmacaoService.confirm('Enviar contrato por e-mail', 'Descartar assinatura atual caso tenha e gerar uma nova?', 'Confirmar', 'Voltar')
            .then((confirmed) => {
                if (confirmed) {
                    this.GeraChave(id);
                }
            })
    }

    GeraChave(pagamentoId: number) {
        this.reservaService.geradorChave(pagamentoId).subscribe(
            dados => {
                alert('Chave enviada por email!');
            },
            error => {
                console.log(error);
                this.mensagensErroReserva = this.errorHandler(error);
                alert(error.error.mensagem);
            }
        )
    }
}
