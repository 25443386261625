import { Cidade } from '../endereco.model';
export class Seguro {
    public Id: number;
    public Partida: any;
    public Retorno: any;
    public DestinoCodigo: number;
    public DestinoNome: string;
    public Adt: number;
    public Chd: number;
    public Snr: number;
    public Fornecedor: string;
    public Status: number;
    public Codigo: string;
    public TarifaCodigo: string;
    public Nome: string;
    public Modalidade: string;
    public LocalizadorIntegrador: string;
    public Vouchers: string;
    public CodigoInterno: string;
    public Moeda: string;
    public Cidade: Cidade;
    public Taxa: number;
    public ValorVenda: number;
    public ValorIRRF = 0;
    public ListaDetalhes: any;
}

export class BuscaSeguroRQ {
    public Partida: any;
    public Retorno: any;
    public DestinoCodigo: string;
    public Adt: number;
    public Chd: number;
    public Snr: number;
    public Guid: string;
    public AgenciaId: number;
    public Rebusca: boolean;
}

export class Voucher {
    public codigo: number;
    public validacao: number;
}