import { __spreadArrays, __read } from 'tslib';
import { ITENSORCAMENTO } from '../app.config';

export interface Alteracoes {
    CriadoPor: string;
    AlteradoPor?: any;
    CriadoEm: Date;
    AlteradoEm?: any;
}

export class Paginacao {
  public TotalPaginas: any = 1;
  public TotalItens: any = 0;
  public NumeroPagina: any = 1;
  public ItensPorPagina: any = ITENSORCAMENTO;

  public MaximoPaginas: any = 10;


  public hasNext(): boolean {
    return this.TotalPaginas > this.NumeroPagina;
  }

  public next(): boolean {
    return (this.NumeroPagina + 1);
  }

  public hasPrevious(): boolean {
    return this.NumeroPagina > 1;

  }

  public previous(): boolean {
    return (this.NumeroPagina + (-1));
  }

  public getPaginacao(): number[] {
    let retorno = [];

    if (this.TotalPaginas === 0) {
      retorno.push(1);
      return retorno;
    }

    if (this.TotalPaginas < this.MaximoPaginas) {
      for (let i = 1; i <= this.TotalPaginas; i++ ) {
        retorno.push(i);
      }
    } else {
      const multplicador =  Math.abs(this.MaximoPaginas / 2);

      let inicial = this.NumeroPagina - (multplicador - 1);
      let final = this.NumeroPagina + multplicador;

      if (inicial < 1) {
        const x: any = 1 - inicial;
        inicial = 1;
        final = final + x;
      }
      if (final > this.TotalPaginas) {
        const x: any = final - this.TotalPaginas;
        final = this.TotalPaginas;
        inicial = (inicial - x);
      }


      for (let i = inicial; i <= final; i++) {
        retorno.push(i);
      }

      if (inicial > 1) {
        // retorno = [1 , ...retorno];

        retorno = __spreadArrays([1], __read(retorno));
      }

      if (final < this.TotalPaginas) {
        // retorno = [...retorno, this.TotalPaginas];

        retorno = __spreadArrays(__read(retorno), [this.TotalPaginas]);
      }

    }

    return retorno;
  }
}

export class LogAlteracoes {
  Id: number;

}

export class LogRQ {
  entidade: string;
  chave: number;
  login: string;
  usuarioid: number;
  acao: string;
  datainicio: string;
  datafim: string;

}
