import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService,UsuarioService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'MasterOp';
  logged = false;
  usuario: any;
  carregando = false;
  errors: string[];
  public user: any = { email: '', password: '' };
  active: boolean
  escondeHtml = false;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() {
    this.isLoggedIn();

    const user = this.loginService.user();
    if (user !== null) {
      this.usuario = user;
    }

    if (window.location.href.split('/').indexOf("voucher") != -1 || window.location.href.split('/').indexOf("orcamentodownload") != -1) {
      this.escondeHtml = true;
    }

    let permi = window.localStorage.getItem("permission");

    if (!permi) {
      this.usuarioService.buscarPermissoes();
    }
  }

  ngDoCheck() {
    //verifica se os dados do usuário estão setados no cache, pois a maioria das vezes o usuario não é setado dentro do
    //fluxo normal de login, por um motivo que nunca consegui descobrir. Então enquanto não setar, ele executará esta função.
    if (this.usuario && (!this.usuario.Agencia && !this.usuario.Empresa)) {
      this.loginService.buscarDadosUsuario(this.usuario);
      this.usuario = JSON.parse(window.localStorage.getItem('loggedUser'));
      this.carregando = false;
    }
  }

  login() {
    this.carregando = true;
    this.loginService.logar(this.user.email, this.user.password).subscribe(
      dados => {
        this.isLoggedIn();
        this.usuario = this.loginService.user();
        this.usuarioService.buscarPermissoes();
        this.carregando = false;
        this.router.navigate(['']);
      }, erro => {
        console.log('Erro de login');
        this.carregando = false;
        alert(erro.error.error_description)
      });
  }

  isLoggedIn() {
    this.logged = this.loginService.isLoggedIn();
      if (!this.logged) {
        this.usuario = null;
      }
      return this.logged;
  }

}
