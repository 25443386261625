import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { Busca, Paginacao } from 'src/app/model';
import { Seguro } from 'src/app/model/turismo/seguro.model';
import { SeguroService } from 'src/app/services';

@Component({
  selector: 'app-lista-seguro',
  templateUrl: './lista-seguro.component.html',
  styleUrls: ['./lista-seguro.component.scss']
})
export class ListaSeguroComponent implements OnInit {

  @Output() selecionou = new EventEmitter<any>();
  @Output() avancar = new EventEmitter<any>();
  @Output() voltar = new EventEmitter<any>();
  @Output() removeu = new EventEmitter<any>();
  @Input() busca: Busca;
  @Input() events: Observable<void>;
  @Input() Orcamento: Seguro[] = [];
  private eventsSubscription: Subscription;
  seguros: Seguro[] = [];
  paginacao: Paginacao = new Paginacao();
  guid = '';
  buscasAtivas = [];
  buscando = false;
  rebusca = false;
  indiceAtual = 0;
  mensagemErro = '';
  contador = 0;
  carregando = true;
  pulse = true;

  condicaoVoltar: boolean;

  itemBusca: any = null;
  // MODAL DE DETALHES DO SEGURO
  @ViewChild('contentDetalhes') contentDetalhes;
  private contentDetalhesref;
  modaldetalhesaberta = false;

  constructor(
    private seguroService: SeguroService,
  ) { }

  ngOnInit() {
    this.busca.trechos = this.busca.trechos.map(x => {
      x.dataIda = moment(x.dataIda);
      x.minDataIda = moment(x.minDataIda);

      if (this.busca.tipoBuscaAereo === 'ida-volta') {
        x.dataVolta = moment(x.dataVolta);
        x.minDataVolta = moment(x.minDataVolta);
      } else {
        x.dataVolta = moment(x.dataIda.format('YYYY-MM-DD')).add(1, 'day');
        x.minDataVolta = moment(x.dataIda.format('YYYY-MM-DD')).add(1, 'day');
      }
      return x;
    });

    const tbusca = this.busca.tipoBusca;
    ((tbusca.aereo || tbusca.hotel || tbusca.ticket || tbusca.carro)
      ? this.condicaoVoltar = true 
      : this.condicaoVoltar = false)

    this.buscar();
    this.eventsSubscription = this.events.subscribe((busca) => this.refazerBusca(busca));
  }

  ngDoCheck() {
  }
  
  rotaAvancar() {
    this.avancar.emit('x');
  }

  rotaAnterior() {
    this.voltar.emit('x');
  }

  refazerBusca(busca) {
    this.busca = busca;
    window.scroll(0, 0);
    this.paginacao.NumeroPagina = 1;
    this.buscando = true;
    this.carregando = true;
    this.buscar();
  }

  buscar(buscaSeguro = null) {
    if (buscaSeguro == null) {
      buscaSeguro = this.busca.getBuscaSeguro();
    }
    buscaSeguro.Rebusca = this.rebusca;
    if (!this.buscando) {
      this.seguros = [];
    }
    this.buscando = true;
    this.seguroService.buscarSeguro(buscaSeguro, this.paginacao.NumeroPagina)
      .subscribe(
        dados => {
          this.buscasAtivas = JSON.parse(dados.MetaData.BuscasAtivas);
          this.busca.guid = dados.MetaData.Guid;
          buscaSeguro.Guid = dados.MetaData.Guid;
          this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
          this.paginacao.TotalItens = dados.MetaData.TotalItens;
          this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
          this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;

          window.sessionStorage.setItem('busca', JSON.stringify(this.busca));
          this.seguros = dados.Items.map(x => {
            x.Id = x.Id * -1;
            x.selecionado = this.seguroSelecionado(x);
            return x;
          });
          this.contador++;

          if (this.buscasAtivas.length > 0 && (window.location.href.split('/').indexOf('lista-seguro') != -1 || window.location.href.split('/').indexOf('adicionar') != -1)) {
            setTimeout(() => {
              this.buscar(buscaSeguro);
            }, 3000);
          } else {
            this.buscando = false;
          }
        },
        erro => {
          console.log(erro);
          this.buscando = false;
          this.mensagemErro = erro.error.mensagem;
        }
      );
  }

  buscarPagina(pagina) {
    window.scroll(0, 0);
    this.paginacao.NumeroPagina = pagina;
    this.buscar();
  }

  selecionarSeguro(seguro) {
    seguro.selecionado = true;
    this.Orcamento.push(seguro);
    this.selecionou.emit(seguro);
  }

  seguroSelecionado(seguro) {
    const dado = this.Orcamento.filter(x => x.Id == seguro.Id);
    return dado.length > 0;
  }

  removerSeguro(seguro) {
    seguro.selecionado = false;
    let indice = -1;
    for (let i = 0; i < this.Orcamento.length; i++) {
      if (this.Orcamento[i].Id == seguro.Id) {
        indice = i;
      }
    }
    if (indice >= 0) {
      this.Orcamento.splice(indice, 1);
      this.removeu.emit(seguro);
    }
  }

  somaTotal(arr) {
    return arr.map(x => x.ValorVenda).reduce(function (a, b) {
      return a + b;
    }, 0);
  }

  onChange(event) {
    this.busca.primeira = false;
    this.rebusca = true;
  }

  distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  }

}
