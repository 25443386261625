
export class Carro {
    public Id: number;
    public selecionado: boolean;
    public Descricao: string;
    public Codigo: string;
    public ModeloCodigo: string;
    public ModeloNome: string;
    public Pax: number;
    public Bagagem: number;
    public TemAirCondicionado: boolean;
    public TransmissaoTipo: string;
    public Categoria: string;
    public Portas: number;
    public Imagem: string;
    public Protecoes: Protecao[] = [];
    public ValorListagem: number;
    public ValorProtecoes: number;
    public TaxaAdmProtecoes: number;
    public TaxaExtra: number;
    public CondutorNome: string;
    public CondutorSobrenome: string;
    public CondutorDocumento: string;
}

export class Protecao {
    public Tipo: string;
    public Codigo: string;
    public Titulo: string;
    public Descricao: string;
    public ValorVenda: number;
    public Obrigatorio: boolean;
    public selecionado = false;
    public desabilitado = false;
}

export class BuscaCarroRQ {
    public LocalOrigem: any;
    public LocalDevolucao: any;
    public EnderecoOrigem: any;
    public EnderecoDevolucao: any;
    public DataHoraOrigem: any;
    public DataHoraDevolucao: any;
    public Moeda: string;
    public Guid: string;
    public Rebusca: boolean;
    public AgenciaId: number;
}

export class FiltroCarro {
}

export class LojasCarros {
    Id: number;
    Sigla: string;
    Nome: string;
    Endereco: string;
    display_field: string;
}