import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { ProdutoOfflineFornecedor } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class ProdutoOfflineFornecedorService {

  constructor(private http: HttpClient) { }


  buscarPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${GERENCIAMENTO}/api/OfflineProdutoFornecedor/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${GERENCIAMENTO}/api/OfflineProdutoFornecedor?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
  }

  buscarPorParametroOnasys(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${GERENCIAMENTO}/api/OfflineProdutoFornecedor/Lista/Onasys/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/OfflineProdutoFornecedor/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/OfflineProdutoFornecedor/${id}`);
  }

  salvar(produtoOfflineFornecedor: ProdutoOfflineFornecedor): Observable<any> {
    if (produtoOfflineFornecedor.Id > 0) {
      return this.http.put<any>(`${GERENCIAMENTO}/api/OfflineProdutoFornecedor/${produtoOfflineFornecedor.Id}`, produtoOfflineFornecedor);
    } else {
      return this.http.post<any>(`${GERENCIAMENTO}/api/OfflineProdutoFornecedor/`, produtoOfflineFornecedor);
    }

  }

}
