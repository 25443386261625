import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-financiamentos',
  templateUrl: './financiamentos.component.html',
  styleUrls: ['./financiamentos.component.scss']
})
export class FinanciamentosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
