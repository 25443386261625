import { BloqueioAereo } from "../../model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";

import { GERENCIAMENTO, ITENSPORPAGINA } from "../../app.config";

@Injectable()
export class BloqueioAereoService {
  constructor(private http: HttpClient) {}

  buscarPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set("noloader", "t");
    if (param.length > 0) {
      return this.http.get<any>(
        `${GERENCIAMENTO}/api/bloqueioaereo/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`,
        { headers: headers }
      );
    } else {
      return this.http.get<any>(
        `${GERENCIAMENTO}/api/bloqueioaereo?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`,
        { headers: headers }
      );
    }
  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(
      `${GERENCIAMENTO}/api/bloqueioaereo/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`
    );
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/bloqueioaereo/${id}`);
  }

  salvar(bloqueioAereo: BloqueioAereo): Observable<any> {
    if (bloqueioAereo.Id > 0) {
      return this.http.put<any>(
        `${GERENCIAMENTO}/api/bloqueioaereo/${bloqueioAereo.Id}`,
        bloqueioAereo
      );
    } else {
      return this.http.post<any>(
        `${GERENCIAMENTO}/api/bloqueioaereo/`,
        bloqueioAereo
      );
    }
  }

  vinculaAgencia (id: number, agenciaId: number): Observable<any> {
  return this.http.post<any>( `${GERENCIAMENTO}/api/bloqueioaereo-agencia/vincular/${id}/${agenciaId}`, {});
  }

  desvincularAgencia (id: number, agenciaId: number): Observable<any> {
    return this.http.delete<any>( `${GERENCIAMENTO}/api/bloqueioaereo-agencia/desvincular/${id}/${agenciaId}`, {});
  }
}
