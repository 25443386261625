import { TIPOBUSCAAEREO } from "./../../model/turismo/busca.model";
import { EventEmitter, Input } from '@angular/core';
import { AfterViewInit, Component, OnInit, Output } from '@angular/core';
import { CidadeService, AeroportoService, LoginService, CarroService, PaisService, ReservaService } from 'src/app/services';
import { Router, ActivatedRoute } from '@angular/router';
import { Busca, Aeroporto, Quarto, Trecho, Cidade, Orcamento, Aereo } from 'src/app/model';
import * as moment from 'moment';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
// tslint:disable: variable-name
// tslint:disable: max-line-length

export const BUSCA = "busca";
export const ORCAMENTO = "orcamentousuario";

@Component({
    selector: 'app-busca',
    templateUrl: './busca.component.html',
    styleUrls: ['./busca.component.scss']
})
export class BuscaComponent implements OnInit, AfterViewInit {

    constructor(
        public _router: Router,
        public route: ActivatedRoute,
        public cidadeService: CidadeService,
        public aeroportoService: AeroportoService,
        public loginService: LoginService,
        public carroService: CarroService,
        public paisService: PaisService,
        private reservaService: ReservaService,
        private modalService: NgbModal
    ) { }



    selecaoProdutos = [
        { title: "Aéreo", icon: "fas fa-plane", tracker: "aereo", active: true },
        { title: "Hotel", icon: "fas fa-hotel", tracker: "hotel", active: true },
        {
            title: "Serviço",
            icon: "fas fa-ticket-alt",
            tracker: "ticket",
            active: true,
        },
        { title: "Carro", icon: "fas fa-car-alt", tracker: "carro", active: false },
        { title: "Seguro", icon: "fas fa-shield-alt", tracker: "seguro", active: false },
    ];

    aereoOpcoes = [
        { title: "Ida e Volta", tracker: "ida-volta" },
        { title: "Somente Ida", tracker: "ida" },
        { title: "Múltiplos Trechos", tracker: "multiplos-trechos" },
    ];

    checkboxes = [
        { title: "Bagagem", type: false },
        { title: "Voo Direto", type: false },
    ];

    @Output() fechar = new EventEmitter<any>()
    @Input() orcamentoId;
    paises = [];
    public quarto = new Quarto();
    busca: Busca = new Busca();
    temagencia: any;
    buscaValidator: any;
    tipoBuscaAereo: string;
    qntTrechos = 0;
    cidades = [];
    rebusca = false;
    adicionar = false;
    bloqueado = false;
    id = null;
    usuario = null;
    timerCidade = null;
    personalizado = false;
    erros: String;
    opened: boolean;
    lojas = [];
    Orcamento: any;
    LocalizadorAereo = "";
    Fornecedor = "GWS";
    semAgencia = false;
    ngOnInit() {
        const busca = JSON.parse(sessionStorage.getItem('busca'));
        var a = 0;
        if (busca != null || undefined) {
            this.selecaoProdutos[a].active = busca.tipoBusca.aereo;
            this.selecaoProdutos[a + 1].active = busca.tipoBusca.hotel;
            this.selecaoProdutos[a + 2].active = busca.tipoBusca.ticket;
            this.selecaoProdutos[a + 3].active = busca.tipoBusca.carro;
            this.selecaoProdutos[a + 4].active = busca.tipoBusca.seguro;
            this.busca.nrnoites = busca.nrnoites;
            this.busca.tipoBuscaAereo = TIPOBUSCAAEREO.idaVolta;
        }

        this.id = this.route.snapshot.params.id;
        if (this.id !== undefined) {
            this.adicionar = true;
        }
        this.busca.trechos = this.busca.trechos.map(x => {
            x.dataIda = moment(x.dataIda).format('YYYY-MM-DD');
            x.dataVolta = moment(x.dataVolta).format('YYYY-MM-DD');
            x.minDataIda = moment(x.minDataIda).format('YYYY-MM-DD');
            x.minDataVolta = moment(x.minDataVolta).format('YYYY-MM-DD');
            return x;
        });
        this.usuario = this.loginService.user();

        this.aereoConsolidacao();
    }

    ngAfterViewInit() {
        const busca = window.sessionStorage.getItem('busca');
        if (busca !== null) {
            this.busca = this.busca.decodeBusca(busca);

            this.busca.trechos = this.busca.trechos.map(x => {
                x.dataIda = moment(x.dataIda).format('YYYY-MM-DD');
                x.dataVolta = moment(x.dataVolta).format('YYYY-MM-DD');
                x.minDataIda = moment(x.minDataIda).format('YYYY-MM-DD');
                x.minDataVolta = moment(x.minDataVolta).format('YYYY-MM-DD');
                return x;
            });
        }
        let paiscache = window.sessionStorage.getItem("zfjd87");
        if (paiscache != null) {
            this.setarPaisAgencia();
        } else {
            setTimeout(() => this.setarPaisAgencia(), 2100);
        }

        this.busca.destinoCodigo = '';

    }

    close() {
        this.fechar.emit('x')
    }

    importaLocalizadorAereo() {
        let orcamento = new Orcamento();
        orcamento.Aereos = [];
        let OrcamentoAvulso: any = [];
        let aereo = new Aereo();
        aereo.LocalizadorAereo = this.LocalizadorAereo;
        aereo.Fornecedor = this.Fornecedor;
        console.log(aereo);
        orcamento.Aereos.push(aereo);
        console.log(orcamento);
        OrcamentoAvulso = orcamento;
        console.log(OrcamentoAvulso);
        this.busca.AgenciaId = null;
        if (!this.adicionar) {
            if (this.usuario.AgenciaId != null) {
                this.busca.AgenciaId = this.usuario.AgenciaId;
            } else {
                let agencia = window.localStorage.getItem("g8h7d8");
                if (agencia != null) {
                    let a = JSON.parse(atob(agencia));
                    this.busca.AgenciaId = a.Id;
                }
            }
            if (!this.busca.AgenciaId || this.busca.AgenciaId == 0) {
                this.semAgencia = true;
                setTimeout(() => (this.semAgencia = false), 5000);
                return null;
            }
        }

        OrcamentoAvulso.AgenciaId = this.busca.AgenciaId;

        this.reservaService.salvarOrcamento(OrcamentoAvulso).subscribe(
            (dados) => {
                window.sessionStorage.removeItem("orcamentousuario");
                //this._router.navigate([`/turismo/orcamento/${dados.Id}`]);
            },
            (erros) => {
                //this.erros = this.errorHandler(erros);
            }
        );
    }

    aereoConsolidacao() {
        if (this.id === undefined && this.busca.tipoBusca.aereo && (!this.busca.tipoBusca.hotel)) {
            this.busca.AereoConsolidacao = true;

        } else {
            this.busca.AereoConsolidacao = false;
        }
    }

    selecionaTab(tipo: string) {
        switch (tipo) {
            case 'aereo':
                this.busca.tipoBusca.aereo = !this.busca.tipoBusca.aereo;
                break;
            case 'hotel':
                this.busca.tipoBusca.hotel = !this.busca.tipoBusca.hotel;
                break;
        }

        this.aereoConsolidacao();
    }

    selecionaPaisNacionalidade(item) {
        this.busca.nacionalidadePax = item;
        this.busca.quartos = this.busca.quartos.map((x) => {
            x.nacionalidadePaxQuarto = item.Iata;
            return x;
        });

        let paiscache = window.localStorage.getItem("zfjd87");

        if (paiscache != null) {
            window.localStorage.removeItem("zfjd87");
            window.localStorage.setItem("zfjd87", btoa(JSON.stringify(item)));
        } else {
            window.localStorage.setItem("zfjd87", btoa(JSON.stringify(item)));
        }
    }
    timerPais = null;

    buscaPaisDebounce(val: string) {
        clearTimeout(this.timerPais);
        this.timerPais = setTimeout(() => {
            this.buscaPais(val);
        }, 600);
    }
    buscar() {
        this.semAgencia = false;
        this.busca.guid = null;
        this.busca.primeira = true;
        this.busca.novoOrcamento = true;
        this.busca.ReservaId = this.id;
        this.busca.orcamentoId = null;
        this.busca.usuario = this.usuario;

        if (this.busca.tipoBusca.aereo || this.busca.tipoBusca.hotel) {
            this.busca.destinoCodigo = '';
        }

        if (this.usuario.AgenciaId) {
            this.busca.AgenciaId = this.usuario.AgenciaId;

        } else {
            let agencia = localStorage.getItem('g8h7d8');

            if (agencia != null) {
                const agenciaID = JSON.parse(atob(agencia));
                this.busca.AgenciaId = agenciaID.Id;
            }
        }
        this.setarPaisAgencia();

        window.sessionStorage.removeItem('busca');
        window.sessionStorage.setItem('busca', JSON.stringify(this.busca));
        window.sessionStorage.removeItem('orcamentousuario');

        if (this.orcamentoId > 0) {
            this.busca.ReservaId = this.orcamentoId;
            this.adicionar = true;
            console.log(this.busca)
        }

        if (this.busca.ReservaId > 0) {
            if (this.busca.tipoBusca.aereo) {
                this._router.navigate([`adicionar/${this.busca.ReservaId}/lista-aereo`]);
            } else if (this.busca.tipoBusca.hotel) {
                this._router.navigate([`adicionar/${this.busca.ReservaId}/lista-hotel`]);
            } else if (this.busca.tipoBusca.ticket) {
                this._router.navigate([`adicionar/${this.busca.ReservaId}/lista-servico`]);
            } else if (this.busca.tipoBusca.carro) {
                this._router.navigate([`adicionar/${this.busca.ReservaId}/lista-carro`]);
            } else if (this.busca.tipoBusca.seguro) {
                this._router.navigate([`adicionar/${this.busca.ReservaId}/lista-seguro`]);
            }

        } else {

            if (this.busca.tipoBusca.aereo) {
                // this._router.navigate([`listas/lista-aereo`]);
                this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this._router.navigate([`listas/lista-aereo`]));
            } else if (this.busca.tipoBusca.hotel) {
                this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this._router.navigate([`listas/lista-hotel`]));
            } else if (this.busca.tipoBusca.ticket) {
                this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this._router.navigate([`listas/lista-servico`]));
            } else if (this.busca.tipoBusca.carro) {
                this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this._router.navigate([`listas/lista-carro`]));
            } else if (this.busca.tipoBusca.seguro) {
                this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this._router.navigate([`listas/lista-seguro`]));
            }
        }
    }

    criaOrcamentoAvulso() {
        let orcamento = new Orcamento();

        let OrcamentoAvulso: any = [];

        OrcamentoAvulso = orcamento;

        this.busca.AgenciaId = null;

        OrcamentoAvulso.quartos = this.busca.quartos;

        if (!this.adicionar) {
            if (this.usuario.AgenciaId != null) {
                this.busca.AgenciaId = this.usuario.AgenciaId;
            } else {
                let agencia = window.localStorage.getItem("g8h7d8");
                if (agencia != null) {
                    let a = JSON.parse(atob(agencia));
                    this.busca.AgenciaId = a.Id;
                }
            }
            if (!this.busca.AgenciaId || this.busca.AgenciaId == 0) {
                this.semAgencia = true;
                setTimeout(() => (this.semAgencia = false), 5000);
                alert("Selecione uma agência.");
                return null;
            }
        }

        OrcamentoAvulso.AgenciaId = this.busca.AgenciaId;

        this.reservaService.salvarOrcamento(OrcamentoAvulso).subscribe(
            (dados) => {
                window.sessionStorage.removeItem("orcamentousuario");
                this._router.navigate([`/orcamento/${dados.Id}`]);
                setTimeout(() => (window.location.reload()), 500);

            },
            (erros) => {
                //this.erros = this.errorHandler(erros);
            }
        );
    }

    setarPaisAgencia() {
        let user = window.localStorage.getItem("loggedUser");
        if (user != null) {
            let u = JSON.parse(user);

            let paiscache = window.localStorage.getItem("zfjd87");

            if (paiscache != null) {
                let pais = JSON.parse(atob(paiscache));

                this.busca.quartos = this.busca.quartos.map((x) => {
                    x.nacionalidadePaxQuarto = pais.Iata;
                    return x;
                });
                this.busca.nacionalidadePax = pais;
                this.busca.nacionalidadePax.Nome = pais.Nome;
            } else {
                if (u.Agencia != null && u.Agencia != undefined) {
                    this.cidadeService.buscarPorId(u.Agencia.CidadeId).subscribe(
                        (dados) => {
                            this.busca.quartos = this.busca.quartos.map((x) => {
                                x.nacionalidadePaxQuarto = dados.Items[0].Estado.Pais.Iata;
                                return x;
                            });
                            this.busca.nacionalidadePax = dados.Items[0].Estado.Pais;
                            this.busca.nacionalidadePax.Nome =
                                dados.Items[0].Estado.Pais.Nome;

                            window.localStorage.setItem(
                                "zfjd87",
                                btoa(JSON.stringify(dados.Items[0].Estado.Pais))
                            );
                        },
                        (erro) => {
                            console.log(erro);
                        }
                    );
                } else if (u.Empresa != null && u.Empresa != undefined) {
                    this.cidadeService.buscarPorId(u.Empresa.CidadeId).subscribe(
                        (dados) => {
                            this.busca.quartos = this.busca.quartos.map((x) => {
                                x.nacionalidadePaxQuarto = dados.Items[0].Estado.Pais.Iata;
                                return x;
                            });
                            this.busca.nacionalidadePax = dados.Items[0].Estado.Pais;
                            this.busca.nacionalidadePax.Nome =
                                dados.Items[0].Estado.Pais.Nome;

                            window.localStorage.setItem(
                                "zfjd87",
                                btoa(JSON.stringify(dados.Items[0].Estado.Pais))
                            );
                        },
                        (erro) => {
                            console.log(erro);
                        }
                    );
                }
            }
        }
    }


    buscaPais(val: string) {
        this.paisService.buscarPorParametro(val, 1).subscribe(
            (dados) => {
                this.paises = dados.Items.map((x) => {
                    x.display_field = x.Nome;
                    return x;
                });
            },
            (erro) => {
                console.log(erro);
                this.paises = [];
            }
        );
    }
    verificaTab() {
        return (
            this.busca.tipoBusca.aereo ||
            this.busca.tipoBusca.hotel ||
            this.busca.tipoBusca.ticket
        );
    }

    buscaCidade(val: string) {
        if (this.busca.tipoBusca.aereo) {
            this.aeroportoService.buscarPorParametro(val, 1).subscribe(
                dados => {
                    '';
                    this.cidades = dados.Items.map(x => {
                        x.display_field = `${x.Iata} - ${x.Descricao} / ${x.Cidade.Nome} - ${x.Cidade.Estado.Iata} / ${x.Cidade.Estado.Pais.Iata}`;
                        return x;
                    });
                },
                erro => {
                    console.log(erro);
                    this.cidades = [];
                }
            );
        } else {
            this.cidadeService.buscarOrcamento(val, 1).subscribe(
                dados => {
                    '';
                    this.cidades = dados.Items.map(x => {
                        x.display_field = x.Nome + ' - ' + x.Estado.Nome + ' / ' + x.Estado.Pais.Nome;
                        return x;
                    });
                },
                erro => {
                    console.log(erro);
                    this.cidades = [];
                }
            );
        }
    }

    buscaCidadeDebounce(val: string) {
        clearTimeout(this.timerCidade);
        this.timerCidade = setTimeout(() => {
            this.buscaDados(val);
        }, 600);
    }

    buscaDados(val: string) {
        if (this.busca.tipoBusca.aereo) {
            this.aeroportoService.buscarPorParametro(val, 1).subscribe(
                dados => {
                    this.cidades = dados.Items.map(x => {
                        x.display_field = `${x.Iata} - ${x.Descricao} / ${x.Cidade.Nome} - ${x.Cidade.Estado.Iata} / ${x.Cidade.Estado.Pais.Iata}`;
                        return x;
                    });
                },
                erro => {
                    console.log(erro);
                    this.cidades = [];
                }
            );
        } else {
            this.cidadeService.buscarOrcamento(val, 1).subscribe(
                dados => {
                    this.cidades = dados.Items.map(x => {
                        x.display_field = x.Nome + ' - ' + x.Estado.Nome + ' / ' + x.Estado.Pais.Nome;
                        return x;
                    });
                },
                erro => {
                    console.log(erro);
                    this.cidades = [];
                }
            );
        }
    }

    selecionaCidadeOrigem(item, trecho) {
        if (this.busca.tipoBusca.aereo) {
            trecho.AeroportoPartida = item;
            trecho.cidadeOrigem = item.Cidade;
        } else {
            trecho.cidadeOrigem = item;
        }
    }

    selecionaCidadeDestino(item, trecho) {
        if (this.busca.tipoBusca.aereo) {
            trecho.AeroportoChegada = item;
            trecho.cidadeDestino = item.Cidade;
            trecho.cidadeDestino.display_field =
                trecho.cidadeDestino.Nome +
                ' - ' +
                trecho.cidadeDestino.Estado.Nome +
                ' / ' +
                trecho.cidadeDestino.Estado.Pais.Nome;
        } else {
            trecho.cidadeDestino = item;
        }
    }

    adicionarTrecho() {
        this.busca.trechos.push(new Trecho());
        this.qntTrechos++;
    }

    removerTrecho(indice) {
        this.qntTrechos--;

        if (indice === undefined) {
            this.busca.trechos.pop();
        } else {
            this.busca.trechos.splice(indice, 1);
        }
    }

    setUmTrecho() {
        while (this.busca.trechos.length > 1) {
            this.busca.trechos.pop();
        }
        this.busca.trechos[0].cidadeOrigem = new Cidade();
        this.busca.trechos[0].cidadeDestino = new Cidade();
        this.busca.trechos[0].AeroportoPartida = new Aeroporto();
        this.busca.trechos[0].AeroportoChegada = new Aeroporto();
    }

    setDataIda(event, trechos, indice) {

        trechos[indice].minDataVolta = moment(trechos[indice].dataIda).add(1, 'days').format('YYYY-MM-DD');
        if (this.busca.nrnoites !== null && this.busca.nrnoites !== 0) {
            trechos[indice].dataVolta = moment(trechos[indice].dataIda).add(
                this.busca.nrnoites,
                'days'
            ).format('YYYY-MM-DD');
        } else {
            trechos[indice].dataVolta = trechos[indice].minDataVolta;
        }

        if (indice > 0) {
            trechos[indice].minDataIda = moment(trechos[indice].dataIda).add(1, 'days').format('YYYY-MM-DD');
        }

        for (let i = indice + 1; i < trechos.length; i++) {
            if (i === 1) {
                trechos[i].minDataIda = moment(trechos[indice].dataIda).add(1, 'days').format('YYYY-MM-DD');
            }

            if (trechos[i].minDataIda > trechos[i].dataIda) {
                trechos[i].dataIda = trechos[i].minDataIda;
            }
        }
        this.GetDateDiffIda(event, trechos, indice);
    }


    onChangeNoites(event, trechos, indice) {
        trechos[indice].dataVolta = moment(trechos[indice].dataIda).add(
            this.busca.nrnoites,
            'days'
        ).format('YYYY-MM-DD');
    }

    GetDateDiffIda(event, trechos, indice) {
        if (trechos[indice].dataVolta != null) {
            const diffdays = moment(trechos[indice].dataVolta).diff(event, 'days');
            this.busca.nrnoites = diffdays;
        }
    }

    onChangeVolta(event, trechos, indice) {
        this.GetDateDiffVolta(event, trechos, indice);
    }

    GetDateDiffVolta(event, trechos, indice) {
        if (trechos[indice].dataVolta != null) {
            event = moment(event);
            const diffdays = event.diff(trechos[indice].dataIda, 'days');
            this.busca.nrnoites = diffdays;
        }
    }

    incrementaQuartos() {
        this.busca.quartos.push(new Quarto());
        this.setarPaisAgencia();
    }

    decrementaQuartos() {
        this.busca.quartos.pop();
    }


    limpaPesquisa() {
        sessionStorage.removeItem(BUSCA);
        sessionStorage.removeItem(ORCAMENTO);
    }

    zeraValores() {
        var i = 99;
        this.qntTrechos = 99;

        while (i !== 0) {
            this.removerTrecho(i);
            i--;
            this.qntTrechos--;
        }

        i = undefined;
        this.qntTrechos = 0;
    }

    changeAereo(item, a?) {
        switch (item) {
            case "aereo":
                this.busca.tipoBusca.aereo = !this.busca.tipoBusca.aereo;
                this.selecaoProdutos[a].active = !this.selecaoProdutos[a].active;
                break;

            case "hotel":
                this.busca.tipoBusca.hotel = !this.busca.tipoBusca.hotel;
                this.selecaoProdutos[a].active = !this.selecaoProdutos[a].active;
                break;

            case "ticket":
                this.busca.tipoBusca.ticket = !this.busca.tipoBusca.ticket;
                this.selecaoProdutos[a].active = !this.selecaoProdutos[a].active;
                break;

            case "carro":
                this.busca.tipoBusca.carro = !this.busca.tipoBusca.carro;
                this.selecaoProdutos[a].active = !this.selecaoProdutos[a].active;
                break;

            case "seguro":
                this.busca.tipoBusca.seguro = !this.busca.tipoBusca.seguro;
                this.selecaoProdutos[a].active = !this.selecaoProdutos[a].active;
                break;
        }
    }

    verificaAgencia() {
        let agencia = window.localStorage.getItem('g8h7d8');

        if (agencia != null) {
            agencia = JSON.parse(atob(agencia));
            this.temagencia = agencia;
        }

        if (agencia != null || agencia != undefined || this.usuario.AgenciaId != null) {
            this.buscar();
            this.close();
        } else {
            // abrirModal();
            alert("Selecione uma agência para continuar!");
        }


    }

    // abrirModal(content, item = null) {
    //     this.modalService.open(content, { size: 'lg', centered: true, backdrop: "static" })
    // }


    buscaLojas(val: string) {
        this.carroService.buscarPorParametro(val, 1).subscribe(
            (dados) => {
                this.lojas = dados.Items.map((x) => {
                    x.display_field = `${x.Sigla} - ${x.Nome} / ${x.Endereco}`;
                    return x;
                });
            },
            (erro) => {
                console.log(erro);
                this.lojas = [];
            }
        );
    }

    selecionaLojaOrigem(item) {
        this.busca.localOrigem = item;
    }

    selecionaLojaDevolucao(item) {
        this.busca.localDevolucao = item;
    }

    buscaLojasDebounce(val: string) {
        clearTimeout(this.timerCidade);
        this.timerCidade = setTimeout(() => {
            this.buscaLojas(val);
        }, 600);
    }

    setDataOrigem(event) {
        // this.busca.dataOrigem = event.startOf('day');
        this.busca.dataDevolucao = moment(this.busca.dataOrigem).add(1, "days");
    }

    setDataDevolucao(event) {
        // this.busca.dataDevolucao = event.startOf('day');
        if (moment(this.busca.dataOrigem) > moment(this.busca.dataDevolucao)) {
            this.busca.dataOrigem = moment(this.busca.dataDevolucao).subtract(
                1,
                "days"
            );
        }
    }

}
