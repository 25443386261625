import { Component, Injectable } from '@angular/core';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import * as _ from "lodash";

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
// @Injectable()
// export class CustomAdapter extends NgbDateAdapter<string> {
//
//   readonly DELIMITER = '-';
//
//   fromModel(value: string | null): NgbDateStruct | null {
//     if (value) {
//       let date = value.split(this.DELIMITER);
//       return {
//         day: parseInt(date[0], 10),
//         month: parseInt(date[1], 10),
//         year: parseInt(date[2], 10)
//       };
//     }
//     return null;
//   }
//
//   toModel(date: NgbDateStruct | null): string | null {
//     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
//   }
// }

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return "";
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { year: toInteger(dateParts[0]), month: null, day: null };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return { year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    let stringDate: string = "";
    if (date) {
      stringDate += isNumber(date.day) ? padNumber(date.day) + "/" : "";
      stringDate += isNumber(date.month) ? padNumber(date.month) + "/" : "";
      stringDate += date.year;
    }
    return stringDate;
  }
}


@Injectable()
export class MomentNgbDateAdapterService extends NgbDateAdapter<moment.Moment> {
  constructor() {
    super();
  }

  fromModel(value: moment.Moment): NgbDateStruct {
    return value && moment.isMoment(value)
      ? {
          day: value.date(),
          month: value.month() + 1,
          year: value.year()
        }
      : null;
  }

  toModel(date: NgbDateStruct): moment.Moment {
    return date &&
      _.isInteger(date.year) &&
      _.isInteger(date.month) &&
      _.isInteger(date.day)
      ? moment({
          year: date.year,
          month: date.month - 1,
          date: date.day,
          hour: 12
        })
      : null;
  }
}

export class NgbdDatepickerAdapter {

  model1: string;
  model2: string;

  constructor(private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>) { }

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
}
