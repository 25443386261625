
export class FormaPagamento {
  Id: number;
  Descricao: string;
  TipoPagamento: any;
  TipoPagamentoDesc: string;
  Condicoes = [];
  Observacoes : string;
}

export class CondicaoPagamento {
  Id: number = 0;
  Descricao: string;
  FormaPagamentoId: number;
}
