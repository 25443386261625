import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { WebService, CidadesWebServices } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class WsService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/webservice/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/webservice?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/webservice/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/webservice/${id}`);
    }

    salvar(webService: WebService): Observable<any>{
      if (webService.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/webservice/${webService.Id}`, webService);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/webservice/`, webService);
      }

    }

    vinculaCidadeIntegrador (cidadesWebService: CidadesWebServices[]): Observable<any> {
      return this.http.post<any>( `${GERENCIAMENTO}/api/webservice-integrador/vincular`, cidadesWebService);
    }

    desvincularCidadeIntegrador (cidadesWebService: CidadesWebServices[]): Observable<any> {
      return this.http.post<any>( `${GERENCIAMENTO}/api/webservice-integrador/desvincular`, cidadesWebService);
    }

    buscarCidadeWsPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/webservice/list/cidadewebservice/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/webservice/list/cidadewebservice?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

  }
