import { BloqueioAereo } from './../gerenciamento/bloqueioaereo.model';
import { Cidade } from '../endereco.model';
import { QuartoHotelRQ } from './hotel.model';

export class Pacote {
    public Id: number;
    public Partida: any;
    public Retorno: any;
    public DestinoCodigo: number;
    public DestinoNome: string;
    public Adt: number;
    public Chd: number;
    public Snr: number;
    public Fornecedor: string;
    public Status: number;
    public Codigo: string;
    public TarifaCodigo: string;
    public Nome: string;
    public Modalidade: string;
    public LocalizadorIntegrador: string;
    public Vouchers: string;
    public CodigoInterno: string;
    public Moeda: any;
    public Cidade: Cidade;
    public Taxa: number;
    public ValorVenda: number;
    public ValorIRRF = 0;
    public MinimoDeNoites: number;
    public BloqueioAereo: any;
    public ValidadeDe: any;
    public ValidadeAte: any;
    public PacotesHoteis: any;
    public PacotesInclui: any;
    public PacoteImagens: any;
}
export class PacoteReserva{
    public Id: number;
    public Checkin: any;
    public Checkout: any;
    public Adt: number;
    public Chd: number;
    public CodigoFornecedor: string;
    public CidadeId: number;
    public IdadesChd: number[] = [];
    public Nome: string;
    public Pacote: Pacote;
    public Status: number;

}
export class BuscaPacoteRQ {
    public Partida: any;
    public Retorno: any;
    public DestinoCodigo: string;
    public Adt: number;
    public Chd: number;
    public Guid: string;
    public AgenciaId: any;
    public Rebusca: boolean;
    public Quartos: QuartoHotelRQ[] = [];
}

export class FiltroPacote {

    public ValorMinimo: number;
    public ValorMaximo: number;
    public noites: number;
    public Nome: string;
    public Fornecedores: string[] = [];
    public Reembolsavel = -1;
    public Localizacao: string;
  }

export class Voucher {
    public codigo: number;
    public validacao: number;
}
