import { Cidade } from '../endereco.model';

export class Hotel {
  public Id: number;
  public selecionado: boolean;
  public Nome: string;
  public Fornecedor: string;
  public Status: number;
  public CodigoFornecedor: any;
  public ExpandirAccordion = false;
  public BloqueiaAccordion = true;
  public ExigePrePagamento: boolean;
  public Latitude: string;
  public Longitude: string;
  public Estrelas: number;
  public Cidade: Cidade;
  public Endereco: any;
  public Checkin: any;
  public Checkout: any;
  public DataReserva: any;
  public Quartos: any;
  public HotelImagem: any;
  public ValorTotalVenda: any;
  public ValorTotalListagem: any;
  public opcoes: any;
  public Detalhes : any;
  public Offline : any;
}
export class QuartoHotelRQ {

  NumeroPesquisa: number;
  Qtde: number;
  Adultos: number;
  Criancas: number[] = [];
  Senior: number;
}
export class FiltroHotel {

  public ValorMinimo: number;
  public ValorMaximo: number;
  public EstrelasMinimo = 1;
  public EstrelasMaximo = 5;
  public Nome: string;
  public Fornecedores: string[] = [];
  public Reembolsavel = -1;
  public Pensao: string[] = [];
  public Pensoes: string[] = [];
  public Localizacao: string;
}
export class BuscaHotelRQ {
  public Checkin: any;
  public Checkout: any;
  public SomentePromocao = false;
  public HotelCodigo: string;
  public Moeda: string;
  public Quartos: QuartoHotelRQ[] = [];
  public Cidade: Cidade = new Cidade();
  public Guid: string;
  public FiltroHotel: FiltroHotel = new FiltroHotel();
  public Rebusca: boolean;
  public AgenciaId: any;
  public PacoteId = 0;

}
