import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EstrelaComponent } from './components/estrela/estrela.component';
import { InputComponent } from './components/input/input.component';
import { ReservaPagamentosComponent } from './views/orcamento/reserva-pagamentos/reserva-pagamentos.component';
import { ServicoCancelaComponent } from './views/orcamento/servico-cancela/servico-cancela.component';
import { ReservaServicoComponent } from './views/orcamento/reserva-servico/reserva-servico.component';
import { HotelReservaComponent } from './views/orcamento/hotel-reserva/hotel-reserva.component';
import { AereoCancelaComponent } from './views/orcamento/aereo-cancela/aereo-cancela.component';
import { AereoEmiteComponent } from './views/orcamento/aereo-emite/aereo-emite.component';
import { AereoReservaComponent } from './views/orcamento/aereo-reserva/aereo-reserva.component';
import { RebuscaComponent } from './components/rebusca/rebusca.component';
import { CarroService } from './services/turismo/carro.service';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { BrowserModule } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LOCALE_ID, NgModule } from '@angular/core';
import { routing } from './app.routing.modules';
import { NgxPrintModule } from 'ngx-print';
import { TagInputModule } from 'ngx-chips';
import { AppComponent } from './app.component';
import {
  LoginService, AuthInterceptor, LoaderInterceptor, LoaderService, CidadeService, PaisService, AgenciaService, AeroportoService,
  ReservaService, AereoService, HotelService, ServicoService, LogService, UsuarioService, VoucherService,
  EmpresaService, PerfilMarkupService, WsService, FormaPagamentoService, CambioService, SeguroService,
  ParametroService, ProfissaoService, ScriptService, ProdutoOfflineService,
  ProdutoOfflineItemService, ProdutoOfflineFornecedorService, FinanciamentoService
} from './services';
import localePt from '@angular/common/locales/pt';
import { Agencia, Busca, BuscaAereoRQ } from './model';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './views/home/home.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { ShowsComponent } from './views/shows/shows.component';
import { HeaderComponent } from './components/header/header.component';
import { CambioComponent } from './components/cambio/cambio.component';
import { registerLocaleData } from '@angular/common';
import { SelecionarAgenciaComponent } from './components/selecionarAgencia/selecionarAgencia.component';
import { PaginacaoComponent } from './components/paginacao/paginacao.component';
import { BuscaComponent } from './components/busca/busca.component';
import { ListasComponent } from './views/listas/listas.component';
import { ListaAereoComponent } from './views/listas/lista-aereo/lista-aereo.component';
import { ListaServicoComponent } from './views/listas/lista-servico/lista-servico.component';
import { ListaCarroComponent } from './views/listas/lista-carro/lista-carro.component';
import { AereoComponent } from './views/listas/lista-aereo/aereo/aereo.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Ng5SliderModule } from 'ng5-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ListaHotelComponent } from './views/listas/lista-hotel/lista-hotel.component';
import { HotelComponent } from './views/listas/lista-hotel/hotel/hotel.component';
import { PreOrcamentoComponent } from './views/listas/pre-orcamento/pre-orcamento.component';
import { ServicoComponent } from './views/listas/lista-servico/servico/servico.component';
import { CarroComponent } from './views/listas/lista-carro/carro/carro.component';
import { ListaPacoteComponent } from './views/listas/lista-pacote/lista-pacote.component';
import { PacoteComponent } from './views/listas/lista-pacote/pacote/pacote.component';
import { ListaSeguroComponent } from './views/listas/lista-seguro/lista-seguro.component';
import { SeguroComponent } from './views/listas/lista-seguro/seguro/seguro.component';
import { PacoteViewComponent } from './views/listas/lista-pacote/pacote-view/pacote-view.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AreaTesteComponent } from './views/area-teste/area-teste.component';
import { OrcamentoComponent } from './views/orcamento/orcamento.component';
import { MeusOrcamentosComponent } from './views/meus-orcamentos/meus-orcamentos.component';
import { CarroReservaComponent } from './views/orcamento/carro-reserva/carro-reserva.component';
import { CarroCancelaComponent } from './views/orcamento/carro-cancela/carro-cancela.component';
import { SeguroReservaComponent } from './views/orcamento/seguro-reserva/seguro-reserva.component';
import { SeguroCancelaComponent } from './views/orcamento/seguro-cancela/seguro-cancela.component';
import { RecuperarSenhaComponent } from './components/recuperar-senha/recuperar-senha.component';
import { SkeletonPackageCardComponent } from './components/skeletons/skeleton-package-card/skeleton-package-card.component';
import { SkeletonHotelCardComponent } from './components/skeletons/skeleton-hotel-card/skeleton-hotel-card.component';
import { MatSidenavModule } from '@angular/material';
import { SelecionarConsultorComponent } from './views/orcamento/selecionar-consultor/selecionar-consultor.component';
import { EstornoPagamentoComponent } from './views/orcamento/estorno-pagamento/estorno-pagamento.component';
import { ComprovanteCartaoComponent } from './views/orcamento/comprovante-cartao/comprovante-cartao.component';
import { NgxMaskModule } from 'ngx-mask';
import { VoucherComponent } from './voucher/voucher.component';
import { OrcamentoDownloadComponent } from './orcamentodownload/orcamentodownload.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HotelDetalhesComponent } from './views/listas/lista-hotel/hotel-detalhes/hotel-detalhes.component';
import { ServicoDetalhesComponent } from './views/listas/lista-servico/servico-detalhes/servico-detalhes.component';
import { AlterarsenhaComponent } from './components/alterarsenha/alterarsenha.component';
import { NgxCurrencyModule } from "ngx-currency";
import { SelecionarAgenteComponent } from './views/orcamento/selecionar-agente/selecionar-agente.component';
import { ReservaTermometroComponent } from './views/orcamento/reserva-termometro/reserva-termometro.component';
import { ProdutoofflineComponent } from './views/orcamento/produtooffline/produtooffline.component';
import { TiposDeProdutosService } from './services/cadastro/tipos-de-produtos.service';
import { FinanciamentosComponent } from './components/financiamentos/financiamentos.component';
import { FinanciamentoFormComponent } from './components/financiamentos/financiamento-form/financiamento-form.component';
import { FinanciamentoBoletosComponent } from './components/financiamentos/financiamento-boletos/financiamento-boletos.component';
import { ConfirmacaoComponent } from './components/confirmacao/confirmacao.component';
import { ConfirmacaoService } from "./components/confirmacao/confirmacao.service";
registerLocaleData(localePt);
TagInputModule.withDefaults({
  tagInput: {
    placeholder: 'Adicionar',
    // add here other default values for tag-input
  },
  dropdown: {
    displayBy: 'my-display-value',
    // add here other default values for tag-input-dropdown
  },
});
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SidebarComponent,
    ShowsComponent,
    HeaderComponent,
    CambioComponent,
    SelecionarAgenciaComponent,
    PaginacaoComponent,
    BuscaComponent,
    ListasComponent,
    ListaAereoComponent,
    ListaServicoComponent,
    ListaCarroComponent,
    AereoComponent,
    TypeaheadComponent,
    ListaHotelComponent,
    HotelComponent,
    PreOrcamentoComponent,
    ServicoComponent,
    CarroComponent,
    ListaPacoteComponent,
    PacoteComponent,
    ListaSeguroComponent,
    SeguroComponent,
    PacoteViewComponent,
    AreaTesteComponent,
    OrcamentoComponent,
    MeusOrcamentosComponent,
    RebuscaComponent,
    AereoReservaComponent,
    AereoEmiteComponent,
    AereoCancelaComponent,
    HotelReservaComponent,
    ReservaServicoComponent,
    ServicoCancelaComponent,
    ReservaPagamentosComponent,
    InputComponent,
    CarroReservaComponent,
    CarroCancelaComponent,
    SeguroReservaComponent,
    SeguroCancelaComponent,
    RecuperarSenhaComponent,
    EstrelaComponent,
    SkeletonPackageCardComponent,
    SkeletonHotelCardComponent,
    SelecionarConsultorComponent,
    EstornoPagamentoComponent,
    ComprovanteCartaoComponent,
    VoucherComponent,
    OrcamentoDownloadComponent,
    LoaderComponent,
    HotelDetalhesComponent,
    ServicoDetalhesComponent,
    AlterarsenhaComponent,
    SelecionarAgenteComponent,
    ReservaTermometroComponent,
    ProdutoofflineComponent,
    FinanciamentosComponent,
    FinanciamentoFormComponent,
    FinanciamentoBoletosComponent,
    ConfirmacaoComponent,
  ],
  imports: [
    routing,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    Ng5SliderModule,
    NgbModule.forRoot(),
    BrowserAnimationsModule,
    MatSidenavModule,
    NgxMaskModule.forRoot(),
    PdfViewerModule,
    AngularEditorModule,
    CarouselModule,
    NgxPrintModule,
    NgxCurrencyModule,
    TagInputModule
  ],
  providers: [
    LoginService,
    AuthInterceptor,
    LoaderInterceptor,
    LoaderService,
    CidadeService,
    PaisService,
    AgenciaService,
    AeroportoService,
    ReservaService,
    AereoService,
    HotelService,
    ServicoService,
    LogService,
    UsuarioService,
    FinanciamentoService,
    VoucherService,
    EmpresaService,
    PerfilMarkupService,
    WsService,
    FormaPagamentoService,
    CambioService,
    SeguroService,
    ParametroService,
    ProfissaoService,
    CarroService,
    Agencia,
    Busca,
    ProdutoOfflineService,
    ProdutoOfflineItemService,
    ProdutoOfflineFornecedorService,
    BuscaAereoRQ,
    TiposDeProdutosService,
    ScriptService,
    ConfirmacaoService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  entryComponents: [
    ConfirmacaoComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
