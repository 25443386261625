import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ProdutoOffline,
  ProdutoOfflineItem,
  ProdutoOfflineFornecedor
} from '../../../model';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors
} from '@angular/forms';
import {
  Location
} from '@angular/common';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {
  LoginService,
  CidadeService,
  TiposDeProdutosService,
  ProdutoOfflineFornecedorService,
  ProdutoOfflineItemService,
  ProdutoOfflineService
} from '../../../services';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DISABLED } from '@angular/forms/src/model';
import validator from 'cpf-cnpj-validator';

@Component({
  selector: 'app-produtooffline',
  templateUrl: './produtooffline.component.html',
  styleUrls: ['./produtooffline.component.scss']
})
export class ProdutoofflineComponent implements OnInit {
  @Input()
  Id: number;

  @Input()
  Orcamento: any;

  @Output() alterou = new EventEmitter<any>();

  display = {};

  mensagensErro = [];
  mensagemErro = '';
  mensagemSucesso = '';

  Markup = 0.74;

  ReservaId: number;

  registro: ProdutoOffline = new ProdutoOffline();

  registroProdutoOfflineItem: ProdutoOfflineItem = new ProdutoOfflineItem();

  Tipos: any;

  carregando = false;

  usuario: any;

  escondeFormItem = true;

  fornecedorOnasys: string;
  selecionouFornecedor = false;
  // usaOnasys = true;
  // usaOperadora = true;

  form: FormGroup;
  formItem: FormGroup;
  emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  numberPattern = /^[0-9]*$/;
  TipoProduto: any;
  config: any = {
    editable: true,
    spellcheck: true,
    height: "8rem",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Digite suas observações...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h3",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "top",
  };
  constructor(
    private produtoOfflineService: ProdutoOfflineService,
    private produtoOfflineItemService: ProdutoOfflineItemService,
    private produtoOfflineFornecedorService: ProdutoOfflineFornecedorService,
    private tiposDeProdutosService: TiposDeProdutosService,
    private cidadeService: CidadeService,
    private formBuilder: FormBuilder,
    public router: Router,
    private _location: Location,
    public route: ActivatedRoute,
    private loginService: LoginService,
    private modalService: NgbModal
  ) { }

  data: [];
  dataOnasys: [];
  usaAuxiliarMkp: boolean = true;
  openAccordion: boolean = false;

  ngOnInit() {
    this.ReservaId = this.Orcamento.Id;
    this.buscaTipos();

    if (this.Id) {
      this.carregarObjeto(this.Id);
    }

    this.form = this.formBuilder.group({
      Titulo: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
      Descritivo: this.formBuilder.control(''),
      // OfflineProdutoTipoId: this.formBuilder.control('', [Validators.required]),
      DataIn: this.formBuilder.control('', [Validators.required, Validators.minLength(6)]),
      DataOut: this.formBuilder.control('', [Validators.required, Validators.minLength(6)]),
      Localizador: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
      QtdInf: this.formBuilder.control(''),
      QtdChd: this.formBuilder.control(''),
      QtdAdt: this.formBuilder.control(''),
      ValorNetInf: this.formBuilder.control(''),
      ValorNetChd: this.formBuilder.control(''),
      ValorNetAdt: this.formBuilder.control(''),
      ValorVendaInf: this.formBuilder.control(''),
      ValorVendaChd: this.formBuilder.control(''),
      ValorVendaAdt: this.formBuilder.control(''),
      PossuiIRRF: this.formBuilder.control(''),
      RegraTarifaria: this.formBuilder.control(''),
      PoliticaCancelamento: this.formBuilder.control('', Validators.required),
      Taxas: this.formBuilder.control(''),
      Cidade: this.formBuilder.control('', Validators.required),

      // OfflineProdutoFornecedor: this.formBuilder.control(''),
      FornecedorOnasys: this.formBuilder.control('', Validators.required),

      Markup: this.formBuilder.control(''),

      DayByDay: this.formBuilder.control(''),
      Inclui: this.formBuilder.control(''),
      NaoInclui: this.formBuilder.control(''),
      Cia: this.formBuilder.control(''),
      Moeda: this.formBuilder.control('', Validators.required),
    });

    this.formItem = this.formBuilder.group({
      Titulo: this.formBuilder.control(''),
      Descricao: this.formBuilder.control(''),
      DtIn: this.formBuilder.control(''),
      DtOut: this.formBuilder.control(''),
      Cia: this.formBuilder.control(''),
      Localizador: this.formBuilder.control(''),
      Apartamento: this.formBuilder.control(''),
      Pensao: this.formBuilder.control(''),
      Origem: this.formBuilder.control(''),
      Destino: this.formBuilder.control(''),
      Valor: this.formBuilder.control(''),
    });

    this.usuario = this.loginService.user();

    if (!this.Id) {
      this.Orcamento.Pessoas.forEach(pessoa => {
        switch (pessoa.Tipo) {
          case 0:
            this.registro.QtdAdt = this.registro.QtdAdt + 1;
            break;
          case 1:
            this.registro.QtdChd = this.registro.QtdChd + 1;
            break;
          case 2:
            this.registro.QtdInf = this.registro.QtdInf + 1;
            break;
        }
      });
    }
  }

  alterarValoresMkp() {
    if (this.usaAuxiliarMkp) {
      let Taxa = (this.registro.Over == undefined) ? 0 : this.registro.Over;

      if (this.registro.ValorNetAdt != undefined && this.registro.ValorNetAdt != 0) {
        this.registro.ValorVendaAdt = (this.registro.ValorNetAdt / this.Markup);
      }

      if (this.registro.ValorNetChd != undefined && this.registro.ValorNetChd != 0) {
        this.registro.ValorVendaChd = (this.registro.ValorNetChd / this.Markup);
      }

      if (this.registro.ValorNetInf != undefined && this.registro.ValorNetInf != 0) {
        this.registro.ValorVendaInf = (this.registro.ValorNetInf / this.Markup);
      }
    }
  }

  salvar() {
    if (this.registro.OfflineProdutoTipoId == undefined || this.registro.OfflineProdutoTipoId == 0) 
      return alert("Informe qual o tipo do produto");
    if(!this.selecionouFornecedor)
    return alert("Selecione o fornecedor do produto");

    // if ((this.usaOnasys == false && this.usaOperadora == false) || (this.usaOnasys == true && this.usaOperadora == true)) {
    //   console.log("Onasys: " + this.usaOnasys, "Operadora: " + this.usaOperadora);

    //   return alert("Erro de fornecedor");
    // }

    this.mensagemSucesso = null;
    this.mensagensErro = null;

    this.registro.ReservaId = this.ReservaId;
    this.produtoOfflineService.salvar(this.registro).subscribe(
      dados => {
        this.mensagemSucesso = 'Registro Salvo com Sucesso!';

        this.registro.Moeda = dados.Moeda;
        this.registro.OfflineProdutoTipo = dados.OfflineProdutoTipo;

        this.alterou.emit(this.registro);

      }, erros => {
        console.log('Erro ao Salvar');
        console.log(erros);
        this.mensagensErro = this.errorHandler(erros);
      },
    );
  }

  getFormValidationErrors(form: FormGroup) {
    let erros = [];
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          const erro = {
            key: key,
            keyError: keyError,
            value: controlErrors[keyError]
          };
          erros = [...erros, erro];
        });
      }
    });
    return erros;
  }

  carregarObjeto(id) {
    this.mensagemSucesso = null;
    this.mensagensErro = null;

    this.produtoOfflineService.buscarPorId(id).subscribe(
      dados => {

        dados.Items[0].dtIn = moment(dados.Items[0].dtIn).format("YYYY-MM-DD");
        dados.Items[0].dtOut = moment(dados.Items[0].dtOut).format("YYYY-MM-DD");

        if (!dados.Items[0].OfflineProdutoFornecedor) {
          dados.Items[0].OfflineProdutoFornecedor = new ProdutoOfflineFornecedor();
        }

        this.selecionouFornecedor = true;

        this.registro = dados.Items[0];

        // this.usaOnasys = false;

        this.TipoProduto = this.registro.OfflineProdutoTipo;

        // this.setOfflineProdutoTipo(this.TipoProduto);



      }, erros => {
        console.log('Erro ao buscar o registro: ');
        console.log(erros);
        this.mensagensErro = this.errorHandler(erros);
      },
    );
  }

  btnVoltar() {
    this._location.back();
  }

  public errorHandler(erro): string[] {

    let mensagem = [];
    switch (erro.status) {
      case 0:
        mensagem.push('o servidor não respondeu, tente novamente mais tarde!');
        break;
      case 400:

        if (erro.error && erro.error.mensagem)
          mensagem.push(erro.error.mensagem);
        else
          mensagem.push("Houve algum erro na execução, tente novamente!");

        break;
      case 401:
        mensagem.push('você não tem autorização para executar esta ação!');
        break;
      case 404:
        mensagem.push(erro.error.mensagem);
        break;
      case 406:
        console.log('a requisição não foi aceita!');
        break;
      case 500:
        mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte');
        break;
      default:
        mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      mensagem = [...mensagem, ...erro.error.mensagens];
    }
    return mensagem;

  }

  selecionaCidade(item) {
    this.registro.Cidade = item;
  }

  onChangeCidade(val: string) {
    this.cidadeService.buscarPorParametro(val, 1).subscribe(
      dados => {
        this.data = dados.Items.map(function (x) {
          x.display_field = x.Nome + ' / ' + x.Estado.Nome + ' - ' + x.Estado.Pais.Nome;
          return x;
        });

      },
      erro => {
        console.log(erro);
        this.data = [];

      },
    );

  }

  alterarFornecedor() {
    // this.usaOnasys = true;
    // this.usaOperadora = true;
    this.fornecedorOnasys = "";
    this.registro.OfflineProdutoFornecedor = { NomeFantasia: "" };
    this.registro.OfflineProdutoFornecedorId = null;
  }

  selecionaFornecedor(item) {
    // this.usaOnasys = false;
    // this.usaOperadora = true;

    this.registro.OfflineProdutoFornecedor = item;
  }

  onChangeFornecedor(val: string) {
    this.produtoOfflineFornecedorService.buscarPorParametro(val, 1).subscribe(
      dados => {
        this.data = dados.Items.map(function (x) {
          x.display_field = x.NomeFantasia;
          return x;
        });

      },
      erro => {
        console.log(erro);
        this.data = [];

      },
    );
  }

  selecionaFornecedorOnasys(item) {
    // this.usaOperadora = false;
    // this.usaOnasys = true;
    this.selecionouFornecedor = true;

    this.registro.OfflineProdutoFornecedor = item;
    this.registro.OfflineProdutoFornecedor.NomeFantasia = item.display_field;

    this.registro.FornecedorOnasys = JSON.stringify(item);

  }

  onChangeFornecedorOnasys(val: string) {
    this.produtoOfflineFornecedorService.buscarPorParametroOnasys(val, 1).subscribe(
      dados => {
        this.dataOnasys = dados.Items.map(function (x) {
          x.display_field = x.nome;
          return x;
        });
      },
      erro => {
        console.log(erro);
        this.dataOnasys = [];
      },
    );
  }

  buscaTipos() {
    this.tiposDeProdutosService.buscarPorParametro('', 1).subscribe(
      dados => {
        this.Tipos = dados.Items;
      },
      erro => {
        console.log(erro);
      },
    );
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  setOfflineProdutoTipo(event) {
    this.TipoProduto = this.Tipos.find(x => x.Id == event);

    this.registro.OfflineProdutoTipo = this.TipoProduto;
    this.registro.OfflineProdutoTipoId = event;
  }


  salvarItem(form) {
    this.mensagemSucesso = null;
    this.mensagensErro = null;

    this.registroProdutoOfflineItem.OfflineProdutoId = this.registro.Id;

    this.produtoOfflineItemService.salvar(this.registroProdutoOfflineItem).subscribe(
      dados => {

        //atualiza a lista de tarifas na view
        if (this.registroProdutoOfflineItem.Id == undefined)
          this.registro.OfflineProdutoItems.push(dados);
        else {
          this.registro.OfflineProdutoItems.splice(this.registro.OfflineProdutoItems.map(x => x.Id).indexOf(dados.Id), 1, dados);
        }

        this.escondeFormItem = true;
        this.mensagemSucesso = 'Registro Salvo com Sucesso!';

      }, erros => {
        console.log('Erro ao Salvar');
        console.log(erros);
        this.mensagensErro = this.errorHandler(erros);
      },
    );
  }
  excluirRegistroItem(id) {

    this.produtoOfflineItemService.remover(id).subscribe(
      dados => {
        this.mensagemSucesso = 'Registro removido com sucesso!';
        //atualiza a lista de Tarifas na view
        this.registro.OfflineProdutoItems = this.registro.OfflineProdutoItems.filter(x => x.Id != id);
      },
      erro => {
        console.log('Erro ao Excluir');
        console.log(erro);
        this.mensagensErro = this.errorHandler(erro);
      },
    );
  }

  public confirmarDeleteItem(id, descricao) {
    if (confirm("Confirmar exclusão de " + descricao)) {
      this.excluirRegistroItem(id);
    }
  }

  novoItem() {
    this.escondeFormItem = false;
    this.formItem.markAsPristine(); // zera os campos do form
    this.formItem.markAsUntouched();
    this.registroProdutoOfflineItem = new ProdutoOfflineItem(); //limpa o objeto
  }

  cancelarItem() {
    this.escondeFormItem = true;
    this.formItem.markAsPristine(); // zera os campos do form
    this.formItem.markAsUntouched();
    this.registroProdutoOfflineItem = new ProdutoOfflineItem(); //limpa o objeto
  }

  editarItem(Item) {

    let ItemClone = cloneDeep(Item);

    ItemClone.DtIn = moment(ItemClone.DtIn).format("YYYY-MM-DD");
    ItemClone.DtOut = moment(ItemClone.DtOut).format("YYYY-MM-DD");

    this.escondeFormItem = false;
    this.formItem.markAsPristine(); // zera os campos do form
    this.formItem.markAsUntouched();

    this.registroProdutoOfflineItem = ItemClone; //limpa o objeto
  }
}
