import { Orcamento } from 'src/app/model';
import { MERCADO_PAGO } from 'src/app/app.config';
// tslint:disable: prefer-for-of
// tslint:disable: no-shadowed-variable
// tslint:disable: only-arrow-functions
// tslint:disable: max-line-length

import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { ReservaService, ProfissaoService, CidadeService, ScriptService } from 'src/app/services';
import { Reserva, ReservaPagamento } from 'src/app/model';
import * as moment from 'moment';
import { __read, __spreadArrays } from 'tslib';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const SCRIPT_PATH = 'https://sdk.mercadopago.com/js/v2';
const JSON_LD_DATA = `
{
  "@context": "http://schema.org",
  "@type": "WebSite",
  "url": "http://website.com",
  "name": "wbs",
  "description": "Web Studio"
}
`;
declare let MercadoPago: any;


@Component({
    selector: 'app-reserva-pagamentos',
    templateUrl: './reserva-pagamentos.component.html',
    styleUrls: ['./reserva-pagamentos.component.scss']
})
export class ReservaPagamentosComponent implements OnInit {

    bandeiras = [
        { Descricao: 'Master' },
        { Descricao: 'Visa' },
        { Descricao: 'Amex' },
        { Descricao: 'Elo' },
        { Descricao: 'Hipercard' },
        { Descricao: 'Hiper' },
        { Descricao: 'Dinners Club' },
        { Descricao: 'JCB' },
        { Descricao: 'Discover' },
        { Descricao: 'American Express' },
        { Descricao: 'Mais!' },
        { Descricao: 'Cabal' },
        { Descricao: 'Soro Cred' },
        { Descricao: 'Banes Card' },
        { Descricao: 'Soro Cred' },
        { Descricao: 'UnionPay' },
    ];

    hoje = moment();
    mp: any;
    dados: any = {};
    profissoes = [];

    @Output() alterou = new EventEmitter<any>();
    @Output() fechou = new EventEmitter<any>();
    @Input() reserva: Reserva;
    @Input() orcamento: Orcamento;

    pagamentos: ReservaPagamento[] = [];
    pagamentosRealizados: ReservaPagamento[] = [];

    podeAdicionarPagamento = true;
    adicionandoPagamento = false;
    carregando = false;
    carregandoInfo = true;
    podePagar = false;
    btnLinkPagamento: boolean;

    valorPago = 0;
    saldo = 0;
    sequencia = 0;

    mensagensErro = [];
    mensagemSucesso: string;

    reservaErro: any;
    formasPagamento: any;
    datas = [];
    comissaoPendente: any;
    pagamentosComissoes: any;
    checkTerceirosPgt = false;

    constructor(
        public _router: Router,
        private reservaService: ReservaService,
        private profissaoService: ProfissaoService,
        private modalService: NgbModal,
        private cidadeService: CidadeService,
        private renderer: Renderer2,
        private scriptService: ScriptService,
    ) {
        _router.events.subscribe((val) => {
            modalService.dismissAll();
        });
    }

    ngOnInit() {
        const scriptElement = this.scriptService.loadJsScript(this.renderer, SCRIPT_PATH);
        this.montaPrimeiroVencimento();
        scriptElement.onload = () => {
            this.mp = new MercadoPago(MERCADO_PAGO, {
                locale: 'pt-BR'
            });
        }
        scriptElement.onerror = () => {
            console.log('Não foi possível carregar a API do Mercado Pago.');
        }

        this.scriptService.setJsonLd(this.renderer, JSON_LD_DATA);
        this.carregandoInfo = true;
        this.verificarPodePagar();
        this.btnLinkPagamento = false;
    }

    linkPagamento() {
        this.btnLinkPagamento = !this.btnLinkPagamento;
    }

    gerarLinkPagamento() {
        this.pagamentos = this.pagamentos.map((x) => {
            x.CondicaoPagamentoId = x.CondicaoPagamento.Id;
            x.FormaSelecionada = x.formaSelecionada.FormaPagamento;
            x.emailLinkPagamento = true;
            // x.TipoPagamento = x.formaSelecionada.FormaPagamento.TipoPagamento;
            if (x.Financiamentos) {
                x.Financiamentos = x.Financiamentos.map((y) => {
                    y.DataPrimeiroVencimento = moment(
                        y.DataPrimeiroVencimento,
                        "DDMMYYYY"
                    ); //moment(y.dataNascimento, 'DDMMYYYY');

                    y.FinanceiroTerceiros = this.checkTerceirosPgt;

                    return y;
                });
            }
            return x;
        });

        let pagamentos = {
            PagamentosRQ: this.pagamentos,
            DescontoAplicado: this.reserva.DescontoAplicado,
        };
        this.reservaService
            .linkPagamento(pagamentos.PagamentosRQ, this.reserva.Id)
            .subscribe(
                (dados) => {
                    alert("Link para pagamento com cartão enviado por Email.");
                    this.buscarPagamentos(false);
                    this.alterou.emit(this.pagamentosRealizados);
                    // this.alterou.emit();
                    //window.open(`${API}/ContratosAssinaturas/PagamentoCartaoPg4.aspx?chave=${dados}`, "blank");
                },
                (error) => {
                    alert(error.error.mensagem);
                }
            );

    }

    consultarPagamento(pagamento) {

        this.reservaService.consultarPagamento(pagamento.Id).subscribe(
            dados => {

            }, erro => {

            })
    }

    montaPrimeiroVencimento() {
        let a = moment().add(1, "months");
        let b = moment().add({ days: 10, months: 1 });
        let tem31 = false;
        let ultimaData: any;
        for (var m = moment(a); m.isBefore(b); m.add(1, "days")) {
            let dia = m.format('D');
            if (dia != "31") {
                this.datas.push(m.format("DD/MM/YYYY"));
            } else {
                tem31 = true;
            }
            ultimaData = m;
        }

        if (tem31) {
            ultimaData = ultimaData.add(1, "days");
            this.datas.push(ultimaData.format("DD/MM/YYYY"));
        }
    }

    verificarPodePagar() {
        this.reservaService.verificarPodePagar(this.reserva.Id).subscribe(
            () => {
                this.podePagar = true;
                this.buscarPagamentos(true);
                this.carregandoInfo = false;
                this.novoPagamento();
            },
            erro => {
                this.reservaErro = erro.error;
                this.carregandoInfo = false;

            });

    }

    buscarPagamentos(formas: boolean) {
        this.reservaService.buscarPagamentos(this.reserva.Id).subscribe(
            dados => {
                if (formas !== false) {
                    this.formasPagamento = dados.FormasPgto;
                    this.formasPagamento = this.formasPagamento.map(x => {
                        x.FormaPagamento.Condicoes = x.FormaPagamento.Condicoes.map(y => {
                            y.FormaPagamento = { Id: x.FormaPagamento.Id, Descricao: x.FormaPagamento.Descricao };
                            return y;
                        });
                        return x;
                    });
                }
                this.pagamentosRealizados = dados.Pagamentos;
                this.valorPago = this.pagamentosRealizados.filter(x => x.Status === 2 || x.Status === 4).map(x => x.Valor).reduce(function (a, b) {
                    return a + b;
                }, 0);

                this.pagamentosComissoes = this.pagamentosRealizados.filter(x => (x.Status === 2 || x.Status === 4) && x.TipoPagamento === 0).map(x => x.Valor).reduce(function (a, b) {
                    return a + b;
                }, 0);

                this.comissaoPendente = (this.reserva.ValorTotalComissao * (this.reserva.Agencia.Comissao / 100)) - this.pagamentosComissoes;
                this.saldo = this.reserva.ValorTotal - this.valorPago;
            }, erro => {
                console.log(erro);
            });

        this.carregando = false;
    }

    monta(pagamento) {
        this.pagamentos.map(() => {
            var index = this.pagamentos.indexOf(pagamento);

            this.pagamentos[index].PaxPaganteId = pagamento.PaxPaganteDados.Id;

            console.log(this.pagamentos[index].formaSelecionada);

            if (this.pagamentos[index].formaSelecionada.FormaPagamentoId !== 2) {
                this.pagamentos[index].CPF = pagamento.PaxPaganteDados.CPF
                    ? pagamento.PaxPaganteDados.CPF
                    : "";
                this.pagamentos[index].Email = pagamento.PaxPaganteDados.Email
                    ? pagamento.PaxPaganteDados.Email
                    : "";
                this.pagamentos[index].Telefone = pagamento.PaxPaganteDados.Telefone
                    ? pagamento.PaxPaganteDados.Telefone
                    : "";
            }

        });
    }

    novoPagamento() {
        this.adicionandoPagamento = true;
        const pgto = new ReservaPagamento();
        this.sequencia = this.sequencia + 1;
        pgto.Guid = this.sequencia;
        pgto.Valor = 0;
        pgto.PaxNaoPagantes = [];
        if (this.reserva.Pessoas.length === 1) {
            pgto.PaxPaganteId = this.pessoasPagantes()[0].Id;
        }
        this.pagamentos.push(pgto);
        this.podeAdicionarPagamento = false;
    }

    calcularSaldoGeral() {
        const saldo = this.saldo - (this.somaPagamentosInformados() * 1);
        return parseFloat(saldo.toFixed(2));

    }

    somaPagamentosInformados(): any {
        const soma = this.pagamentos.map(x => x.Valor).reduce(function (a, b) {
            return (a * 1) + (b * 1);
        }, 0);
        return parseFloat(soma.toFixed(2));
    }

    pessoasPagantes() {
        const idsT = this.pagamentos.map(y => y.PaxNaoPagantes.map(x => x));
        let ids = [];
        for (let i = 0; i < idsT.length; i++) {
            ids = ids.concat(idsT[i]);
        }

        return this.reserva.Pessoas.filter(x =>
            (!x.PaxPaganteId || x.PaxPaganteId === x.Id)
            && (ids.indexOf(x.Id) === -1)
        );
    }

    possuiPaxNaoPagantes(paxPaganteId, guid) {
        const x = this.pagamentos.filter(x => x.PaxPaganteId === paxPaganteId && x.Guid !== guid).filter(x => x.PaxNaoPagantes.length > 0);
        return (x.length > 0);
    }

    pessoasNaoPagantes(pagamento) {
        const idPagante = pagamento.idPagante;
        const idsT = this.pagamentos.map(y => y.PaxNaoPagantes.map(x => x));
        let ids = [];
        for (let i = 0; i < idsT.length; i++) {
            ids = ids.concat(idsT[i]);
        }

        let naoPagantes = this.reserva.Pessoas.filter(x =>
            pagamento.PaxNaoPagantes.indexOf(x.Id) >= 0 ||
            ((!x.PaxPaganteId || x.PaxPaganteId === idPagante)
                && x.Id !== idPagante
                && ids.indexOf(x.Id) < 0
                && this.pagamentos.map(y => y.PaxPaganteId).indexOf(x.Id) === -1)
        );

        // naoPagantes = [this.reserva.Pessoas.filter(x => x.Id !== pagamento.PaxPaganteId && x.PaxPaganteId === pagamento.PaxPaganteId), ...naoPagantes];

        naoPagantes = __spreadArrays([this.reserva.Pessoas.filter(x => x.Id !== pagamento.PaxPaganteId && x.PaxPaganteId === pagamento.PaxPaganteId)], __read(naoPagantes));

        return naoPagantes;
    }


    selecionarPaxNaoPagante(pax, pagamento) {

        if (!pax.PaxPaganteId || pax.PaxPaganteId === 0) {
            const id = pax.Id;
            const i = pagamento.PaxNaoPagantes.indexOf(id);
            if (i === -1) {
                pagamento.PaxNaoPagantes.push(id);
            } else {
                pagamento.PaxNaoPagantes.splice(i, 1);
            }
        } else {
            alert('Esse pax não pode ser alterado');
        }

    }

    checado(pax, pagamento) {
        return pagamento.PaxNaoPagantes.indexOf(pax.Id) > -1 || pax.PaxPaganteId === pagamento.PaxPaganteId;
    }

    selecionouFormaPagamento(pagamento) {
        // COMISSAO = 0,
        // FATAURADO = 1,
        // CARTAO_CREDITO = 2,
        // BOLETO_VISTA = 3,
        // FINANCIAMENTO = 4
        this.btnLinkPagamento = false;
        switch (pagamento.formaSelecionada.FormaPagamento.TipoPagamento) {
            case 0:   // COMISSAO = 0,
                const comissao: number = this.reserva.ValorTotalComissao * (this.reserva.Agencia.Comissao / 100);
                if (pagamento.Valor > comissao || pagamento.Valor === 0) {
                    pagamento.Valor = comissao - this.pagamentosComissoes;
                }
                pagamento.Descricao = 'Comissão';
                break;
            case 1: // FATAURADO = 1,
                pagamento.Descricao = 'Faturado';
                pagamento.Valor = this.calcularSaldoGeral() * 1;
                break;
            case 2: // CARTAO_CREDITO = 2,
                if (pagamento.Valor === 0) {
                    pagamento.Valor = this.calcularSaldoGeral() * 1;
                }
                pagamento.Cartoes = [{}];
                pagamento.Descricao = 'Cartão Crédito';
                this.btnLinkPagamento = true;
                break;
            case 3: // BOLETO_VISTA = 3,
                if (pagamento.Valor === 0) {
                    pagamento.Valor = this.calcularSaldoGeral() * 1;
                }
                pagamento.Boletos = [{ Parcela: 1 }];
                pagamento.Descricao = 'Boleto à vista';
                break;
            case 4: // FINANCIAMENTO = 4
                // this.pagamentos = this.pagamentos.map((x) => {
                //   x.Valor = 0;
                //   x.Taxas = 0;
                //   return x;
                // });

                if (pagamento.Valor === 0) {
                    pagamento.Valor = this.calcularSaldoGeral() * 1;
                }
                pagamento.Financiamentos = [{}];
                pagamento.Financiamentos[0].DataPrimeiroVencimento = moment()
                    .add(1, "months")
                    .format("DD/MM/YYYY");
                pagamento.Financiamentos[0].FinanceiroTerceiros = false;

                pagamento.Descricao = 'Financiamento';
                if (this.profissoes.length <= 0) {
                    this.profissaoService.buscarPorParametro('', 1).subscribe(
                        dados => {
                            this.profissoes = dados.Items;
                        },
                        error => {
                            console.log(error);
                        }
                    );
                }
                break;
            case 7: // CONFIRMACAO = 7,
                pagamento.Descricao = 'Confirmação de Terceiros';
                pagamento.Valor = this.calcularSaldoGeral() * 1;
                break;
        }

        pagamento.CondicaoPagamento = pagamento.formaSelecionada.FormaPagamento.Condicoes[0];
        this.podeAdicionarPagamento = this.podeAdicionarPagamento || this.pagamentos.filter(x => !x.formaSelecionada).length === 0;

    }


    removerPagamento(indice) {

        this.pagamentos.splice(indice, 1);
        if (this.pagamentos.length === 0) {
            this.podeAdicionarPagamento = true;
        }
    }
    //cardNumber", "identificationType", "identificationNumber", "cardholderName", "cardExpirationMonth", "cardExpirationYear", "securityCode
    async gerarTokenMP(cartao, CPF) {
        try {
            let cardNumber = cartao.Numero.replace(/ /g, '');
            let securityCode = cartao.DigitoVerificador.replace(/ /g, '');
            let data = cartao.DataValidade.split('/');
            let cardExpirationMonth = data[0];
            let cardExpirationYear = data[1];
            const token = await this.mp.createCardToken({
                cardNumber: cardNumber,
                securityCode: securityCode,
                cardExpirationMonth: cardExpirationMonth,
                cardExpirationYear: cardExpirationYear,
                cardholderName: cartao.Nome,
                identificationType: 'CPF',
                identificationNumber: CPF,
                issuer: cartao.Bandeira
            })
            return token.id;
        } catch (e) {
            console.error('error creating token: ', e.message);
            throw e;
        }
    }

    async efetuarPagamento() {
        try {
            // this.carregando = true;
            this.mensagemSucesso = '';
            this.mensagensErro = [];

            this.pagamentos = this.pagamentos.map(x => {

                x.CondicaoPagamentoId = x.CondicaoPagamento.Id;

                x.FormaSelecionada = x.formaSelecionada.FormaPagamento;

                if (x.Financiamentos) {
                    x.Financiamentos = x.Financiamentos.map((y) => {
                        y.DataPrimeiroVencimento = moment(
                            y.DataPrimeiroVencimento,
                            "DDMMYYYY"
                        ); // moment(y.dataNascimento, 'DDMMYYYY');

                        y.FinanceiroTerceiros = this.checkTerceirosPgt;

                        return y;
                    });
                }
                return x;
            });

            let pagamentos = {
                PagamentosRQ: this.pagamentos,
                DescontoAplicado: this.reserva.DescontoAplicado
            };
            let sucesso = false;

            let pagamento = this.pagamentos[0];
            if (pagamento.formaSelecionada.FormaPagamento.TipoPagamento == 2) {
                let cartao = pagamento.Cartoes[0];
                cartao.ExtrasIntegrador = await this.gerarTokenMP(cartao, pagamento.CPF);
            }
            this.reservaService.enviarPagamentos(pagamentos, this.reserva.Id).subscribe(
                dados => {
                    this.dados = dados;
                    const guids = dados.filter(x => x.Erros.length > 0).map(x => x.Guid);
                    for (let i = 0; i < this.pagamentos.length; i++) {
                        if (guids.indexOf(this.pagamentos[i].Guid) > -1) {
                            this.pagamentos[i].Erros = dados.filter(
                                y => this.pagamentos[i].Guid === y.Guid
                            )[0].Erros;

                        } else if (dados[i].TipoPagamento == 2) {
                            if (
                                dados[i].Cartoes[0].MensagemRetornoCartao &&
                                dados[i].Cartoes[0].CodigoRetornoCartao
                            ) {
                                if (dados[i].Status == 2 || dados[i].Status == 4) {
                                    this.pagamentos[i].Success =
                                        dados[i].Cartoes[0].CodigoRetornoCartao +
                                        " - " +
                                        dados[i].Cartoes[0].MensagemRetornoCartao;
                                    alert(this.pagamentos[i].Success);
                                } else {
                                    alert(dados[i].Cartoes[0].CodigoRetornoCartao +
                                        " - " +
                                        dados[i].Cartoes[0].MensagemRetornoCartao);
                                    this.mensagensErro = [];
                                    this.mensagensErro.push(
                                        dados[i].Cartoes[0].CodigoRetornoCartao +
                                        " - " +
                                        dados[i].Cartoes[0].MensagemRetornoCartao
                                    );
                                }
                            } else {
                                this.pagamentos[i].Success = "Pagamento registrado com sucesso!";
                                sucesso = true;
                            }

                            this.pagamentos[i].Processado = true;
                        }
                    }
                    const pagamentosRegistrados = this.dados.filter(x => !x.Erros || x.Erros.length === 0);
                    this.pagamentos = this.pagamentos.filter(x => x.Erros && x.Erros.length > 0);
                    // console.log(pagamentosRegistrados, this.pagamentosRealizados);

                    // this.pagamentosRealizados = [...this.pagamentosRealizados, ...pagamentosRegistrados];
                    this.pagamentosRealizados = __spreadArrays([this.pagamentosRealizados], __read(pagamentosRegistrados));

                    this.carregando = false;

                    this.buscarPagamentos(false);
                    this.alterou.emit(this.pagamentosRealizados);
                    if (sucesso) {
                        alert("Pagamento registrado com sucesso!")
                        this.fecharModal();
                    }
                    if (this.dados[0].TipoPagamento == 4 && !(this.dados[0].Financiamentos[0].GrauParentesco && this.dados[0].Financiamentos[0].GrauParentesco == '99')) {
                        this._router.navigate([`/financiamentos/${this.dados[0].Financiamentos[0].Id}`]);
                    }

                }, erros => {
                    console.log('Erro ao enviar os pagamentos');
                    console.log(erros);
                    this.mensagensErro.push(erros.error.mensagem);
                    this.carregando = false;
                });

        } catch (e) {
            this.mensagensErro.push('Erro ao enviar o pagamento, tente novamente.');
            this.carregando = false;
        }
    }

    fecharModal() {
        this.modalService.dismissAll();
    }

    selecionarProfissao(pagamento) {
        pagamento.Profissao = pagamento.profissao.Descricao;
        pagamento.ProfissaoCodigo = pagamento.profissao.CodigoPagtur;
    }

    buscaCepPax(pagamento) {
        this.cidadeService.buscarEnderecoPorCep(pagamento.CEP).subscribe(
            dados => {
                pagamento.Logradouro = dados.logradouro;
                pagamento.Bairro = dados.bairro;
                pagamento.Complemento = dados.complemento;
                pagamento.Cidade = dados.localidade;
                pagamento.Estado = dados.uf;
            });
    }

    validaData(data) {
        data = moment(data, 'DDMMYYYY', true);
        const now = moment();
        return data && data.isValid() && data < now;
    }

    setDataNascimento($event, obj) {
        obj.DataNascimento = $event;
    }

    validaComissao(pagamentos) {
        if (pagamentos.length > 0 && pagamentos.every(x => x.formaSelecionada)) {
            return pagamentos.some(x => x.formaSelecionada.FormaPagamento.TipoPagamento === 0 && x.Valor > this.comissaoPendente);
        } else {
            return false;
        }

    }
}
