import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BuscaComponent } from "../busca/busca.component";

export const BUSCA = "busca";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})

export class SidebarComponent extends BuscaComponent implements OnInit {
  @Output() deslogou = new EventEmitter<any>();

  sidebarOpcoes = [
    { title: "Home", icon: "fas fa-home", active: false, function: "/", acessivel: true },
    { title: "Shows", icon: "fas fa-music", active: false, function: "shows", acessivel: false },
    { title: "Orçamentos", icon: "fas fa-file-alt", active: false, function: "orcamentos", acessivel: true},
  ];

  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem("loggedUser"));
    this.erros = null;

  }

  ngDoCheck() {
    this.checkRouting();
  }

  removerBusca() {
    sessionStorage.clear();
  }

  logout() {
    if (confirm("Confirmar Logout ?")) {
      this.loginService.logout();
      this.deslogou.emit("Deslogou");
      window.location.reload();
    }
  }

  checkRouting() {
    const item = window.location.pathname;
    var a = 0;

    switch (item) {
      case "/":
        this.sidebarOpcoes[a].active = true;
        this.sidebarOpcoes[a + 1].active = false;
        this.sidebarOpcoes[a + 2].active = false;
      break;
      case "/shows":
        this.sidebarOpcoes[a].active = false;
        this.sidebarOpcoes[a + 1].active = true;
        this.sidebarOpcoes[a + 2].active = false;
      break;
      case "/orcamentos":
        this.sidebarOpcoes[a].active = false;
        this.sidebarOpcoes[a + 1].active = false;
        this.sidebarOpcoes[a + 2].active = true;
      break;
    }
  }

  routing(item) {
    var a = 0;
    switch (item) {
      case "/":
        this._router.navigate([`/`]);
        break;

      case "shows":
        this._router.navigate([`/shows`]);
        break;

      case "orcamentos":
        this._router.navigate([`/orcamentos`]);
        break;
    }
  }

  verificaAgencia() {
    let agencia = window.localStorage.getItem("g8h7d8");
    if(this.usuario.AgenciaId != null || agencia != null || undefined){
     this.personalizado = !this.personalizado;
   }else if (agencia == null || undefined) {
      alert("Selecione uma agência para continuar");
    }

  }

}
