import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { Busca } from "src/app/model";
import { Seguro } from "src/app/model/turismo/seguro.model";
import { ReservaService, SeguroService } from "src/app/services";

@Component({
  selector: "app-pre-orcamento",
  templateUrl: "./pre-orcamento.component.html",
  styleUrls: ["./pre-orcamento.component.scss"],
})
export class PreOrcamentoComponent implements OnInit {
  @Input() busca: Busca;
  @Input() Orcamento: any;
  @Input() usuario;
  clicked = false;
  progress: any;
  user: any;
  path: any;
  orcamentoPage: any;
  erros = [];
  hotelCardLoadded = false;
  @Output() selecionouHotel = new EventEmitter<any>();
  @ViewChild('roomOptions') openModal:ElementRef;
  objeto: any;
  carregando: boolean = false;

  constructor(
    private reservaService: ReservaService,
    private seguroService: SeguroService,
    private _router: Router
  ) {}

  ngOnInit() {
    window.location.pathname == "/listas/orcamento"
      ? (this.orcamentoPage = true)
      : (this.orcamentoPage = false);
    this.user = JSON.parse(localStorage.getItem("loggedUser"));
    this.path = window.location.pathname;

    this.Orcamento = JSON.parse(sessionStorage.getItem('orcamentousuario'));
    console.log(this.Orcamento);
  }

  excluirRegistro(index, objeto, aereo) {
    if (
      this.Orcamento.Aereos.leght > 0 && this.Orcamento.Hoteis.leght > 0 ||
      this.Orcamento.Aereos.leght > 0 && this.Orcamento.Servicos.leght > 0 ||
      this.Orcamento.Aereos.leght > 0 && this.Orcamento.Carros.leght > 0 ||
      this.Orcamento.Aereos.leght > 0 && this.Orcamento.Seguros.leght > 0
    ) {
      alert("É necessário manter pelo menos um item além do aéreo!");
    } else {
      objeto = objeto.splice(index, 1);
    }
    window.sessionStorage.setItem(
      "orcamentousuario",
      JSON.stringify(this.Orcamento)
    );

    if (
      this.Orcamento.Hoteis.length === 0 &&
      this.Orcamento.Servicos.length === 0 &&
      this.Orcamento.Aereos.length === 0
    ) {
      alert("Pré orçamento cancelado!");
      sessionStorage.clear();
      this._router.navigate(["/"]);
    }
  }

  public confirmarDelete(index, objeto, aereo = false) {
    const resposta = window.confirm(
      `Tem certeza que deseja excluir o registro ?`
    );

    if (resposta) {
      this.excluirRegistro(index, objeto, aereo);
    } else {
      // some code
    }
  }

  somaTotal(arr) {
    return arr
      .map((x) => x.ValorListagem)
      .reduce(function (a, b) {
        return a + b;
      }, 0);
  }

  seguro: Seguro;
    GetDetalhesCobertura(seguro: Seguro) {
        this.seguroService.detalhesCobertura(seguro).subscribe(
            dados => {
                this.seguro = dados.Seguro;
                console.log(this.seguro);
                // this.LngLat = "[" + this.hotel.Longitude.replace(/,/g, '.') + "," + this.hotel.Latitude.replace(/,/g, '.') + "]";
                this.carregando = false;
            }, erro => {
                this.carregando = false;
                alert(erro);
            });
    }

  salvarOrcamento() {
    this.clicked = true;
    this.erros = [];
    if (!this.busca.novoOrcamento) {
      if (this.Orcamento.Hoteis && this.Orcamento.Hoteis.length > 0) {
        if (this.Orcamento.Hoteis.filter((x) => x.Opcao <= 0).length > 0) {
          this.erros.push("Selecione a opção dos hotéis para prosseguir");
          alert(this.erros);
          this.clicked = false;
          return false;
        }
      }
    }

    

    this.Orcamento.quartos = this.busca.quartos;
    this.Orcamento.AgenciaId = this.busca.AgenciaId;

    if (this.busca.novoOrcamento) {
      this.reservaService.salvarOrcamento(this.Orcamento).subscribe(
        (dados) => {
          window.sessionStorage.removeItem("orcamentousuario");
          this._router.navigate([`/orcamento/${dados.Id}`]);
        },
        (erros) => {
          console.log(erros);
          this.clicked = false;
          this.erros = this.errorHandler(erros);
        }
      );
    } else {
      this.clicked = false;
      this.reservaService
        .adicionarItemOrcamento(this.Orcamento, this.busca.orcamentoId)
        .subscribe(
          (dados) => {
            window.sessionStorage.removeItem("orcamentousuario");
            this._router.navigate([`/orcamento/${this.busca.orcamentoId}`]);
          },
          (erros) => {
            console.log(erros);

            this.erros = this.errorHandler(erros);
          }
        );
    }
  }

  public errorHandler(erro): string[] {
    const mensagem = [];
    switch (erro.status) {
      case 0:
        mensagem.push("o servidor não respondeu, tente novamente mais tarde!");
        break;
      case 400:
        mensagem.push("Houve algum erro na execução, tente novamente!");
        break;
      case 401:
        mensagem.push("você não tem autorização para executar esta ação!");
        break;
      case 404:
        mensagem.push(erro.error.mensagem);
        break;
      case 406:
        console.log("a requisição não foi aceita!");
        break;
      case 500:
        mensagem.push(
          "Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte"
        );
        break;
      default:
        mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
    }
    return mensagem;
  }

  adicionarItem(item) {
    this._router.navigate([`listas/${item}`]);
  }
  selecionou(hotel: any) {
    this.selecionouHotel.emit(hotel);
  }
  adicionarPacote() {
    this._router.navigate([`lista-pacotes/pacotes`]);
  }
  carregar(obj) {
    this.hotelCardLoadded = true;
    this.objeto = obj;
    setTimeout(() => {
      this.openModal.nativeElement.click();
    }, 100);
  }
}
