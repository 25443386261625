// tslint:disable: max-line-length
// tslint:disable: use-life-cycle-interface

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AereoService } from 'src/app/services';
import { Aereo } from 'src/app/model';
import { __read, __spreadArrays } from 'tslib';
// import { ModalManager } from 'ngb-modal';
// import { Router,ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-aereo-reserva',
    templateUrl: './aereo-reserva.component.html',
    styleUrls: ['./aereo-reserva.component.scss']
})
export class AereoReservaComponent implements OnInit {

    constructor(private aereoService: AereoService) { }

    @Output() alterou = new EventEmitter<any>();
    @Output() fechou = new EventEmitter<any>();
    @Input() aereo: any;
    @ViewChild('contentAereoReserva') contentAereoReserva;

    aereoRetarifado: Aereo;

    cienteAlterouValor = false;
    carregando = false;

    mensagensErro: any[];
    regrasTarifarias: any;
    politica: any;

    mensagemSucesso: string;
    progress: number;


    ngOnInit() {
        this.progress = 1;
        this.carregando = true;
        delete this.mensagensErro;
        delete this.mensagemSucesso;
        console.log("tarifar");
        this.aereoService.tarifarAereo(this.aereo).subscribe(
            dados => {
                this.aereoRetarifado = dados;
                this.aereoRetarifado.VendaTotal = this.aereoRetarifado.VendaTotal;
                this.carregando = false;

            }, erros => {
                console.log(erros);
                alert(this.errorHandler(erros));
                this.carregando = false;
            });
    }

    ngAfterViewInit() {


    }

    onSubmit() {
        return false;
    }

    voltar() {
        this.progress--;
    }

    buscarPoliticasCia() {
        if (!this.regrasTarifarias) {
            delete this.mensagensErro;
            delete this.mensagemSucesso;

            this.carregando = true;
            this.aereoService.regrasTarifarias(this.aereo).subscribe(
                dados => {
                    console.log(dados);
                    this.regrasTarifarias = dados;
                    this.carregando = false;
                    this.progress++;
                },
                erros => {
                    console.log(erros);
                    this.mensagensErro = this.errorHandler(erros);
                    this.carregando = false;
                });
        }

    }

    reservarAereo() {
        delete this.mensagensErro;
        delete this.mensagemSucesso;
        if (this.carregando === false) {
            this.carregando = true;
            this.aereoService.reservarAereo(this.aereoRetarifado).subscribe(
                dados => {
                    console.log(dados);
                    this.aereo = dados;
                    this.carregando = false;
                    this.mensagemSucesso = 'Aereo reservado!';
                    this.alterou.emit(this.aereo);
                    this.progress++;
                }, erros => {
                    console.log(erros);
                    alert(this.errorHandler(erros));
                    this.carregando = false;
                });
        } else {
            alert('Aguarde ! Estamos finalizando sua reserva de aéreo.');
        }
    }

    public errorHandler(erro): string[] {

        let mensagem = [];
        switch (erro.status) {
            case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
            case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
            case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
            case 404: mensagem.push('Solicitação inválida!'); break;
            case 406: mensagem.push(erro.error.mensagem); break;
            case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'); break;
            default: mensagem.push(erro.statusText);
        }
        if (erro.error != null && erro.error.mensagens != null) {
            mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
        }
        return mensagem;

    }

}
