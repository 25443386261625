import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-aereo',
  templateUrl: './aereo.component.html',
  styleUrls: ['./aereo.component.scss']
})
export class AereoComponent implements OnInit {

  @Output() selecionouAereo = new EventEmitter<any>();
  @Output() removeuAereo = new EventEmitter<any>();
  @Output() avancar = new EventEmitter<any>();
  @Input() aereo;
  @Input() usuario;
  @Input() selecionar = true;

  busca: any;
  orcamentoPage: any;

  constructor() { }

  ngOnInit() {
    this.busca = JSON.parse(sessionStorage.getItem('busca'));
    (window.location.pathname == '/listas/orcamento' ? this.orcamentoPage = true : this.orcamentoPage = false);
  }

  selecionou(aereo: any) {
    this.selecionouAereo.emit(aereo);
    this.rotaAvancar();
  }

  removeu (aereo) {
    this.removeuAereo.emit(aereo);
  }

  rotaAvancar() {
    this.avancar.emit('x');
  }
}
