export class PerfilUsuario {

  public Id: number;
  public Descricao: string;
  public Role : string;
  public Ativo: boolean;
  public Agencia: boolean;
  public Empresa: boolean;
  public Permissoes: any[];
  public Alteracoes: any;

}
