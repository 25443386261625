import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";

import { Reserva, ReservaPagamento } from "../../model/";

import { API, ITENSPORPAGINA } from "../../app.config";

@Injectable()
export class ReservaService {

  constructor(private http: HttpClient) {}

  buscaTermometro(id:number):  Observable<any>{
    return this.http.get<any>(`${API}/api/reservas/termometro/${id}`);
  }

  linkPagamento(pagamentos: any, reservaId: number): Observable<any> {
    return this.http.post<any>(`${API}/api/contrato/linkparapagamento/${reservaId}`, pagamentos);
  }

  gerarLinkPagamento(pagamentoId: number): Observable<any> {
    return this.http.get<any>(`${API}/api/reserva/criar/link-cartaopagamento/${pagamentoId}`);
  }

  adicionarItemOrcamento(reserva: Reserva, id: number): Observable<any> {
    return this.http.patch<any>(`${API}/api/Reserva/${id}`, reserva);
  }

  salvarOrcamento(reserva: Reserva): Observable<any> {
    return this.http.post<any>(`${API}/api/Reserva/`, reserva);
  }

  alterarOrcamento(reserva: Reserva): Observable<any> {
    return this.http.put<any>(`${API}/api/Reserva/${reserva.Id}`, reserva);
  }

  buscarMeusOrcamentos(filtro: any, pagina: number): Observable<any> {
    return this.http.post<any>(
      `${API}/api/reservas-minhas?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`,
      filtro
    );
  }

  buscarOrcamento(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/Reserva/${id}`);
  }

  buscaReservaBusca(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/reservas/pesquisa/${id}`);
  }

  salvarDadosPessoas(pessoas: any): Observable<any> {
    let id = pessoas[0].ReservaId;
    return this.http.post<any>(`${API}/api/reservas/paxes/${id}`, pessoas);
  }

  buscarConsultor(consultor): Observable<any> {
    const headers = new HttpHeaders().set("noloader", "t");

    return this.http.get<any>(`${API}/api/usuario/consultor/${consultor}`, {
      headers: headers,
    });
  }

  alterarConsultor(reservaId, consultorId): Observable<any> {
    return this.http.put<any>(
      `${API}/api/reserva/consultor/${reservaId}/${consultorId}`,
      {}
    );
  }

  alterarAgente(reservaId, agenteId): Observable<any>{
    return this.http.put<any>( `${API}/api/reserva/agente/${reservaId}/${agenteId}`, {});
  }

  buscarPagamentos(id): Observable<any> {
    const headers = new HttpHeaders().set("noloader", "t");
    return this.http.get<any>(`${API}/api/reservas/pagamentos/${id}`, {
      headers: headers,
    });
  }

  verificarPodePagar(reservaId): Observable<any> {
    return this.http.get<any>(
      `${API}/api/reserva/verificar-pagar/${reservaId}`
    );
  }

  enviarPagamentos(pagamentos, reservaId): Observable<any> {
    return this.http.post<any>(
      `${API}/api/reservas/pagamentos/${reservaId}`,
      pagamentos
    );
  }

  estornarPagamento(pagamentoId): Observable<any> {
    return this.http.delete<any>(
      `${API}/api/reservas/pagamentos/${pagamentoId}`
    );
  }

  consultarPagamento(pagamentoId): Observable<any> {
    return this.http.get<any>(
      `${API}/api/reservas/pagamentos/consultar/${pagamentoId}`
    );
  }

  urlOrcamento(reservaID, simplificado): string {
    return `${API}/api/orcamento/${simplificado}/${reservaID}`;
    //return this.http.get<any>(`${TURISMO}/api/orcamento//${reservaID}`);
  }

  exibirOrcamento(reservaID, simplificado): Observable<any> {
    return this.http
      .get<any>(`${API}/api/orcamento/${simplificado}/${reservaID}`, {
        responseType: "blob" as "json",
      })
      .map((res) => {
        return new Blob([res], { type: "application/pdf" });
      });
  }

  enviarOrcamento(id, simplificado, email): Observable<any> {
    return this.http.patch<any>(
      `${API}/api/OrcamentoEnvio/${id}/${simplificado}?html=1`,
      { Email: email }
    );
  }

  alterarReservaPagamento(reservapagamento: ReservaPagamento): Observable<any> {
    return this.http.put<any>(
      `${API}/api/reservas/pagamentos/alterar/${reservapagamento.Id}`,
      reservapagamento
    );
  }

  geradorChaveLink(pagamentoId: number): Observable<any> {
    return this.http.get<any>(`${API}/api/contrato/criar/link/${pagamentoId}`);
  }

  geradorChaveLinkPDF(pagamentoId: number): Observable<any> {
    return this.http.get<any>(`${API}/api/contrato/criar/linkPDF/${pagamentoId}`);
  }

  geradorChave(pagamentoId: number): Observable<any> {
    return this.http.get<any>(`${API}/api/contrato/criar/chave/${pagamentoId}`);
  }
}
