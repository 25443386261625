import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Busca, BuscaServicoRQ } from 'src/app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Util } from 'src/app/services';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-servico',
  templateUrl: './servico.component.html',
  styleUrls: ['./servico.component.scss']
})
export class ServicoComponent implements OnInit {

  @Input() servico: any;
  @Input() usuario: any;
  @Input() busca: Busca;
  @Input() buscaRQ: BuscaServicoRQ;
  @Input() selecionar = true;
  @Output() selecionouServico = new EventEmitter<any>();
  @Output() removeuServico = new EventEmitter<any>();
  @Input() index;

  itemBusca : any;

  orcamentoPage: any;

  diasDaSemana = [
   {diaSemana: 'Dom'},
   {diaSemana: 'Seg'},
   {diaSemana: 'Ter'},
   {diaSemana: 'Qua'},
   {diaSemana: 'Qui'},
   {diaSemana: 'Sex'},
   {diaSemana: 'Sáb'}
  ]

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    (window.location.pathname == '/listas/orcamento' ? this.orcamentoPage = true : this.orcamentoPage = false);
  }

  selecionou(servico: any) {
    this.selecionouServico.emit(servico);
  }

  removeu(servico: any) {
    this.removeuServico.emit(servico);
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  abrirModal(content, item) {

    this.itemBusca = cloneDeep(item);

    if (this.itemBusca.Fornecedor == 'OS') {

      this.itemBusca.Imagens = this.itemBusca.Imagens.map(x => {
        x = Util.pathImagemApi(x);
        return x;
      });

    }


    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg', backdrop: 'static' });
  }


}
