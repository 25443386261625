import { Component, OnInit , Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-estrela',
  templateUrl: './estrela.component.html',
  styleUrls: ['./estrela.component.scss'],
})
export class EstrelaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

 //estrelas: number[] = [1,2,3,4,5];
estrelas(): number[]{
  const array = [];
  for (let i = 1; i <= this.estrela; i++){
    array.push(i);
  }
  return array;
}

 @Input() estrela: number = 0;
 estrelaAnterior: number;

 @Output() selecionou = new EventEmitter<number>();

 setEstrelas(rate: number){
   this.estrela = rate;
   this.estrelaAnterior = undefined;

   this.selecionou.emit(this.estrela);
 }

 setEstrelaTemporaria(r: number){
   if (this.estrelaAnterior === undefined)
     this.estrelaAnterior = this.estrela;

   this.estrela = r;
 }

 limpaEstrelaTemporaria(){
   if (this.estrelaAnterior !== undefined){
     this.estrela = this.estrelaAnterior;
     this.estrelaAnterior = undefined;
   }
 }



}
