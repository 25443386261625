import { Agencia } from '../cadastro/agencia.model';

export class BloqueioAereo {
  public Id: number;
  public Descricao: string;
  public CiaValidadora: string;
  public Tarifa: number;
  public MoedaId: number;
  public Markup: any;
  public Taxa: number;
  public LocalizadorAereo: string;
  public AeroportoPartida: string;
  public AeroportoChegada: string;
  public DataIn: any;
  public DataOut: any;
  public PrazoEmissaoAte: any;
  public TotalAssentos: number;
  public Ativo: any;
  public Alteracoes: any;
  public Voos: BloqueioAereoVoo[];
  public Agencias : Agencia[];
}

export class BloqueioAereoVoo {
  public Id: number;
  public NumeroVoo: number;
  public AeroportoPartida: string;
  public AeroportoChegada: string;
  public DataPartida: any;
  public DataChegada: any;
  public Cia: string;
  public ClasseTarifaria: string;
  public ARUNK: any;
  public BloqueioAereoId : number;
}

export class BloqueioAereo_Agencia {
  public Agencia?: any = {NomeFantasia : ''};
}
