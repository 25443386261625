import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CidadeService, AeroportoService, CarroService } from 'src/app/services';
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: 'app-rebusca',
  templateUrl: './rebusca.component.html',
  styleUrls: ['./rebusca.component.scss']
})
export class RebuscaComponent implements OnInit {

  @Input() busca;
  @Input() tipoBusca;
  @Output() rebuscou = new EventEmitter<any>();

  hoje: any;
  rebusca = false;
  cidades = [];
  lojas = [];
  constructor(
    private _router: Router,
    public route: ActivatedRoute,
    private cidadeService: CidadeService,
    private carroService: CarroService,
    private aeroportoService: AeroportoService,
  ) { }

  ngOnInit() {

    var today = new Date()
    this.hoje = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

    this.busca.trechos = this.busca.trechos.map(x => {
      x.dataIda = moment(x.dataIda);
      x.dataVolta = moment(x.dataVolta);
      x.minDataIda = moment(x.minDataIda);
      x.minDataVolta = moment(x.minDataVolta);
      return x;
    });

    this.busca.dataOrigem = this.busca.dataOrigem ? moment(this.busca.dataOrigem) : this.busca.dataOrigem;
    this.busca.dataDevolucao = this.busca.dataDevolucao ? moment(this.busca.dataDevolucao) : this.busca.dataDevolucao;
  }

  aba() {
    if (this.route.snapshot.params.tipo != null) {
      return this.route.snapshot.params.tipo;
    } else {
      const x = this._router.url.split('/');
      return x[x.length - 1];
    }
  }

  timerCidade = null;
  buscaCidadeDebounce(val: string){
    clearTimeout(this.timerCidade);
    this.timerCidade = setTimeout(() => {
      this.buscaDados(val);
    }, 600);
   }

  buscaDados(val: string) {
    if (this.tipoBusca == 'lista-aereo') {
      this.aeroportoService.buscarPorParametro(val, 1).subscribe(
        dados => {
          this.cidades = dados.Items.map(x => {
            x.display_field = `${x.Iata} - ${x.Descricao} / ${x.Cidade.Nome} - ${x.Cidade.Estado.Iata} / ${x.Cidade.Estado.Pais.Iata}`;
            return x;
          });
        },
        erro => {
          console.log(erro);
          this.cidades = [];
        }
      );
    } else {
      this.cidadeService.buscarOrcamento(val, 1).subscribe(
        dados => {
          this.cidades = dados.Items.map(x => {
            x.display_field =
              x.Nome + " - " + x.Estado.Nome + " / " + x.Estado.Pais.Nome;
            return x;
          });
        },
        erro => {
          console.log(erro);
          this.cidades = [];
        }
      );
    }
  }

  selecionaCidadeOrigem(item, trecho) {
    if (this.tipoBusca == 'lista-aereo') {
      trecho.AeroportoPartida = item;
      trecho.cidadeOrigem = item.Cidade;
    } else {
      trecho.cidadeOrigem = item;
    }
  }

  selecionaCidadeDestino(item, trecho) {
    if (this.tipoBusca == 'lista-aereo') {
      trecho.AeroportoChegada = item;
      trecho.cidadeDestino = item.Cidade;
      trecho.cidadeDestino.display_field =
        trecho.cidadeDestino.Nome +
        " - " +
        trecho.cidadeDestino.Estado.Nome +
        " / " +
        trecho.cidadeDestino.Estado.Pais.Nome;
    } else {
      trecho.cidadeDestino = item;
    }
  }


  setDataIda(event, trechos, indice) {
    trechos[indice].dataIda = moment(event);
    trechos[indice].minDataVolta = moment(trechos[indice].dataIda).add(1, 'days');
    if (this.busca.nrnoites != null && this.busca.nrnoites != 0) {
      trechos[indice].dataVolta = moment(trechos[indice].dataIda).add(
        this.busca.nrnoites,
        'days'
      );
    }
    else {
      trechos[indice].dataVolta = trechos[indice].minDataVolta;
    }

    if (indice > 0) {
      trechos[indice].minDataIda = moment(trechos[indice].dataIda).add(1, 'days');
    }

    for (let i = indice + 1; i < trechos.length; i++) {
      if (i == 1) {
        trechos[i].minDataIda = moment(trechos[indice].dataIda).add(1, 'days');
      }

      if (trechos[i].minDataIda > trechos[i].dataIda) {
        trechos[i].dataIda = trechos[i].minDataIda;
      }
    }
    this.GetDateDiffIda(event, trechos, indice);
  }

  GetDateDiffIda(event, trechos, indice) {
    if (trechos[indice].dataVolta != null) {
      let diffdays = trechos[indice].dataVolta.diff(event, "days");
      this.busca.nrnoites = diffdays;
    }
  }

  buscou() {
    this.busca.trechos = this.busca.trechos.map(x => {
      x.dataIda = moment(x.dataIda);
      x.dataVolta = moment(x.dataVolta);
      x.minDataIda = moment(x.minDataIda);
      x.minDataVolta = moment(x.minDataVolta);
      return x;
    });
    this.rebuscou.emit(this.busca);
  }

  // onChangeNoites(event, trechos, indice) {
  //   trechos[indice].dataVolta = moment(trechos[indice].dataIda).add(
  //     this.busca.nrnoites,
  //     'days'
  //   );
  // }

  buscaLojasDebounce(val: string) {
      clearTimeout(this.timerCidade);
      this.timerCidade = setTimeout(() => {
          this.buscaLojas(val);
      }, 600);
  }

  buscaLojas(val: string) {
      this.carroService.buscarPorParametro(val, 1).subscribe(
          dados => {
              this.lojas = dados.Items.map(x => {
                  x.display_field = `${x.Sigla} - ${x.Nome} / ${x.Endereco}`;
                  return x;
              });
          },
          erro => {
              console.log(erro);
              this.lojas = [];
          }
      );
  }

  selecionaLojaOrigem(item) {
      this.busca.localOrigem = item;
  }

  selecionaLojaDevolucao(item) {
      this.busca.localDevolucao = item;
  }

  setDataDevolucao(event) {
      this.busca.dataDevolucao = event.startOf('day');
      if (moment(this.busca.dataOrigem) > moment(this.busca.dataDevolucao)) {
          this.busca.dataOrigem = moment(this.busca.dataDevolucao).subtract(1, 'days');
      }
  }

  setDataOrigem(event) {
      this.busca.dataOrigem = event.startOf('day');
      this.busca.dataDevolucao = moment(this.busca.dataOrigem).add(1, 'days');;
  }

}
