import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Paginacao, Usuario } from 'src/app/model';
import { LoginService, AgenciaService, Util } from 'src/app/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  mensagemErro = '';
  parametroBusca = '';
  mensagemSucesso = '';
  parametroId: any = '';
  carregarPacotes: boolean;
  agente: boolean;
  nomeAgencia: string;
  agencia: any;
  dados = [];
  Logo: string = '';
  paginacao: Paginacao = new Paginacao();
  @Output() deslogou = new EventEmitter<any>();
  @Input() usuario: Usuario;
  // usuario: any;

  constructor(
    private loginService: LoginService,
    private agenciaService: AgenciaService,
  ) { }

  ngOnInit() {
    this.buscar();

    let agencia = localStorage.getItem('g8h7d8')
    if (agencia != (null || undefined)) {
      agencia = JSON.parse(atob(agencia));
      this.agencia = agencia;
      this.nomeAgencia = this.agencia.NomeFantasia;
      this.Logo = Util.pathImagemApi(this.agencia.Logo);
    } else if (this.usuario.Agencia != (null || undefined)) {
      this.nomeAgencia = this.usuario.Agencia.NomeFantasia;
      this.Logo = Util.pathImagemApi(this.usuario.Agencia.Logo);
    } else {
      this.nomeAgencia = 'Selecione uma agência'
    }

    // this.usuario = localStorage.getItem('loggedUser')
    // if (this.usuario != (null || undefined)) {
    //   this.usuario = JSON.parse(localStorage.getItem('loggedUser'));
    // }


    this.loadPortalConfig();
  }

  ngDoCheck() {
    if ((window.localStorage.getItem('g8h7d8') && !this.agencia) || (window.localStorage.getItem('g8h7d8') && this.agencia && (this.agencia != JSON.parse(atob(window.localStorage.getItem('g8h7d8')))))) {
      const agencia = JSON.parse(atob(window.localStorage.getItem('g8h7d8')));
      this.agencia = agencia;
      if (this.usuario.Agencia != (null || undefined)) {
        this.nomeAgencia = this.usuario.Agencia.NomeFantasia
        this.Logo = Util.pathImagemApi(this.usuario.Agencia.Logo);
      } else {
        this.nomeAgencia = 'Selecione uma agência'
      }
    }
    // if (this.agencia == null || undefined) {
    //   this.nomeAgencia = 'Selecione Agência'
    // } else {
    //   this.nomeAgencia = this.agencia.NomeFantasia
    // }
  }

  buscar() {
    this.mensagemErro = '';
    this.mensagemSucesso = '';
    let busca = this.agenciaService.buscarPorParametro(this.parametroBusca, this.paginacao.NumeroPagina);
    if (this.parametroId != undefined && this.parametroId != '') {
      this.paginacao.NumeroPagina = 1;
      busca = this.agenciaService.buscarPorId(this.parametroId);
    }
    busca.subscribe(
      dados => {
        this.dados = dados.Items;
        this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacao.TotalItens = dados.MetaData.TotalItens;
        this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
        this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
      },
      erro => {
        this.dados = [];
        console.log('Erro ao Buscar');
        console.log(erro);
        this.mensagemErro = this.errorHandler(erro, 'Buscar agências');
      },
    );
  }

  limpar() {
    this.agencia = null;
    localStorage.removeItem('g8h7d8');
    this.Logo = '';
    this.nomeAgencia = 'Selecione uma agência';
    this.mensagemSucesso = 'Agência Removida com Sucesso';
  }

  trocarAgencia(agencia) {
    this.agencia = agencia;
    this.Logo = Util.pathImagemApi(this.agencia.Logo);
    this.agencia.Empresa = [];
    this.agencia.Cidade = [];
    this.agencia.PerfilMarkup = [];
    this.agencia.Comercial = [];

    localStorage.setItem('g8h7d8', btoa(JSON.stringify(agencia)));
  }

  removeuAgencia() {
    this.agencia = null;
  }

  togglePct() {
    this.carregarPacotes = !this.carregarPacotes;
    (this.carregarPacotes ? localStorage.setItem('pct', 'true') : localStorage.setItem('pct', 'false'))
  }

  loadPortalConfig() {
    const config = localStorage.getItem('pct');
    if (config == null || undefined) {
      localStorage.setItem('pct', 'true');
    } else if (config == 'true') {
      this.carregarPacotes = true
    } else {
      this.carregarPacotes = false
    }
  }

  public errorHandler(erro, acao: string = null): string {

    let mensagem = 'Erro';
    if (acao !== null) {
      mensagem += ' ao tentar ' + acao + ': ';
    }
    switch (erro.status) {
      case 0: mensagem += 'o servidor não respondeu, tente novamente mais tarde!'; break;
      case 401: mensagem += 'você não tem autorização para executar esta ação!'; break;
      case 404: mensagem += erro.error.mensagem; break;
      case 406: mensagem += 'a requisição não foi aceita, tente novamente!'; break;
      case 500: mensagem += 'Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'; break;
      default: mensagem += erro.statusText;
    }

    return mensagem;

  }
  senhaAlterada(dados) {
    this.logout();
  }
  logout() {
    this.loginService.logout();
    window.location.reload();
  }
  codifica(a) {
    atob(a);
  }

  decodifica(a) {
    btoa(a);
  }

}
