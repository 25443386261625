import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { Pacote, Busca, Paginacao, Quarto, Orcamento, Aeroporto } from 'src/app/model';
import { AeroportoService, CidadeService, LoginService,Util } from 'src/app/services';
import { PacotelstService } from 'src/app/services/turismo/pacotelst.service';

@Component({
  selector: 'app-pacote',
  templateUrl: './pacote.component.html',
  styleUrls: ['./pacote.component.scss']
})
export class PacoteComponent implements OnInit {
  @Output() pacote;
  @Input() Orcamento: Pacote[] = [];
  @Input() events: Observable<void>;
  @Input() busca: Busca;
  @Output() selecionou = new EventEmitter<any>();
  @Output() selecionouPacote = new EventEmitter<any>();
  @Output() avancar = new EventEmitter<any>();
  @Output() removeu = new EventEmitter<any>();

  private eventsSubscription: Subscription;

  paginacao: Paginacao = new Paginacao();
  quarto: Quarto = new Quarto();
  Pacote = new Pacote();
  pacotesLst = [];
  cidades = [];
  erros = [];
  dataAtual: any;
  noitesAdd = false;
  semAgencia = false;
  adicionar = false;
  usuarioLogado = null;
  timerCidade = null;
  usuario = null;
  id = null;
  loading: boolean;
  cardLoadded: boolean;
  objeto: any;
  dataCheckin: any;
  dataCheckout: any;
  disable: boolean;
  disable1: boolean;
  Data1: any;
  Data2: any;

  dadosPacote: [] = [];

  @ViewChild('tarifarPacote') openModal: ElementRef;

  parametroBusca: string = '';

  typeIcon = [

  ]

  listaIcons = [
    { title: 'Todos', icon: "far fa-heart", value: true },
    { title: 'Internacional', icon: "fas fa-globe", value: false },
    { title: 'Romance', icon: "fas fa-heart", value: false },
    { title: 'Lazer', icon: "fas fa-umbrella-beach", value: false },
    { title: 'Luxo', icon: "fas fa-gem", value: false },
    { title: 'Aventura', icon: "fas fa-hiking", value: false },
    { title: 'Feriados', icon: "fas fa-calendar-day", value: false },
    { title: 'Experiência', icon: "fas fa-star", value: false },
    { title: 'Diversão', icon: "fas fa-laugh-squint", value: false },
    { title: 'Cruzeiro', icon: "fas fa-ship", value: false },
    { title: 'All Inclusive', icon: "fas fa-gifts", value: false },
    { title: 'Religioso', icon: "fas fa-pray", value: false },
  ]


  constructor(
    private modalService: NgbModal,
    public router: Router,
    private pacotelstservice: PacotelstService,
    private loginService: LoginService,
    public aeroportoService: AeroportoService,
    public cidadeService: CidadeService,

  ) { }

  ngOnInit() {
    this.cardLoadded = false;
    this.loading = true
    this.dataAtual = moment().format('YYYY-MM-DD');

    this.usuarioLogado = this.loginService.user();
    this.busca.tipoBusca.hotel = true;
    this.buscarPacotesLst();

    // this.eventsSubscription = this.events.subscribe((busca) => this.refazerBusca(busca));

    this.usuario = this.loginService.user();
    this.busca.usuario = this.usuario;
  }

  ngAfterViewInit() {
    const busca = window.sessionStorage.getItem('busca');
    if (busca !== null) {
      this.busca = this.busca.decodeBusca(busca);
    }

    this.busca.trechos[0].minDataIda = moment().add(1, 'day').toDate();
  }

  abrirModal(content, pacote){    
    this.dadosPacote = pacote;
    console.log(this.dadosPacote);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true, size: 'sm' })
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  carregar(obj) {
    this.dataCheckin = null;
    this.dataCheckout = null;
    this.cardLoadded = true;
    this.Pacote = obj;
    this.disable = false;
    sessionStorage.clear();
    this.objeto = obj;
    this.dataCheckin = moment(this.objeto.Checkin).format('YYYY-MM-DD');
    this.dataCheckout = moment(this.objeto.Checkout).format('YYYY-MM-DD');
    this.disable = true;
    setTimeout(() => {
      this.openModal.nativeElement.click();
    }, 10);
  }

  buscarPacotesLst() {
    this.pacotelstservice.buscarPacotes(this.parametroBusca,this.Data1,this.Data2,this.paginacao.NumeroPagina).subscribe(
      dados => {
        this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacao.TotalItens = dados.MetaData.TotalItens;
        this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
        this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
        this.pacotesLst = dados.Items;

        this.pacotesLst = this.pacotesLst.map(x => {
          x.PacoteImagens.map(y => {
            y.Imagem = Util.pathImagemApi(y.Imagem);
            return y;
          });
          return x;
        })

        this.loading = false;
      },
      erro => console.log(erro),
    );
  }

  BuscaPacotes() {
    this.semAgencia = false;
    this.busca.primeira = true;
    this.busca.novoOrcamento = true;
    this.busca.orcamentoId = null;
    this.busca.PacoteId = this.Pacote.Id;
    if (this.Pacote.BloqueioAereo.length > 0) {
      this.busca.trechos[0].AeroportoPartida = this.Pacote.BloqueioAereo[0].AeroportoPartida;
      this.busca.trechos[0].AeroportoChegada = this.Pacote.BloqueioAereo[0].AeroportoChegada;
    }
    this.busca.trechos[0].cidadeDestino.Id = this.Pacote.Cidade.Id;
    this.busca.trechos[0].cidadeDestino.Nome = this.Pacote.Cidade.Nome;

    this.busca.tipoBusca.aereo = false;
    this.busca.tipoBusca.carro = false;
    this.busca.tipoBusca.seguro = false;
    this.busca.tipoBusca.ticket = false;
    this.busca.tipoBusca.transfer = false;
    this.busca.trechos[0].dataIda = this.dataCheckin;
    this.busca.trechos[0].dataVolta = this.dataCheckout;

    if (this.usuario.AgenciaId != null) {
      this.busca.AgenciaId = this.usuario.AgenciaId;
    } else {
      let agencia = window.localStorage.getItem("g8h7d8");
      if (agencia !== null) {
        const agenciaID = JSON.parse(atob(agencia));
        this.busca.AgenciaId = agenciaID.Id;
      } else {
        alert("Selecione uma agência!")
      }
    }
    if (!this.busca.AgenciaId || this.busca.AgenciaId == 0) {
      this.semAgencia = true;
      setTimeout(() => (this.semAgencia = false), 5000);
      return null;
    }

    sessionStorage.removeItem('busca');
    sessionStorage.setItem('busca', JSON.stringify(this.busca));

    this.router.navigate([`lista-pacote/pacote-view/`]);
  }

  buscarPagina(pagina) {
    this.paginacao.NumeroPagina = pagina;
    this.buscarPacotesLst();
  }

  refazerBusca() {
    //this.busca = busca;
    this.paginacao.NumeroPagina = 1;
    this.buscarPacotesLst();
  }


  incrementaQuartos() {
    this.busca.quartos.push(new Quarto());
  }

  decrementaQuartos() {
    this.busca.quartos.pop();
  }

  noitesAdicionais() {
    this.noitesAdd = !this.noitesAdd;
  }

  selecionarPacote(pacote) {
    pacote.selecionado = true;
    // this.Orcamento.push(pacote);
    // this.selecionou.emit(pacote);
    this.BuscaPacotes();
  }

  pacoteSelecionado(pacote) {
    const dado = this.Orcamento.filter(x => x.Id === pacote.Id);
    return dado.length > 0;
  }

  removerPacote(pacote) {
    pacote.selecionado = false;
    let indice = -1;
    for (let i = 0; i < this.Orcamento.length; i++) {
      if (this.Orcamento[i].Id === pacote.Id) {
        indice = i;
      }
    }
    if (indice >= 0) {
      this.Orcamento.splice(indice, 1);
      this.removeu.emit(pacote);
    }
  }

  setDataIda(event, trechos, indice) {
    this.busca.nrnoites = this.Pacote.MinimoDeNoites;
    trechos[indice].minDataVolta = moment(trechos[indice].dataIda).add(1, 'days');
    if (this.busca.nrnoites !== null && this.busca.nrnoites !== 0) {
      trechos[indice].dataVolta = moment(trechos[indice].dataIda).add(
        this.busca.nrnoites,
        'days'
      );
    } else {
      trechos[indice].dataVolta = trechos[indice].minDataVolta;
    }

    if (indice > 0) {
      trechos[indice].minDataIda = moment(trechos[indice].dataIda).add(1, 'days');
    }

    for (let i = indice + 1; i < trechos.length; i++) {
      if (i === 1) {
        trechos[i].minDataIda = moment(trechos[indice].dataIda).add(1, 'days');
      }

      if (trechos[i].minDataIda > trechos[i].dataIda) {
        trechos[i].dataIda = trechos[i].minDataIda;
      }
    }
    this.GetDateDiffIda(event, trechos, indice);
  }

  onChangeNoites(event, trechos, indice) {
    trechos[indice].dataVolta = moment(trechos[indice].dataIda).add(
      this.busca.nrnoites,
      'days'
    );
  }

  GetDateDiffIda(event, trechos, indice) {
    if (trechos[indice].dataVolta != null) {
      const diffdays = trechos[indice].dataVolta.diff(event, 'days');
      this.busca.nrnoites = diffdays;
    }
  }

  onChangeVolta(event, trechos, indice) {
    this.GetDateDiffVolta(event, trechos, indice);
  }

  GetDateDiffVolta(event, trechos, indice) {
    if (trechos[indice].dataVolta != null) {
      event = moment(event);
      const diffdays = event.diff(trechos[indice].dataIda, 'days');
      this.busca.nrnoites = diffdays;
    }
  }

}
