import { Cidade } from '../endereco.model';

export class ServicoOffline{

  public Id: number;
  public Titulo: string;
  public Descricao: string;
  public Destaque: any;
  public Ativo: any;
  public DiasSemana: string;
  public DataUnica: any;
  public PrazoCancelamento: number;
  public PoliticaCancelamento: string;
  public OfflineServicoFornecedorId: number;
  public OfflineServicoFornecedor : ServicoOfflineFornecedor = new ServicoOfflineFornecedor();
  public Tipo: any;
  public Categoria: any;
  public Alteracoes: any;
  public CidadesVinculas: Cidade[];
  public Imagens: OfflineServicoImagens[];
  public Tarifas : ServicoOfflineTarifa[];
  public Perguntas : ServicoOfflinePergunta[];

}

export class ServicoOfflineFornecedor{
  public Id: number;
  public Cidade: Cidade = new Cidade();

  public RazaoSocial: String;
  public Nome: String;
  public CNPJ: String;
  public WebSite: String;
  public Email: String;
  public EmailReserva: String;
  public Telefone: String;
  public ERPId: any;
  public TemIRRF : any;
  public Servicos: ServicoOffline[];
  public MoedaId: number;
  public Cep: any;
  public Logradouro: any;
  public Numero: any;
  public Bairro: any;
  public Complemento: any;
  public Ativo: any;
  public Alteracoes: any;
}

export class OfflineServicoImagens {
  public Id: number ;
  public OfflineServicoId: number;
  public URL: string;
}

export class OfflineServico_Cidade {
  public Cidade?: any = {Nome : ''};
}

export class ServicoOfflineTarifa {
  public Id : number;
  public OfflineServicoId: number;
  public De : any;
  public Ate : any;
  public ValidadeDe : any;
  public ValidadeAte : any;
  public Ativo : any;
  public TarifaFaixasEtarias : ServicoOfflineTarifaFaixaEtaria[];
}

export class ServicoOfflineTarifaFaixaEtaria {
  public Id : number;
  public OfflineServicoTarifaId : number;
  public Tipo : string;
  public IdadeMinima : number;
  public IdadeMaxima : number;
  public QuantidadeMinima : number;
  public QuantidadeMaxima : number;
  public Valor : number;
}

export class ServicoOfflinePergunta {
  public Id : number;
  public Pergunta : String;
  public Tipo : number;
  public Opcoes : ServicoOfflinePerguntaOpcao[];
  public Obrigatorio : any;
  public OfflineServicoId : number;
}

export class ServicoOfflinePerguntaOpcao {
  public Id : number;
  public Opcao : String;
  public OfflineServicoPerguntaId : number;
}
