import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {
  Location
} from "@angular/common";
import {
  Router,
  ActivatedRoute
} from "@angular/router";
import {
  HotelService,
  ReservaService,
  AereoService,
  Util
} from "src/app/services";
import {
  ORCAMENTO,
  BUSCA
} from "../lista-pacote.component";
import {
  Observable,
  Subject
} from "rxjs";
import {
  Orcamento,
  Busca,
  FiltroHotel,
  Hotel,
  Paginacao,
  Aereo,
  FiltroAereo,
  PacoteReserva
} from "src/app/model";
import {
  PacotelstService
} from 'src/app/services/turismo/pacotelst.service';
import {
  Options
} from "@angular-slider/ngx-slider";
import * as moment from "moment";
@Component({
  selector: "app-pacote-view",
  templateUrl: "./pacote-view.component.html",
  styleUrls: ["./pacote-view.component.scss"],
})
export class PacoteViewComponent implements OnInit {
  constructor(
    private _location: Location,
    public _router: Router,
    public route: ActivatedRoute,
    private aereoService: AereoService,
    public reservaService: ReservaService,
    private hotelService: HotelService,
    private pacoteService: PacotelstService,

  ) {}

  @Output() selecionou = new EventEmitter < any > ();
  @Output() removeu = new EventEmitter < any > ();
  @Output() avancar = new EventEmitter < any > ();
  @Input() Orcamento: PacoteReserva[] = [];
  @Input() events: Observable < void > ;
  @Input() hotel: any;

  @ViewChild('roomOptions') openModal: ElementRef;

  buscaInStorage = sessionStorage.getItem('busca');
  buscaParsed = JSON.parse(this.buscaInStorage);
  objeto: any;
  // @Input() busca: Busca;
  aereos: Aereo[] = [];
  bloqueios: Aereo[] = [];
  FiltroAereo: FiltroAereo = new FiltroAereo();
  busca: Busca = new Busca();
  totalpax = 0;
  Cias: String[] = [];
  valorTotalPacote: any;
  OpcoesDuracaoTrechos: any[];
  pacoteReserva: any = new PacoteReserva();
  eventsSubject: Subject < void > = new Subject < void > ();
  FiltroHotel: FiltroHotel = new FiltroHotel();
  paginacao: Paginacao = new Paginacao();
  Fornecedores: String[] = [];
  hoteis: Hotel[] = [];
  listagemhoteis: Hotel[] = [];
  buscasAtivas = [];
  integradores = [];
  cidades = [];
  nome_Hotel_Search: any;
  itemBusca: any = null;
  progress: any;
  pacoteId: any;
  qntTrechos = 0;
  usuario = null;
  hotelCardLoadded = false;
  carregando: boolean;
  buscandohotel = false;
  rebusca = false;
  indiceAtual = 0;
  contador = 0;
  rating = 5;
  mensagemErro: String;
  guid = "";
  Voos: any[] = [];

  OpcoesValor: Options = {
    floor: 0,
    ceil: 99999,
    getPointerColor: (value: number): string => {
      return "#fff";
    },
    getSelectionBarColor: (value: number): string => {
      return "#fff";
    },
  };

  OpcoesClassificacao: Options = {
    floor: 1,
    ceil: 5,
    getSelectionBarColor: (value: number): string => {
      return "#fff";
    },
    getPointerColor: (value: number): string => {
      return "#fff";
    },
    animate: false,
  };

  @ViewChild("closeModal") closeModal;

  ngOnInit() {
    const busca = window.sessionStorage.getItem(BUSCA);
    this.busca = this.busca.decodeBusca(busca);
    this.busca.trechos = this.busca.trechos.map((x) => {
      x.dataIda = moment(x.dataIda);
      x.minDataIda = moment(x.minDataIda);
      x.dataVolta = moment(x.dataVolta);
      x.minDataVolta = moment(x.minDataVolta);
      return x;
    });
    this.buscar();
  }

  aba() {
    if (this.route.snapshot.params.tipo != null) {
      return this.route.snapshot.params.tipo;
    } else {
      let x = this._router.url.split("/");

      return x[x.length - 1];
    }
    return "";
  }

  buscar() {
    this.carregando = true;
    // console.log(this.busca);
    // const buscaPacote = this.busca.getBuscaServico();
    const buscaPacote = this.busca.getBuscaHotel();
    // console.log(buscaPacote);
    this.pacoteService.buscar(buscaPacote).subscribe(
      dados => {
        this.pacoteReserva = dados;
        console.log(dados);
        if (this.pacoteReserva.Hoteis.length > 0) {
          for (let i = 0; i < this.pacoteReserva.Hoteis.length; i++) {
            this.hoteis = this.pacoteReserva.Hoteis[i].Hoteis;
            this.hoteis = this.hoteis.map(x => {
              x.Id = x.Id * -1;
              const opcoes = x.Quartos.map(q => q.Pesquisa)
                .filter(this.distinct)
                .sort();
              x.opcoes = [];
              for (let i = 0; i < opcoes.length; i++) {
                x.opcoes.push(x.Quartos.filter(x => x.Pesquisa === opcoes[i]));
              }
              x.Quartos = [];
              for (let i = 0; i < opcoes.length; i++) {
                x.Quartos.push(x.opcoes[i][0]);
              }
              return x;
            });
            this.listagemhoteis.push(this.hoteis[0]);
          }

        }
        this.pacoteReserva.Pacote.PacoteImagens = this.pacoteReserva.Pacote.PacoteImagens.map(x => {
          x.Imagem = Util.pathImagemApi(x.Imagem);
          return x;
        })
        this.carregando = false;
        window.sessionStorage.setItem("busca", JSON.stringify(this.busca));
        console.log(this.mensagemErro);
        this.selecionarHotel(this.listagemhoteis[0]);
      },
      erro => {
        console.log(erro);
        console.log(this.mensagemErro);
        this.mensagemErro = "Não foi encontrada tarifa para essa combinação de pessoas !";
      },
    );
  }

  somaTotal(arr) {
    return arr
      .map(x => x.ValorListagem)
      // tslint:disable: only-arrow-functions
      .reduce(function (a: any, b: any) {
        return a + b;
      }, 0);
  }

  distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  carregar(obj) {
    console.log(obj);
    this.hotelCardLoadded = true;

    this.objeto = obj;
    setTimeout(() => {
      this.openModal.nativeElement.click();
    }, 100);
  }

  // selecionarHotel(objeto) {

  //     this.pacoteReserva.Hoteis[0] = objeto;
  //     var jsonHotel = JSON.stringify(this.hoteis);
  //     this.pacoteReserva.Hoteis[0].Hoteis = JSON.parse(jsonHotel);
  //     let hotel = this.pacoteReserva.Hoteis[0];
  //     let ValorListagemHotel = hotel.Quartos.map(x => x.ValorListagem).reduce(function (a, b) {
  //         return a + b;
  //     }, 0);
  //     let ValorFechamentoHotel = hotel.Quartos.map(x => x.ValorFechamento).reduce(function (a, b) {
  //         return a + b;
  //     }, 0);
  //     let ValorVendaHotel = hotel.Quartos.map(x => x.ValorVenda).reduce(function (a, b) {
  //         return a + b;
  //     }, 0);
  //     this.pacoteReserva.ValorListagem = this.pacoteReserva.ValorListagemSemHotel + ValorListagemHotel;
  //     this.pacoteReserva.ValorFechamento = this.pacoteReserva.ValorFechamentoSemHotel + ValorFechamentoHotel;
  //     this.pacoteReserva.ValorVenda = this.pacoteReserva.ValorVendaSemHotel + ValorVendaHotel;
  //     console.log(this.pacoteReserva);
  // }
  selecionarHotel(objeto) {
    this.pacoteReserva.Hoteis = [];
    this.pacoteReserva.Hoteis[0] = objeto;
    var jsonHotel = JSON.stringify(this.listagemhoteis.filter(x => x.CodigoFornecedor == objeto.CodigoFornecedor));
    this.pacoteReserva.Hoteis[0].Hoteis = JSON.parse(jsonHotel);
    let hotel = this.pacoteReserva.Hoteis[0];
    let ValorListagemHotel = hotel.Quartos.map(x => x.ValorListagem).reduce(function (a, b) {
      return a + b;
    }, 0);
    let ValorFechamentoHotel = hotel.Quartos.map(x => x.ValorFechamento).reduce(function (a, b) {
      return a + b;
    }, 0);
    let ValorVendaHotel = hotel.Quartos.map(x => x.ValorVenda).reduce(function (a, b) {
      return a + b;
    }, 0);
    this.pacoteReserva.ValorListagem = this.pacoteReserva.ValorListagemSemHotel + ValorListagemHotel;
    this.pacoteReserva.ValorFechamento = this.pacoteReserva.ValorFechamentoSemHotel + ValorFechamentoHotel;
    this.pacoteReserva.ValorVenda = this.pacoteReserva.ValorVendaSemHotel + ValorVendaHotel;
    console.log(this.pacoteReserva);
  }
  voltar() {
    this._location.back();
  }

  avancarx() {
    //  sessionStorage.setItem("orcamentousuario", JSON.stringify(this.Orcamento));
    // console.log(this.Orcamento);
    while (this.Orcamento.length > 0) {
      this.Orcamento.pop();
    }
    this.Orcamento.push(this.pacoteReserva);
    this.selecionou.emit(this.pacoteReserva);
    this._router.navigate([`listas/orcamento`]);
  }
}
