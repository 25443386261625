import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { ReservaPagamento } from 'src/app/model';
import { ReservaService } from 'src/app/services';

@Component({
  selector: 'app-estorno-pagamento',
  templateUrl: './estorno-pagamento.component.html',
  styleUrls: ['./estorno-pagamento.component.scss']
})
export class EstornoPagamentoComponent implements OnInit {

  constructor(private reservaService: ReservaService) { }

    ngOnInit() {
    }


    @Output() alterou = new EventEmitter<any>();

    @Output() fechou = new EventEmitter<any>();

    @Input()
    pagamento: ReservaPagamento;
    carregando = false;

    mensagensErro : any[];
    mensagemSucesso : string;

    estornar(){

      delete this.mensagensErro;
      delete this.mensagemSucesso;
      //this.carregando = true;
      this.reservaService.estornarPagamento(this.pagamento.Id).subscribe(
        dados => {
          this.pagamento.Status = dados.Status;
          this.mensagemSucesso = "Estorno efetivado!";
        //  this.carregando = false;
          this.alterou.emit(this.pagamento);
        }, erro => {
          this.mensagensErro = this.errorHandler(erro);

        })
    }

    public errorHandler(erro): string[]{
      console.log(erro);

      let mensagem = [];
      switch (erro.status){
        case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
        case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
        case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
        case 404: mensagem.push("Solicitação inválida!" ); break;
        case 406: mensagem.push(erro.error.mensagem ); break;
        // case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ); break;
        case 500: mensagem.push(erro.error.ExceptionMessage ); break;
        default : mensagem.push(erro.statusText);
      }
      if (erro.error != null && erro.error.mensagens != null){
        mensagem = [...mensagem, ...erro.error.mensagens];
      }
      return mensagem;

    }

}
