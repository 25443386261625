import { Component, OnInit } from '@angular/core';
import { VoucherService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit {

  voucher = null;
  erros = null;
  carregando = true;

  constructor(private voucherService: VoucherService,
    public route: ActivatedRoute) { }



  ngOnInit() {

    let key = this.route.snapshot.params['key'];
    if (key !== undefined) {
      setTimeout(() => { this.carregarObjeto(key); }, 500);
    }

  }

  salvar(){
    window.open(this.voucher, "_blank");
  }

  carregarObjeto(key){

    // this.voucher = this.voucherService.exibirVoucherUrl(key);

    this.voucherService.exibirVoucher(key).subscribe(
      dados => {
        this.voucher = URL.createObjectURL(dados);
      },
      erros => {
        this.erros = erros;
      }
    )
  }


  pageRendered(e: CustomEvent) {
    this.carregando = false;
  }

  onError(event){
    console.log("ERRO AO CARREGAR O PDF:");
    console.log(event);
  }

  imprimir(){
    window.open(this.voucher);
  }

}
