import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Busca } from 'src/app/model';
import { HotelService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss']
})
export class HotelComponent implements OnInit {
  @Input() hotel: any;
  @Input() usuario: any;
  @Input() buscando: any;
  @Input() selecionar = true;
  @Output() avancar = new EventEmitter<any>();
  @Output() selecionouHotel = new EventEmitter<any>();
  @Output() removeuHotel = new EventEmitter<any>();

  @ViewChild('roomOptions') openModal:ElementRef; 

  hotelCardLoadded = false;
  carregando = false;
  checked = false;

  busca: Busca;
  NumeroNoites = 0;

  Arr = Array;
  dif: number;
  user: any;
  orcamentoPage: any;
  objeto: any;
  itemBusca : any;


  constructor(
    private hotelService: HotelService,
    public router: Router,
    private modalService: NgbModal) { }

  ngOnInit() {
    (window.location.pathname == '/listas/orcamento' ? this.orcamentoPage = true : this.orcamentoPage = false);
    this.user = JSON.parse(localStorage.getItem('loggedUser'));
    this.dif = Math.floor(this.hotel.Estrelas);
    this.busca = JSON.parse(sessionStorage.getItem('busca'));
      this.NumeroNoites = this.noites(this.busca.trechos[0].dataIda,this.busca.trechos[0].dataVolta);
  }

  selecionou(hotel: any) {
    this.selecionouHotel.emit(hotel);
  }
  selecionaQuarto(hotel, Agrupador) {
    console.log(this.objeto)
    if (Agrupador >= 0)
      for (let i = 0; i < hotel.Quartos.length; i++) {
          hotel.Quartos[i] = hotel.opcoes[i].find(x => x.Agrupador == Agrupador);
      }
  }

  pensoesIguais(){
    if (this.objeto.MesmaPensao) {
      if (this.objeto.Quartos.length <= 1) {
        return true;
      }
      // Verifica se as pensões em todos os quartos são iguais
      const primeiraPensao = this.objeto.Quartos[0].CodigoPensao;
      return this.objeto.Quartos.every((quartos: any) => quartos.CodigoPensao === primeiraPensao);
    }

    return true;
  }

  noites(dataInicio, dataFim) {

    dataInicio = moment(dataInicio).startOf('day');
    dataFim = moment(dataFim).startOf('day');

    return dataFim.diff(dataInicio, 'days');
  }
  rotaAvancar() {
    this.avancar.emit();
  }

  removeu(hotel: any) {
    this.removeuHotel.emit(hotel);
  }

  carregar(obj) {
    this.hotelCardLoadded = true;
    this.objeto = obj;
    setTimeout(() => {
      this.openModal.nativeElement.click();
    }, 100);
  }

  somaTotal(arr) {
    return arr
      .map(x => x.ValorListagem)
      // tslint:disable: only-arrow-functions
      .reduce(function(a: any, b: any) {
        return a + b;
      }, 0);
  }

  GetHotelDetalhes() {
    this.carregando = true;
    this.hotelService.detalhesHotel(this.hotel).subscribe(
      dados => {
        this.hotel = dados;
        this.carregando = false;
      }, erro => {
        this.carregando = false;
      });
  }

  abrirModal(content, item) {
    this.itemBusca = item;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
  }
}
