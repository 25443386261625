const producao = false;

var api = '';
var apihotel = '';
var apiaereo = '';


(producao ? api = 'https://api.masteropoperadora.com' : api = "https://apitestemasterop.geniusws.com.br");
(producao ? apihotel = 'https://apihotel.masteropoperadora.com' : apihotel = "https://apitestemasterop.geniusws.com.br");
(producao ? apiaereo = 'https://apiaereo.masteropoperadora.com' : apiaereo = "https://apitestemasterop.geniusws.com.br");

export const API = api;

export const CLIENT_ID = 'postman:123';
export const ITENSPORPAGINA = 20;
export const ITENSORCAMENTO = 20;

export const EMPRESAS = API;

export const GERENCIAMENTO = API;

export const TURISMO = API;
export const TURISMO_AEREO = apiaereo;
export const TURISMO_HOTEL = apihotel;
export const TURISMO_TICKET = API;
export const TURISMO_TRANSFER = API;
export const TURISMO_SEGURO = API;
export const TURISMO_CARRO = API;

// export const MERCADO_PAGO = "APP_USR-d7d5fc40-f288-44ba-ab37-454a39b7837f";
export const MERCADO_PAGO = "APP_USR-a7a79d6f-4f4e-4cf0-8fe5-b7cc4400b811";
