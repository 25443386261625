import { Seguro } from './seguro.model';
import { Aereo } from './aereo.model';
import { Carro } from './carro.model';
import { Hotel } from './hotel.model';
import { Pacote,PacoteReserva } from './pacote.model';
import { Servico } from './servico.model';

export class Orcamento {
  Id = 0;
  public Aereos: Aereo[] = [];
  public Hoteis: Hotel[] = [];
  public Servicos: Servico[] = [];
  public Carros: Carro[] = [];
  public Pacotes: Pacote[] = [];
  public Seguros: Seguro[] = [];
  public PacoteReservas: PacoteReserva[] = [];

}
