import { Reserva } from '../turismo/reserva.model';

export class TeleVendasPagamento {
  public Id: number;
  public WebService?: any;
  public WebServiceId: number;
  public Nome: string;
  public CPF : string;
  public Referencia : string;
  public NumeroCartao : string;
  public ValidadeCartao : string;
  public CodigoCartao : string;
  public Bandeira : string;
  public Valor : number;
  public Parcelas : number;
  public TemOrcamento : any;
  public Reserva?: Reserva;
  public ReservaId: number;
  public Status : string;
}
