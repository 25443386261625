import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./views/home/home.component";
import { ListaAereoComponent } from "./views/listas/lista-aereo/lista-aereo.component";
import { ListaCarroComponent } from "./views/listas/lista-carro/lista-carro.component";
import { ListaHotelComponent } from "./views/listas/lista-hotel/lista-hotel.component";
import { ListaServicoComponent } from "./views/listas/lista-servico/lista-servico.component";
import { ListasComponent } from "./views/listas/listas.component";
import { ShowsComponent } from "./views/shows/shows.component";
import { PreOrcamentoComponent } from "./views/listas/pre-orcamento/pre-orcamento.component";
import { ListaSeguroComponent } from "./views/listas/lista-seguro/lista-seguro.component";
import { PacoteViewComponent } from "./views/listas/lista-pacote/pacote-view/pacote-view.component";
import { ListaPacoteComponent } from "./views/listas/lista-pacote/lista-pacote.component";
import { AreaTesteComponent } from "./views/area-teste/area-teste.component";
import { OrcamentoComponent } from "./views/orcamento/orcamento.component";
import { MeusOrcamentosComponent } from "./views/meus-orcamentos/meus-orcamentos.component";
import { VoucherComponent } from './voucher/voucher.component';
import { OrcamentoDownloadComponent } from './orcamentodownload/orcamentodownload.component'
import { FinanciamentosComponent } from "./components/financiamentos/financiamentos.component";
import { FinanciamentoFormComponent } from "./components/financiamentos/financiamento-form/financiamento-form.component";
import { Pacote } from "./model/turismo/pacote.model";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "shows", component: ShowsComponent },
  {
    path: "listas",
    component: ListasComponent,

    children: [
      { path: "lista-aereo", component: ListaAereoComponent },
      { path: "lista-hotel", component: ListaHotelComponent },
      { path: "lista-servico", component: ListaServicoComponent },
      { path: "lista-carro", component: ListaCarroComponent },
      { path: "lista-seguro", component: ListaSeguroComponent },
      { path: "orcamento", component: PreOrcamentoComponent },
    ],
  },
  {
    path: "lista-pacote",
    component: ListaPacoteComponent,
    children: [
      { path: "pacotes", component: Pacote },
      { path: "pacote-view", component: PacoteViewComponent },
    ],
  },
  { path: "pre-orcamento", component: PreOrcamentoComponent },
  { path: "orcamentos", component: MeusOrcamentosComponent },
  { path: "area-teste", component: AreaTesteComponent },
  { path: "orcamento/:id", component: OrcamentoComponent },
  { path: "adicionar/:id/:tipo", component: ListasComponent },
  { path: "adicionar/:id/aereo", component: ListaAereoComponent },
  { path: "adicionar/:id/hotel", component: ListaHotelComponent },
  { path: "adicionar/:id/servico", component: ListaServicoComponent },
  { path: "adicionar/:id/carro", component: ListaCarroComponent },
  { path: "adicionar/:id/seguro", component: ListaSeguroComponent },
  // { path: "financiamento", component: FinanciamentoFormComponent },
  { path: "financiamento/:id", component: FinanciamentoFormComponent},
  { path: 'voucher/:key', component: VoucherComponent },
  { path: 'orcamentodownload/:simplificado/:id', component: OrcamentoDownloadComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
  relativeLinkResolution: "legacy",
  onSameUrlNavigation: "reload"
});
