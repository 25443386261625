import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Carro } from "src/app/model";
import { CarroService } from "src/app/services";
import { __spreadArrays, __read } from "tslib";

@Component({
  selector: "app-carro-reserva",
  templateUrl: "./carro-reserva.component.html",
  styleUrls: ["./carro-reserva.component.scss"],
})
export class CarroReservaComponent implements OnInit {
  @Output() alterou = new EventEmitter<any>();

  @Input() carro: {
    Protecoes: [];
    CondutorDocumento: "";
    CondutorNome: "";
    CondutorSobrenome: "";
    Descricao: string;
    Imagem: any;
    ModeloNome: any;
    Pax: any;
    Portas: any;
    Bagagem: any;
    TemAirCondicionado: any;
    TransmissaoTipo: any;
    Status: any;
  };

  @Input()
  paxes: any;
  carregando = false;
  mensagensErro: any[];
  mensagemSucesso: string;
  reservado = false;
  condutores: any[];
  condutor: any;
  mensagemDocumento: boolean;
  progress = 0;
  success = false;

  constructor(private carroService: CarroService) {}

  ngOnInit() {
    this.getCondutores();
  }

  getCondutores() {
    this.mensagemDocumento = true;
    this.condutores = this.paxes.filter(
      (x) => x.TipoDesc == "ADT" && x.CPF && x.Idade > 20
    );
    if (this.condutores != null) {
      this.condutor = this.condutores[0];
      console.log(this.condutores[0]);
      this.carro.CondutorNome = this.condutores[0].Nome;
      this.carro.CondutorSobrenome = this.condutores[0].Sobrenome;
      this.carro.CondutorDocumento = this.condutores[0].CPF;
      this.mensagemDocumento = false;
    }
  }

  reservar() {
    delete this.mensagensErro;
    delete this.mensagemSucesso;
    this.carregando = true;
    this.carroService.reservarCarro(this.carro).subscribe(
      (dados) => {
        this.carro = dados.Carro;
        alert("Carro reservado!");
        this.alterou.emit(this.carro);
        this.carregando = false;
        this.success = true;
      },
      (erro) => {
        this.carregando = false;
        console.log(erro);
        alert(this.errorHandler(erro));
      }
    );
  }

  public errorHandler(erro): string[] {
    let mensagem = [];
    switch (erro.status) {
      case 0:
        mensagem.push("o servidor não respondeu, tente novamente mais tarde!");
        break;
      case 400:
        mensagem.push("Houve algum erro na execução, tente novamente!");
        break;
      case 401:
        mensagem.push("você não tem autorização para executar esta ação!");
        break;
      case 404:
        mensagem.push("Solicitação inválida!");
        break;
      case 406:
        mensagem.push(erro.error.mensagem);
        break;
      case 500:
        mensagem.push(
          "Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte"
        );
        break;
      default:
        mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      // mensagem = [...mensagem, ...erro.error.mensagens];
      mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
    }
    return mensagem;
  }
}
