import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-area-teste',
  templateUrl: './area-teste.component.html',
  styleUrls: ['./area-teste.component.scss']
})
export class AreaTesteComponent implements OnInit {

  opened: boolean;

  constructor() { }

  ngOnInit() {
  }

}
