import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-carro',
  templateUrl: './carro.component.html',
  styleUrls: ['./carro.component.scss']
})
export class CarroComponent implements OnInit {

  @Output() selecionouCarro = new EventEmitter<any>();
  @Output() removeuCarro = new EventEmitter<any>();
  @Output() detalhesCarro = new EventEmitter<any>();
  @Input() carro;
  @Input() usuario;
  @Input() index;
  @Input() selecionar = true;

  @ViewChild('protectionOptions') openModal:ElementRef;

  orcamentoPage: any;
  objeto: any;
  carroLoadded = false;

  constructor() { }

  ngOnInit() {
    (window.location.pathname == '/listas/orcamento' ? this.orcamentoPage = true : this.orcamentoPage = false);
    this.carro.Protecoes.filter(x => !x.Obrigatorio).map(x => { x.desabilitado = false; return x });
    this.carro.Protecoes.filter(x => x.Codigo == 'PAI').map(x => { x.desabilitado = true; return x });
    this.carro.ValorProtecoes = this.carro.Protecoes.filter(x => x.selecionado).map(x => x.ValorVenda).reduce(function (a, b) {
        return a + b;
    }, 0);

    this.carro.TaxaAdmProtecoes = this.carro.Protecoes.filter(x => x.selecionado).map(x => x.TaxaAdm).reduce(function (a, b) {
        return a + b;
    }, 0);
  }

  selecionou(carro) {
    this.selecionouCarro.emit(carro);
  }

  removeu(carro) {
    this.removeuCarro.emit(carro);
  }

  carregar(obj) {
    this.carroLoadded = true;
    this.objeto = obj;

    setTimeout(() => {
      this.openModal.nativeElement.click();
    }, 100);
  }

  detalhes(carro) {
    this.detalhesCarro.emit(carro);
  }

  selecionarProtecao(protecao, obrigatorio) {
    if (obrigatorio) {
        this.carro.Protecoes.filter(x => x.Obrigatorio && x != protecao).map(x => { x.selecionado = false; return x });
        protecao.selecionado = true;
        this.carro.Protecoes.filter(x => !x.Obrigatorio).map(x => { x.selecionado = false; return x });

        if (protecao.Codigo == 'SUP' || protecao.Codigo == 'COM') {
            for (let j = 0; j < this.carro.Protecoes.length; j++) {
                this.carro.Protecoes[j].desabilitado = (this.carro.Protecoes[j].Codigo == "ALI" || this.carro.Protecoes[j].Codigo == "PAI") ? true : false;
            }
        } else {
            for (let j = 0; j < this.carro.Protecoes.length; j++) {
                this.carro.Protecoes[j].desabilitado = false;
            }
            this.carro.Protecoes.filter(x => x.Codigo == 'PAI').map(x => { x.desabilitado = true; x.selecionado = false; return x });
        }

    } else {
        if (protecao.Codigo == 'WDW') {
            this.carro.Protecoes.filter(x => x.Codigo == 'WDWP').map(x => { x.selecionado = false; return x });
        } else if (protecao.Codigo == 'WDWP') {
            this.carro.Protecoes.filter(x => x.Codigo == 'WDW').map(x => { x.selecionado = false; return x });
        }
        protecao.selecionado = !protecao.selecionado;
        if (protecao.Codigo == 'ALI') {
            if (protecao.selecionado) {
                this.carro.Protecoes.filter(x => x.Codigo == 'PAI').map(x => { x.desabilitado = false; return x });
            } else {
                this.carro.Protecoes.filter(x => x.Codigo == 'PAI').map(x => { x.desabilitado = true; x.selecionado = false; return x });
            }

        }



    }

    this.carro.ValorProtecoes = this.carro.Protecoes.filter(x => x.selecionado).map(x => x.ValorVenda).reduce(function (a, b) {
        return a + b;
    }, 0);

    this.carro.TaxaAdmProtecoes = this.carro.Protecoes.filter(x => x.selecionado).map(x => x.TaxaAdm).reduce(function (a, b) {
        return a + b;
    }, 0);
  }

}
