import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-package-card',
  templateUrl: './skeleton-package-card.component.html',
  styleUrls: ['./skeleton-package-card.component.scss']
})
export class SkeletonPackageCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
