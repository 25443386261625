import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Busca, Orcamento, Pacote } from 'src/app/model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Input() Orcamento: Orcamento;
  @Input() busca: Busca;
  @Output() selecionou = new EventEmitter<any>();
  Pacote = new Pacote();
  carregarPacotes: boolean;
  semAgencia = false;
 usuario = null;

  constructor(
    private _router: Router,
  ) { }

  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem('loggedUser'));
    let config = localStorage.getItem('pct');
    if (config == null || undefined) {
      localStorage.setItem('pct', 'true');
    } else if (config == 'true') {
      this.carregarPacotes = true
    } else {
      this.carregarPacotes = false
    }
  }

  selecionarPacote(pacote) {
    pacote.selecionado = true;
    this.Orcamento.Pacotes.push(pacote);
    this.selecionou.emit(pacote);
    this.BuscaPacotes();
  }

  BuscaPacotes() {
    this.semAgencia = false;
    this.busca.primeira = true;
    this.busca.novoOrcamento = true;
    this.busca.orcamentoId = null;
    this.busca.PacoteId = this.Pacote.Id;
    this.busca.trechos[0].cidadeDestino.Id = this.Pacote.Cidade.Id;
    if (this.usuario.AgenciaId != null) {
      this.busca.AgenciaId = this.usuario.AgenciaId;
    } else {
      let agencia = window.localStorage.getItem("g8h7d8");
      if (agencia !== null) {
        this.busca.AgenciaId = agencia;
      }
    }
    if (!this.busca.AgenciaId || this.busca.AgenciaId == 0) {
      this.semAgencia = true;
      setTimeout(() => (this.semAgencia = false), 5000);
      return null;
    }

    sessionStorage.removeItem('busca');
    sessionStorage.setItem('busca', JSON.stringify(this.busca));

    this._router.navigate([`lista-pacote/pacote-view`]);
  }



}
