import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { Busca, Aereo, FiltroAereo, Paginacao } from 'src/app/model';
import { AereoService, LoginService,Util } from 'src/app/services';
import { Options } from 'ng5-slider';
import { Router, ActivatedRoute } from '@angular/router';

export const BUSCA = 'busca';
export const ORCAMENTO = 'orcamentousuario';

@Component({
  selector: 'app-lista-aereo',
  templateUrl: './lista-aereo.component.html',
  styleUrls: ['./lista-aereo.component.scss']
})
export class ListaAereoComponent implements OnInit {

  @Output() selecionou = new EventEmitter<any>();
  @Output() avancar = new EventEmitter<any>();
  @Output() removeu = new EventEmitter<any>();
  @Input() busca: Busca;
  @Input() Orcamento: Aereo[] = [];
  @Input() events: Observable<void>;

  private eventsSubscription: Subscription;
  Bagagens: String[] = [];
  aereos: Aereo[] = [];
  bloqueios: Aereo[] = [];
  FiltroAereo: FiltroAereo = new FiltroAereo();

  OpcoesValor : Options = {
   floor: 0,
   ceil: 99999,
   getPointerColor: (value: number): string => {
     return "#113163";
   },
   getSelectionBarColor: (value: number): string => {
     return '#0064FF';
   },
 };

 OpcoesHorario = {
  floor: 0,
  ceil: 23,
  getPointerColor: (value: number): string => {
    return "#113163";
  },
  getSelectionBarColor: (value: number): string => {
    return '#0064FF';
  },
  translate: (value: number): string => {
    return value + "h";
  }
};

  paginacao: Paginacao = new Paginacao();
  guid: string = "";
  buscasAtivas = [];
  buscando = false;
  rebusca = false;
  cidades = [];
  totalpax = 0;
  Voos: any[] = [];
  OpcoesDuracaoTrechos : any[];

  visualiza_integrador_aereo = false;
  usuarioLogado = null;

  timer = null;
  buscarDebounce() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.refazerBusca(this.busca), 600);

  }

  constructor(
  private aereoService: AereoService,
  private loginService:LoginService,
  public _router: Router,
  public route: ActivatedRoute,
  ) {}

  ngOnInit() {

    this.usuarioLogado = this.loginService.user();

    this.busca.trechos = this.busca.trechos.map(x => {
      x.dataIda = moment(x.dataIda);
      x.dataVolta = moment(x.dataVolta);
      x.minDataIda = moment(x.minDataIda);
      x.minDataVolta = moment(x.minDataVolta);
      return x;
    });

    this.buscar();

    this.eventsSubscription = this.events.subscribe((busca) => this.refazerBusca(busca));
  }

  Cias: String[] = [];

  Fornecedores: String[] = [];

  contador = 0;

  mensagemErro = "";
  buscar() {
    const buscaAereo = this.busca.getBuscaAereo();

    if (this.Voos.length > 0) {
      this.FiltroAereo.Voos = this.Voos.map(x => Number(x.value));
    } else {
      this.FiltroAereo.Voos = null;
    }

    buscaAereo.Rebusca = this.rebusca;

    let todasfamilias = buscaAereo.FiltroAereo.TodasFamilias;
    let somenteVoosDiretos = buscaAereo.FiltroAereo.SomenteVoosDiretos;

    buscaAereo.FiltroAereo = this.FiltroAereo;
    buscaAereo.FiltroAereo.TodasFamilias = todasfamilias;
    buscaAereo.FiltroAereo.SomenteVoosDiretos = somenteVoosDiretos;

    if (!this.buscando) {
      this.aereos = [];
    }
    this.buscando = true;
    this.mensagemErro = '';
    this.aereoService
      .buscarAereo(buscaAereo, this.paginacao.NumeroPagina)
      .subscribe(
        dados => {
          //console.log(dados);
          this.buscasAtivas = JSON.parse(dados.Aereos.MetaData.BuscasAtivas);
          this.busca.guid = dados.Aereos.MetaData.Guid;
          this.totalpax = buscaAereo.Adt + buscaAereo.Chd + buscaAereo.Inf;
          this.paginacao.NumeroPagina = dados.Aereos.MetaData.NumeroPagina;
          this.paginacao.TotalItens = dados.Aereos.MetaData.TotalItens;
          this.paginacao.ItensPorPagina = dados.Aereos.MetaData.itensPorPagina;
          this.paginacao.TotalPaginas = dados.Aereos.MetaData.TotalPaginas;


          const meta = JSON.parse(dados.Aereos.MetaData.MetaDados);
          if (meta != null) {
            const meta = JSON.parse(dados.Aereos.MetaData.MetaDados);

            this.Cias = meta.Cias;
            this.Fornecedores = meta.Fornecedores;
            this.Bagagens = meta.Bagagens;
            
            if (!(this.FiltroAereo.ValorMinimo > 0)) {
              this.FiltroAereo.ValorMinimo = meta.ValorMinimo;
              this.FiltroAereo.ValorMaximo = meta.ValorMaximo;
            }

            this.OpcoesDuracaoTrechos = [];

            //gambiarra feita pois, por um motivo inexplicável, o minimoduracaotrecho está sendo alterado quando o request
            //da busca é executado.
            if(isNaN(this.FiltroAereo.MinimoDuracaoTrechos[0])){
              this.FiltroAereo.MinimoDuracaoTrechos = [];
              this.FiltroAereo.MaximoDuracaoTrechos = [];
            }

            for (let i = 0; i < meta.MinimoDuracaoTrechos.length; i++){
              let op = {
                floor: meta.MinimoDuracaoTrechos[i],
                ceil: meta.MaximoDuracaoTrechos[i],
                translate: (value: number): string => {
                  return value + "h";
                },
                getPointerColor: (value: number): string => {
                  return "#113163";
                },
                getSelectionBarColor: (value: number): string => {
                  return '#0064FF';
                }
              }

              this.OpcoesDuracaoTrechos.push(op);
              this.FiltroAereo.MinimoDuracaoTrechos.push(meta.MinimoDuracaoTrechos[i]);
              this.FiltroAereo.MaximoDuracaoTrechos.push(meta.MaximoDuracaoTrechos[i]);

            }

            //atualiza o slider
            const newOptions: Options = Object.assign({}, this.OpcoesValor);
            newOptions.floor = meta.ValorMinimo;;
            newOptions.ceil = meta.ValorMaximo;
            this.OpcoesValor = newOptions;

          } else {
            this.Cias = [];
            this.Fornecedores = [];
            this.FiltroAereo.ValorMinimo = 0;
            this.FiltroAereo.ValorMaximo = 0;
            this.FiltroAereo.MinimoDuracaoTrechos = [];
            this.FiltroAereo.MaximoDuracaoTrechos = [];

            const newOptions: Options = Object.assign({}, this.OpcoesValor);
            newOptions.floor = 0;
            newOptions.ceil = 99999;
            this.OpcoesValor = newOptions;

          }

          window.sessionStorage.setItem("busca", JSON.stringify(this.busca));
          this.aereos = dados.Aereos.Items.map(x => {
            x.Id = x.Id * -1;
            x.selecionado = this.aereoSelecionado(x);
            return x;
          });

          this.bloqueios = dados.Bloqueios.map(x => {
            x.ExtraIntegracao2 = JSON.parse(x.ExtraIntegracao);
            x.Id = x.Id * -1;
            x.selecionado = this.aereoSelecionado(x);
            return x;
          });


          if (this.buscasAtivas.length > 0) {
            setTimeout(() => {
              this.buscar();
            }, 1500);
          } else {
            this.buscando = false;
          }
        },
        erro => {
          console.log(erro);
          this.mensagemErro = erro.error.mensagem;
          this.buscando = false;
        }
      );
  }

  buscarPagina(pagina) {
    window.scroll(0, 0);
    this.paginacao.NumeroPagina = pagina;
    this.buscar();
  }

  refazerBusca(busca) {
    if (this.aba() == 'lista-aereo') {
      this.busca = busca;
      window.scroll(0, 0);
      this.paginacao.NumeroPagina = 1;
      this.buscando = true;
      this.buscar();
    }
  }
  aba() {
    if (this.route.snapshot.params.tipo != null) {

      return this.route.snapshot.params.tipo;
    } else {
      const x = this._router.url.split('/');

      return x[x.length - 1];
    }
  }
  selecionarCia(cia) {

    setTimeout(() => {

      if (this.ciaSelecionada(cia)) {
        this.FiltroAereo.Cias = this.FiltroAereo.Cias.filter(x => x != cia);
      } else {
        this.FiltroAereo.Cias.push(cia);
      }

        this.refazerBusca(this.busca);
      }, 600);

  }

  ciaSelecionada(cia) {
    return this.FiltroAereo.Cias.indexOf(cia) >= 0;
  }

  FornecedorSelecionado(fornecedor) {
    return this.FiltroAereo.Fornecedores.indexOf(fornecedor) >= 0;
  }

  selecionarFornecedor(fornecedor) {

    setTimeout(() => {

      if (this.FornecedorSelecionado(fornecedor)) {
        this.FiltroAereo.Fornecedores = this.FiltroAereo.Fornecedores.filter(x => x != fornecedor);
      } else {
        this.FiltroAereo.Fornecedores.push(fornecedor);
      }

        this.refazerBusca(this.busca);
      }, 600);
  }

  selecionarAereo(aereo) {
    // this.removerAereo(aereo);
    aereo.selecionado = true;
    if (this.busca.primeira) {
      this.busca.aereo = aereo;
      while (this.Orcamento.length > 0) {
        this.Orcamento.pop();
      }
    }
    this.Orcamento.push(aereo);
    this.selecionou.emit(aereo);
  }

  aereoSelecionado(aereo) {
    const dado = this.Orcamento.filter(x => x.Id == aereo.Id);
    return dado.length > 0;
  }

  removerAereo(aereo) {
    aereo.selecionado = false;
    let indice = -1;
    for (let i = 0; i < this.Orcamento.length; i++) {
      if (this.Orcamento[i].Id == aereo.Id) {
        indice = i;
      }
    }
    if (indice >= 0) {
      this.Orcamento.splice(indice, 1);
      this.removeu.emit(aereo);
    }
  }

  arrayN(i: number) {
    return [Array(i).keys()];
  }

  onChange(event) {
    this.busca.primeira = false;
    this.rebusca = true;
  }

  limparFiltros() {
    this.FiltroAereo = new FiltroAereo();
    this.removeCheck();
    this.buscar();
  }

  rotaAvancar() {
    this.avancar.emit("x");
  }

  removeCheck() {
    let els = document.querySelectorAll('label.btn.active');

    for (var i = 0; i < els.length; i++) {
        els[i].classList.remove('active')
    }

  }

}
