import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UsuarioService } from 'src/app/services';

@Component({
    selector: 'app-selecionar-agente',
    templateUrl: './selecionar-agente.component.html',
    styleUrls: ['./selecionar-agente.component.scss']
})
export class SelecionarAgenteComponent implements OnInit {

    @Input() reserva: any;

    @Output() selecionou = new EventEmitter<any>();

    mensagemErro = '';
    mensagemSuccesso = '';
    carregando = true;
    dados = [];
    agenteLista: [];
    clicked: boolean;

    agenteSelecionado: any;

    constructor(
        private usuarioService: UsuarioService,
    ) { }

    ngOnInit() {
        //this.buscar();
        //this.clicked = false;
    }

    buscar(AgenciaId) {
        this.mensagemErro = '';
        this.mensagemSuccesso = '';
        this.usuarioService.buscarAgente(AgenciaId, '').subscribe(
            dados => {

                this.dados = dados;
            }, erro => {
                console.log("ERRO AO BUSCAR AGENTES");
                console.log(erro);
            });
    }

    selecionar() {
        this.selecionou.emit(this.agenteSelecionado);
    }

}
