import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FinanciamentoService } from 'src/app/services';

@Component({
  selector: 'financiamento-boletos',
  templateUrl: './financiamento-boletos.component.html',
  styleUrls: ['./financiamento-boletos.component.scss']
})
export class FinanciamentoBoletosComponent implements OnInit {
  @Input()
  FinanciamentoId: any;
  financiamento: any[];
  mensagemErro: any[];
  boletos: any[];
  constructor(public router: Router, public modalService: NgbModal, private financiamentoService: FinanciamentoService) { }

  ngOnInit() {
    this.buscaBoletos();
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  buscaBoletos() {
    let busca = this.financiamentoService.verificarBoletos(this.FinanciamentoId);
    busca.subscribe(
      dados => {
        if (dados) {
          this.financiamento = dados;
          this.boletos = dados.Boletos;
        } else {
          this.financiamento = null;
          this.boletos = null;
        }
      },
      erro => {
        this.financiamento = null;
        console.log('Erro ao Buscar');
        console.log(erro);
        this.mensagemErro = this.errorHandler(erro);
      },
    );
  }

  public errorHandler(erro): string[] {
    let mensagem = [];
    switch (erro.status) {
        case 0:
            mensagem.push("o servidor não respondeu, tente novamente mais tarde!");
            break;
        case 400:
            mensagem.push(erro.error.mensagem);
            break;
        case 401:
            mensagem.push("você não tem autorização para executar esta ação!");
            break;
        case 404:
            mensagem.push(erro.error.mensagem);
            break;
        case 406:
            mensagem.push(erro.error.mensagem);
            break;
        case 500:
            mensagem.push(
                "Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte"
            );
            break;
        default:
            mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
        mensagem = [Array.from(mensagem), Array.from(erro.error.mensagens)];
    }
    return mensagem;
}

}
