import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BuscaCarroRQ } from '../../model';

import { API } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';
@Injectable()
export class CarroService {
    constructor(private http: HttpClient) { }
    buscarCarro(busca: BuscaCarroRQ, pagina: Number): Observable<any> {
        return this.http.post<any>(`${API}/api/Carro/busca?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    buscarPorParametro(param: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        if (param.length > 0) {
            return this.http.get<any>(`${API}/api/lojascarro/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
        else {
            return this.http.get<any>(`${API}/api/lojascarro?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
    }

    removerCarro(carro: any): Observable<any> {
        return this.http.delete<any>(`${API}/api/Carro/${carro.Id}`);
    }

    reservarCarro(carro: any): Observable<any> {
        return this.http.post<any>(`${API}/api/carro/reservar/${carro.Id}`, carro, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    atualizarStatusCarro(carro: any): Observable<any> {
        return this.http.patch<any>(`${API}/api/carro/atualizar/${carro.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    cancelarCarro(carro: any): Observable<any> {
        return this.http.post<any>(`${API}/api/carro/cancelar/${carro.ReservaId}/${carro.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
    }
}
