import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { Orcamento, Busca } from "src/app/model";
import { ReservaService } from "src/app/services";

export const BUSCA = "busca";
export const ORCAMENTO = "orcamentousuario";

@Component({
  selector: "app-lista-pacote",
  templateUrl: "./lista-pacote.component.html",
  styleUrls: ["./lista-pacote.component.scss"],
})
export class ListaPacoteComponent implements OnInit {
  Orcamento: Orcamento = new Orcamento();
  busca: Busca = new Busca();
  eventsSubject: Subject<void> = new Subject<void>();

  constructor(
    public reservaService: ReservaService,
    public _router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
  }

  aba() {
    if (this.route.snapshot.params.tipo != null) {
      return this.route.snapshot.params.tipo;
    } else {
      let x = this._router.url.split("/");

      return x[x.length - 1];
    }
    return "";
  }

  rota(texto) {
    console.log(texto);
    if (this.busca.novoOrcamento) {
      this._router.navigate([`pacotes/${texto}`]);
    } else {
      this._router.navigate([`adicionar/${this.busca.orcamentoId}/${texto}`]);
    }
  }

  atualizarOrcamento(event, adicionou) {
    window.sessionStorage.setItem(ORCAMENTO, JSON.stringify(this.Orcamento));
    if (this.busca.primeira && adicionou) {
      // window.sessionStorage.setItem(BUSCA, JSON.stringify(this.busca));

      const id = this.route.snapshot.params.id;

      if (this.aba() == "pacotes") {
        this._router.navigate([`/pacote-hotel`]);
      }
      if (this.aba() == "pacote-hotel") {
        this._router.navigate([`/revisa-orcamento`]);
      }
    }
  }

  rotaAnterior() {
    const tipo = this.aba();
    switch (tipo) {
      case "pacote-hotel":
        return this.rota("/pacotes");
      case "revisa-orcamento":
        return this.rota("/pacote-hotel");
    }
  }
}
