import { Alteracoes } from './../base';
import { FormaPagamento } from '../gerenciamento/forma-pagamento.model';

export class ReservaPagamento {
    Id: number;
    Valor: number;
    Taxas: number = 0;
    Juros: number;
    ValorComJuros: number;
    Alteracoes: any;
    //  Reserva : any
    ReservaId: number
    FormaPagamento: FormaPagamento
    CondicaoPagamento: any
    CondicaoPagamentoId: number

    //Moeda : any
    MoedaId: number
    Status: number
    TipoPagamento: number
    formaSelecionada: any
    FormaSelecionada: any
    Guid: number
    Erros: any
    Financiamentos: Financiamento[]
    PaxPaganteId: number
    PaxPagante: any
    PaxPaganteDados: any
    PaxNaoPagantes: any
    Quitada: boolean
    Processado = false
    Success: string
    CPF: string
    Email: string
    Telefone: string
    Link: string
    Reserva: any
    Cartoes: any[]
    emailLinkPagamento: boolean
    DescontoId: number
    ChaveLink: string
}

export class Financiamento {
    Id: number;
    Erros: any;
    Cep: any;
    CidadeDs: any = "";
    EstadoDs: any = "";
    Logradouro: any;
    Complemento: any;
    Bairro: any;
    DataNascimento: any;
    profissao: any;
    Profissao: any;
    ProfissaoCodigo: any;
    ReservaId: number;
    Status: any;
    TipoResidencia: any;
    TemCNPJ: boolean;
    CNPJ: any;
    CodigoInterno: any;
    CodigoPedido: any;
    ValorTotal: any;
    ValorEntrada: any;
    Parcelas: any;
    DataPrimeiroVencimento: any;
    Nome: any;
    Sobrenome: any;
    CPF: any;
    RG: any;
    OrgaoEmissor: any;
    Email: any;
    Celular: any;
    TelefoneResidencial: any;
    Numero: any;
    ReferenciaComercial: any;
    Renda: any;
    EnderecoComercial: any;
    TelefoneComercial: any;
    Ramal: any;
    BancoNumero: any;
    ClienteDesde: any;
    AgenciaNumero: any;
    AgenciaDigito: any;
    ContaNumero: any;
    ContaDigito: any;
    Produtos: any[];
    Produto: any;
    StatusDesc: any;
    grauParentesco: string;
    GrauParentesco: any;
    FinanciamentoTerceirosAprovadorPor: string;
    FinanciamentoTerceirosAprovadorEm: string;
    FinanciamentoTerceirosAprovado: boolean;
    Observacao: string;
    FinanceiroTerceiros: boolean;
    Historicos: any[];
    ReservaPagamento: any;
    ConsultaSpc: any;
    Taxas: any;
}

export class ReservaPessoa {
    sexo = '';
    Nome = '';
    Sobrenome = '';
    Pax: string;
    Id: number;
    PaxPaganteId: number;
    Nascimento: any = null;
    IdadeCertaVoltaViagem: boolean;
}

export class Reserva {
    Id: number;
    ValorTotal: number;
    TaxaTotal: number;
    TaxaTotalMkp: number;
    ValorPagoTaxas: number;
    ValorPagoTaxasMkp: number;
    ValorMkpTaxa : number;
    ValorPago: number;
    ValorJuros: number;
    ValorTotalComJuros: number;
    ValorTotalComissao:number;
    Pessoas: ReservaPessoa[];
    Agencia: any;
    Descontos: any[] = [];
    DescontoAplicado: any;
    ValorDesconto: any;
    ValorDescontoId: any;
    DescontoAplicadoId: number;
    Disney: boolean;
    VerificaTaxaPaga: boolean;
    AereoPacote: any[] = [];
    HotelPacote: any[] = [];
    OfflineProdutos: any[] = [];
    Circuitos: any[] = [];
}

export class FiltroReserva {
    id: number;
    pax: string;
    localizador: string;
    situacao = -1;
    AgenciaId: any;
    SomenteComReservas = false;
    Data1: any;
    Data2: any;
}

export enum STATUSRESERVA {
    // TODAS = -1,
    PENDENTE = 0,
    AGUARDANDOINTEGRADOR = 1,
    PARCIALMENTECONFIRMADO = 2,
    RESERVADO = 3,
    CONFIRMADO = 4,
    EMITIDO = 5,
    PAGO = 6,
    PROBLEMATECNICO = 7,
    CANCELADA = 8,
    EXCLUIDA = 9,
}
