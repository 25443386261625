import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { Hoteloffline } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class HotelofflineService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{

      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlinehotel/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlinehotel?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlinehotel/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlinehotel/${id}`);
    }

    salvar(hoteloffline: Hoteloffline): Observable<any>{
      if (hoteloffline.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/offlinehotel/${hoteloffline.Id}`, hoteloffline);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/offlinehotel/`, hoteloffline);
      }

    }

    uploadImagem(arquivo: any, id: number): Observable<any>{

      const url = `${GERENCIAMENTO}/api/offlinehotel/imagem/${id}/`;

      const headers = new HttpHeaders().append('noloader', 'noloader');
      const formData = new FormData();
      formData.append('Arquivo', arquivo);

      return this.http.post(url, formData, {headers : headers});

    }

    removerImagem(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlinehotel/imagem/remover/${id}`);
    }

  }
