// tslint:disable: max-line-length

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AereoService } from 'src/app/services';
import { Aereo } from 'src/app/model';
import { __read, __spreadArrays } from 'tslib';

@Component({
  selector: 'app-aereo-cancela',
  templateUrl: './aereo-cancela.component.html',
  styleUrls: ['./aereo-cancela.component.scss']
})
export class AereoCancelaComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private aereoService: AereoService
    ) { }

  @Output() alterou = new EventEmitter<any>();
  @Output() fechou = new EventEmitter<any>();

  @Input() aereo: any;

  aereoRetarifado: Aereo;
  carregando = false;
  mensagensErro: any[];
  mensagemSucesso: string;
  cienteAlterouValor = false;
  progress: number;


  ngOnInit() {
    this.progress = 1;
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
  }

  fecharModal() {
    this.modalService.dismissAll();
  }


  cancelarAereo() {
    delete this.mensagensErro;
    delete this.mensagemSucesso;

    this.carregando = true;
    this.aereoService.cancelarAereo(this.aereo).subscribe(
      dados => {
          this.aereo = dados;
          this.carregando = false;
          this.progress++;
          this.alterou.emit(this.aereo);
          alert('Aéreo cancelado com sucesso!');
      }, erros => {
        console.log(erros);
        alert(this.errorHandler(erros));
        this.carregando = false;
      });
  }

  public errorHandler(erro): string[] {
    let mensagem = [];
    switch (erro.status) {
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
      case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
      case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
      case 404: mensagem.push('Solicitação inválida!' ); break;
      case 406: mensagem.push(erro.error.mensagem ); break;
      case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ); break;
      default : mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      mensagem =  __spreadArrays([mensagem], __read(erro.error.mensagens));
    }
    return mensagem;

  }

}
