import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SeguroService } from 'src/app/services';
import { __spreadArrays, __read } from 'tslib';

@Component({
  selector: 'app-seguro-reserva',
  templateUrl: './seguro-reserva.component.html',
  styleUrls: ['./seguro-reserva.component.scss']
})
export class SeguroReservaComponent implements OnInit {

  success: boolean;
  carregando: boolean;
  progress = 0;
  @Input() seguro: any;

  @Output() alterou = new EventEmitter<any>();
  
  mensagensErro: any[];
  mensagemSucesso: string;
  reservado = false;

  constructor(private seguroService: SeguroService) { }

  ngOnInit() {
  }

  reservar(){
    delete this.mensagensErro;
    delete this.mensagemSucesso;
    this.carregando = true;
    this.seguroService.reservarSeguro(this.seguro).subscribe(
      dados => {

        this.seguro = dados.Seguro;
        alert('Seguro emitido!');
        this.alterou.emit(this.seguro);
        this.success = true;
        this.carregando = false;

      }, erro => {
        this.carregando = false;
        console.log(erro);
        this.mensagensErro = this.errorHandler(erro);
        alert(this.mensagensErro)
      });

  }

  public errorHandler(erro): string[]{

    let mensagem = [];
    switch (erro.status){
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
      case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
      case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
      case 404: mensagem.push('Solicitação inválida!' ); break;
      case 406: mensagem.push(erro.error.mensagem ); break;
      case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ); break;
      default : mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null){
      mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
    }
    return mensagem;

  }

}
