import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { Busca, Carro, Paginacao } from 'src/app/model';
import { CarroService } from 'src/app/services';

@Component({
  selector: 'app-lista-carro',
  templateUrl: './lista-carro.component.html',
  styleUrls: ['./lista-carro.component.scss']
})
export class ListaCarroComponent implements OnInit {

    @Output() selecionou = new EventEmitter<any>();
    @Output() avancar = new EventEmitter<any>();
    @Output() voltar = new EventEmitter<any>();
    @Output() removeu = new EventEmitter<any>();
    @Input() busca: Busca;
    @Input() events: Observable<void>;
    @Input() Orcamento: Carro[] = [];
    
    carros: Carro[] = [];
    paginacao: Paginacao = new Paginacao();
    
    mensagemErro = '';
    guid = '';
    
    buscasAtivas = [];
    lojas = [];
    
    carregando = true;
    buscando = false;
    rebusca = false;
    pulse = true;
    
    indiceAtual = 0;
    contador = 0;
    
    itemBusca: any = null;
    timerCidade = null;
    
    condicaoVoltar: boolean;
    
    private eventsSubscription: Subscription;
    @ViewChild('contentDetalhes') contentDetalhes;
    private contentDetalhesref;
    modaldetalhesaberta = false;

    constructor(
        private carroService: CarroService,
    ) { }

    ngOnInit() {
        this.buscar();
        this.eventsSubscription = this.events.subscribe((busca) => this.refazerBusca(busca));
        
        const tbusca = this.busca.tipoBusca;
        ((!tbusca.aereo && !tbusca.hotel && !tbusca.ticket) 
        ? this.condicaoVoltar = false
        : this.condicaoVoltar = true) 
    }

    rotaAvancar() {
        this.avancar.emit('x');
    }

    rotaAnterior() {
        this.voltar.emit('x');
    }

    refazerBusca(busca) {
        this.busca = busca;
        this.busca.primeira = false;
        window.scroll(0, 0);
        this.paginacao.NumeroPagina = 1;
        this.buscando = true;
        this.carregando = true;
        this.buscar();
    }

    buscar(buscaCarro = null) {
        if (buscaCarro == null) {
            buscaCarro = this.busca.getBuscaCarro();
        }
        buscaCarro.Rebusca = this.rebusca;
        if (!this.buscando) {
            this.carros = [];
        }
        this.buscando = true;
        this.carroService.buscarCarro(buscaCarro, this.paginacao.NumeroPagina)
            .subscribe(
                dados => {
                    this.buscasAtivas = JSON.parse(dados.MetaData.BuscasAtivas);
                    this.busca.guid = dados.MetaData.Guid;
                    buscaCarro.Guid = dados.MetaData.Guid;
                    this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
                    this.paginacao.TotalItens = dados.MetaData.TotalItens;
                    this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
                    this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
                    window.sessionStorage.setItem('busca', JSON.stringify(this.busca));
                    var protecoesObrigatorias = ["SUP", "COM", "BAS"];
                    this.carros = dados.Items.map(x => {
                        x.Id = x.Id * -1;
                        x.selecionado = this.carroSelecionado(x);
                        return x;
                    });

                    for (let i = 0; i < this.carros.length; i++) {
                        for (let j = 0; j < this.carros[i].Protecoes.length; j++) {
                            this.carros[i].Protecoes[j].Obrigatorio = protecoesObrigatorias.includes(this.carros[i].Protecoes[j].Codigo);
                            this.carros[i].Protecoes[j].selecionado = this.carros[i].Protecoes[j].Codigo == "BAS" ? true : false;
                        }
                        this.carros[i].ValorProtecoes = this.carros[i].Protecoes.filter(x=>x.selecionado)[0].ValorVenda;
                    }

                    this.contador++;

                    if (this.buscasAtivas.length > 0 && (window.location.href.split('/').indexOf('lista-carro') != -1 || window.location.href.split('/').indexOf('adicionar') != -1)) {
                        setTimeout(() => {
                            this.buscar(buscaCarro);
                        }, 3000);
                    } else {
                        this.buscando = false;
                    }
                },
                erro => {
                    console.log(erro);
                    this.buscando = false;
                    this.mensagemErro = erro.error.mensagem;
                }
            );
    }

    buscarPagina(pagina) {
        window.scroll(0, 0);
        this.paginacao.NumeroPagina = pagina;
        this.buscar();
    }

    selecionarCarro(carro) {
        carro.selecionado = true;
        this.Orcamento.push(carro);
        this.selecionou.emit(carro);
        console.log(carro);
    }

    carroSelecionado(carro) {
        const dado = this.Orcamento.filter(x => x.Id == carro.Id);
        return dado.length > 0;
    }

    removerCarro(carro) {
        carro.selecionado = false;
        let indice = -1;
        for (let i = 0; i < this.Orcamento.length; i++) {
            if (this.Orcamento[i].Id == carro.Id) {
                indice = i;
            }
        }
        if (indice >= 0) {
            this.Orcamento.splice(indice, 1);
            this.removeu.emit(carro);
        }
    }

    somaTotal(arr) {
        return arr.map(x => x.ValorVenda).reduce(function (a, b) {
            return a + b;
        }, 0);
    }

    onChange(event) {
        this.busca.primeira = false;
        this.rebusca = true;
    }

    distinct = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    buscaLojas(val: string) {
        this.carroService.buscarPorParametro(val, 1).subscribe(
            dados => {
                this.lojas = dados.Items.map(x => {
                    x.display_field = `${x.Sigla} - ${x.Nome} / ${x.Endereco}`;
                    return x;
                });
            },
            erro => {
                console.log(erro);
                this.lojas = [];
            }
        );
    }

    selecionaLojaOrigem(item) {
        this.busca.localOrigem = item;
    }
  
    selecionaLojaDevolucao(item) {
        this.busca.localDevolucao = item;
    }
  
    setDataDevolucao(event) {
        this.busca.dataDevolucao = event.startOf('day');
        if (moment(this.busca.dataOrigem) > moment(this.busca.dataDevolucao)) {
            this.busca.dataOrigem = moment(this.busca.dataDevolucao).subtract(1, 'days');
        }
    }
  
    setDataOrigem(event) {
        this.busca.dataOrigem = event.startOf('day');
        this.busca.dataDevolucao = moment(this.busca.dataOrigem).add(1, 'days');;
    }

    buscaLojasDebounce(val: string) {
        clearTimeout(this.timerCidade);
        this.timerCidade = setTimeout(() => {
            this.buscaLojas(val);
        }, 600);
    }
}
